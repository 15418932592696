import Vue from 'vue'
import { routes } from './routes'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

function configRoutes() {
  return routes
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: configRoutes(),
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (to.meta.actionBar) {
      store.dispatch('SetActionBar', to.meta.actionBar)
    } else {
      store.dispatch('SetActionBar', false)
    }

    if (store.getters.isAuthenticated) {
      const project = to.params.project
      const currentProject = store.getters.Project

      const projectContextReady = store.getters.ProjectContextLoaded

      if (project && (!projectContextReady || currentProject.name != project)) {
        // console.log('-- 1. router')
        // console.log(project)
        // console.log('-- 2. router')
        next('/')
      }

      next()
      return
    }

    next('/login')
  } else {
    next()
  }
})

export default router
