import http from '@/http/index'

const contentTypes = {
  async getModels(project, query) {
    return await http.get(`${project}/content-types/models`, { params: query })
  },
  async getModel(project, id) {
    return await http.get(`${project}/content-types/models/${id}`)
  },
  async createModel(project, data) {
    return await http.post(`${project}/content-types/models`, data)
  },
  async updateModel(project, id, data) {
    return await http.put(`${project}/content-types/models/${id}`, data)
  },
  async deleteModel(project, id) {
    return await http.delete(`${project}/content-types/models/${id}`)
  },
  async getComponents(project) {
    return await http.get(`${project}/content-types/components`)
  },
  async getComponent(project, id) {
    return await http.get(`${project}/content-types/components/${id}`)
  },
  async createComponent(project, data) {
    return await http.post(`${project}/content-types/components`, data)
  },
  async updateComponent(project, id, data) {
    return await http.put(`${project}/content-types/components/${id}`, data)
  },
  async deleteComponent(project, id) {
    return await http.delete(`${project}/content-types/components/${id}`)
  },
}

export default contentTypes
