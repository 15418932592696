<template>
  <b-container class="ml-auto mb-2 content-container" :fluid="true">
    <content-list :model="model" v-if="model && isList" ref="ContentList" />
    <content-edit :model="model" v-if="model && isEdit" ref="ContentEdit" @dirty-change="onDirtyChange" />
    <content-single :model="model" v-if="model && isSingle" ref="ContentSingle" />
    <content-new :model="model" v-if="model && isNew" ref="ContentNew" @dirty-change="onDirtyChange" />
    <b-modal id="confirmDirtyLeave" hide-footer title="Changes" size="md" centered hide-header v-model="dirtyConfirm">
      <div class="d-block text-left mt-2">
        <h4>Unsaved changes</h4>
        <p class="h6 font-weight-normal">
          There are unsaved changes. Exiting the page the changes will be lost.
        </p>
        <p class="h6 font-weight-normal">Are you sure you want to continue?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="dirtyConfirm = false">Cancel</b-button>
        <b-button class="" variant="warning" @click="onDirtyConfirmContinue">Continue</b-button>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import ContentList from './components/ContentsList.vue'
import ContentEdit from './components/ContentEdit.vue'
import ContentSingle from './components/ContentSingle.vue'
import ContentNew from './components/ContentNew.vue'

export default {
  name: 'ContentsWrapper',
  components: {
    ContentList,
    ContentEdit,
    ContentSingle,
    ContentNew,
  },
  data() {
    return {
      model: null,
      isDirty: false,
      dirtyConfirm: false,
      to: null,
    }
  },
  watch: {
    $route(to, from) {
      if (this.isDirty) {
        const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
      } else {
      }
    },
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    typeId() {
      return this.$route.meta.typeId
    },
    isNew() {
      return this.$route.meta.isNew
    },
    isList() {
      return this.$route.meta.isList
    },
    isSingle() {
      return this.$route.meta.isSingle
    },
    isEdit() {
      return this.$route.meta.isEdit
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.checkDirtyDataOnExit)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.checkDirtyDataOnExit)
  },
  mounted() {
    this.selectedLocale = this.defaultLocale
  },
  created() {
    this.model = this.$ContentTypes.findModel(this.typeId)
  },
  methods: {
    checkDirtyDataOnExit(e) {
      if (!this.isDirty) return
      e.preventDefault()
      e.returnValue = ''
    },
    onDirtyChange(event) {
      this.isDirty = event
    },
    onDirtyConfirmContinue() {
      this.dirtyConfirm = false
      this.isDirty = false
      this.$router.push(this.to)
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      this.to = to
      this.dirtyConfirm = true
      next(false)
    } else {
      next()
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.isDirty) {
      this.to = to
      this.dirtyConfirm = true
      next(false)
    } else {
      next()
    }
  },
}
</script>
