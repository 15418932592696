<template>
  <div>
    <b-form-group>
      <validation-provider #default="{ errors }" :name="field.label" :rules="rules">
        <date-picker :value="value" :color="config.date.color" :mode="config.date.mode" :masks="config.date.masks" @input="onDateInput">
          <template v-slot="{ inputValue, inputEvents }"> <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" /> </template
        ></date-picker>
        <br />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'DatetimeField',
  props: ['field', 'value', 'locale', 'errors', 'index'],
  components: {
    Calendar,
    DatePicker,
  },
  data() {
    return {
      internal: null,
      config: {
        date: {
          mode: 'date',
          color: 'blue',
          masks: {
            input: 'YYYY-MM-DD',
          },
        },
      },
    }
  },
  computed: {
    rules() {
      const ruleSet = []

      if (this.field.required) {
        ruleSet.push('required')
      }
      return ruleSet.join('|')
    },
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
    onDateInput(event) {
      if (event) {
        this.$emit('input', this.$moment(event).format('YYYY-MM-DD'))
      } else {
        this.$emit('input', '')
      }
    },
  },
}
</script>
