import http from '@/http/index'

const reports = {
  async getProjectCounters(project) {
    return await http.get(`${project}/reports/counters`)
  },
  async getProjectContentStats(project) {
    return await http.get(`${project}/reports/content-stats`)
  },
}

export default reports
