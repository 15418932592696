import http from '@/http/index'

const accounts = {
  async login(data) {
    return await http.post('/login', data)
    //return await http.post("/token", data);
  },
  async registerAccount(data) {
    return await http.post('/accounts/register', data)
  },
  async forgotPassword(data) {
    return await http.post('/accounts/password/forgot', data)
  },
  async resetPassword(data) {
    return await http.post('/accounts/password/reset', data)
  },
  async getMe() {
    return await http.get(`/accounts/me`)
  },
  async updateMe(data) {
    return await http.put(`/accounts/me`, data)
  },
  async changeMyPassword(data) {
    return await http.post(`/accounts/me/password`, data)
  },
  async getRoles(project) {
    return await http.get(`${project}/memberhips/roles`)
  },
  async getPermissionSet(project) {
    return await http.get(`${project}/memberhips/roles/permissions`)
  },
  async createRole(project, data) {
    return await http.post(`${project}/memberhips/roles`, { ...data, type: 'project' })
  },
  async updateRole(project, id, data) {
    return await http.put(`${project}/memberhips/roles/${id}`, data)
  },
  async deleteRole(project, id) {
    return await http.delete(`${project}/memberhips/roles/${id}`)
  },

  async deleteAccount(id) {
    return await http.delete(`/accounts/${id}`)
  },
}

export default accounts
