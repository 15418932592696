<template>
  <div>
    <section-divider text="Text Settings" />
    <b-form-group description="You won't be able to create a content if there is an existing content with the same value of this field" class="mb-0">
      <div class="inline-spacing">
        <b-form-checkbox id="unique" v-model="item.unique" class="custom-control-primary">
          Unique
        </b-form-checkbox>
      </div>
    </b-form-group>
    <b-form-group description="Displays this field's value on lists as default and on relations">
      <div class="inline-spacing">
        <b-form-checkbox id="isTitle" v-model="item.isTitle" class="custom-control-primary">
          Use as title
        </b-form-checkbox>
      </div>
    </b-form-group>
    <b-form-group label="Default Value">
      <b-form-input id="defaultValue" placeholder="" size="md" v-model="item.defaultValue" />
      <b-form-text>Will be the default value when creating new contents</b-form-text>
    </b-form-group>
    <b-form-group label="Editor *">
      <validation-provider #default="{ errors }" name="Editor" rules="required">
        <b-form-select id="editor" v-model="item.editor" :options="editorOptions" size="md" />
        <small class="text-danger">{{ errors[0] }}</small>
        <b-form-text>The type of editor you will use when editing contents</b-form-text>
      </validation-provider>
    </b-form-group>
    <b-form-group label="Number of chars">
      <b-row>
        <b-col md="2">
          <b-form-input id="min" placeholder="Min chars" size="md" v-model="item.min" />
        </b-col>
        <b-col md="2">
          <b-form-input id="max" placeholder="Max chars" size="md" v-model="item.max" />
        </b-col>
      </b-row>
      <b-form-text>Min and Max length. 0 means no limits</b-form-text>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: 'TextFieldSettings',
  props: ['value'],
  data() {
    return {
      editorOptions: [
        { value: null, text: 'Please select an editor style' },
        { value: 'single_line', text: 'Text input' },
        { value: 'textarea', text: 'Textarea' },
      ],
    }
  },
  computed: {
    item() {
      return this.value ? this.value : {}
    },
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.item, [key]: value })
    },
  },
}
</script>
