<template>
  <object :data="logoUrl" type="image/svg+xml" />
</template>
<script>
export default {
  props: ['type'],
  computed: {
    logoUrl() {
      if (this.type) {
        if (this.type == 'dark') return require(`@/assets/images/logo/logo-dark.svg`)
      }

      return require('@/assets/images/logo/logo.svg')
    },
  },
}
</script>
