<template>
  <component :is="tag" class="item-container d-flex flex-column mb-1">
    <b-row v-if="view == 'table'" class="mb-0">
      <b-col>
        <b-img
          :src="mediaUrl"
          v-b-tooltip.hover.v-secondary.top
          v-b-tooltip.hover.html="`${item.originalFileName}<br/><span class='mt-25 font-small-1'>${item.folder}</span>`"
          width="140"
        />
      </b-col>
      <b-col>
        {{ item.originalFileName }}
      </b-col>
    </b-row>
    <b-card v-else no-body :class="{ selected: selected }">
      <div class="preview d-flex rounded-top">
        <b-form-checkbox
          class="media-item-selector"
          :checked="selected"
          @change="$emit('item-changed', $event, item)"
          v-if="libraryMode == 'manage' && !imageLoading"
        />
        <div class="d-flex w-100 h-100 position-relative" @click="$emit('item-clicked', item)">
          <div class="d-flex align-items-center justify-content-center w-100 h-100 position-absolute" v-if="imageLoading">
            <b-spinner label="Loading..." class="align-middle" />
          </div>
          <div v-if="item.type == 'image'" class="d-flex bg-img-empty w-100 h-100">
            <v-lazy-image
              v-b-tooltip.hover.v-secondary.top
              :src="mediaUrl"
              :style="imageBoxStyle"
              v-b-tooltip.hover.html="`${item.originalFileName}<br/><span class='mt-25 font-small-1'>${item.folder}</span>`"
              :use-picture="false"
              class="m-auto"
              @load="imageLoading = false"
            />
          </div>
          <div class="m-auto" v-else>
            <img
              :src="require(`@/assets/file-icons/svg/${fileIcon.getIcon(item.originalFileName)}`)"
              height="80"
              v-b-tooltip.hover.v-secondary.top
              v-b-tooltip.hover.html="`${item.originalFileName}<br/><span class='mt-25 font-small-1'>${item.folder}</span>`"
            />
          </div>
        </div>
      </div>
      <b-card-body class="info" :class="{ 'd-flex align-items-center justify-content-center pb-1': libraryMode == 'selector' }">
        <div class="text-center align-center" v-if="libraryMode == 'selector'">
          <div class="file-name my-25">
            <small>{{ item.originalFileName }}</small>
          </div>
          <b-button variant="outline-primary" size="sm" @click="$emit('item-changed', true, item)" v-if="!selected && enableSelection">Select</b-button>
          <b-button variant="warning" size="sm" @click="$emit('item-changed', false, item)" v-if="selected">Deselect</b-button>
        </div>
        <div class="file-name" v-if="libraryMode == 'manage'">
          <small>{{ item.originalFileName }}</small>
        </div>
        <div class="text-center" v-if="libraryMode == 'manage'">
          <feather-icon icon="EditIcon" size="14" class="mr-1" @click="$emit('image-edit', item)" v-if="item.type == 'image'" />
          <feather-icon icon="EyeIcon" size="14" class="mr-1" @click="$emit('show-info', item)" />
          <feather-icon
            icon="TrashIcon"
            size="14"
            class="text-danger"
            @click="$emit('delete', item)"
            v-if="libraryMode == 'manage' && $acl.can('medialibrary.files.delete')"
          />
        </div>
      </b-card-body>
    </b-card>
  </component>
</template>
<script>
const filesize = require('filesize')
import fileIcon from '@/assets/file-icons/index.js'

export default {
  props: ['item', 'tag', 'view', 'selected', 'mode', 'enableSelection'],
  components: {},
  data() {
    return {
      fileIcon,
      imageLoading: this.item.type == 'image',
    }
  },
  watch: {},
  computed: {
    imageBoxStyle() {
      if (this.imageLoading) {
        return { opacity: 0 }
      } else {
        return { opacity: 1 }
      }
    },
    libraryMode() {
      return this.mode ? this.mode : 'manage'
    },
    mediaUrl() {
      return `${this.item.url}?width=160&height=120&ranchor=center&rmode=boxpad`
    },
    mediaSize() {
      return filesize(this.item.size)
    },
  },
  methods: {},
}
</script>
