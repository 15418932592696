<template>
  <div>
    <b-form-group>
      <validation-provider #default="{ errors }" :name="field.label" :rules="rules">
        <codemirror ref="cmEditor" :value="json" :options="cmOptions" @input="onCodeChange" />
        <b-form-text v-if="field.help">{{ field.help }}</b-form-text>
        <!-- <prism-editor v-model="internal" class="json-editor height-300" :highlight="highlighter" line-numbers @blur="update" /> -->
        <div class="d-flex justify-content-between mt-25">
          <div class="text-danger">{{ errors[0] }}</div>
          <div>
            <b-link @click="format" variant="primary" size="sm" class="pr-25">Format Json</b-link>
          </div>
        </div>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/javascript/javascript.js'
import '@/assets/scss/atlas-codemirror.scss'
import { validJson, helpers } from '../Validators/FieldValidators'

export default {
  name: 'JsonField',
  components: { codemirror },
  props: ['field', 'value', 'errors', 'index'],
  data() {
    return {
      internal: '',
      cmOptions: {
        tabSize: 4,
        mode: 'text/javascript',
        theme: 'atlas-codemirror',
        lineNumbers: true,
        line: true,
      },
      validJson,
      helpers,
    }
  },
  computed: {
    rules() {
      const ruleSet = ['validate_json']

      if (this.field.required) {
        ruleSet.push('required')
      }

      return ruleSet.join('|')
    },
    json() {
      if (this.value) {
        return typeof this.value !== 'string' ? JSON.stringify(this.value, null, 2) : this.value
      }
      return ''
    },
  },
  watch: {},
  mounted() {},
  methods: {
    onCodeChange(e) {
      if (e) {
        this.$emit('input', e)
      } else {
        this.$emit('input', null)
      }
    },
    format() {
      if (this.value && this.helpers.isValidJson(this.value)) {
        var str = this.json
        var js = JSON.parse(str)
        this.$emit('input', JSON.stringify(js, null, 2))
      }
    },
  },
}
</script>
