const prepareSingleContentCode = (code, type, id) => {
  let result = code
    .replace('{baseUrl}', 'https://[address]/api/contents')
    .replace('{type}', type)
    .replace('{id}', id)

  return result
}

const singleContentCodes = [
  {
    language: 'Javascript',
    mime: 'text/javascript',
    snippet: `<script>
    const config = {
        headers: { Authorization: 'Bearer {token}' }
    };

    const id = '{id}';
    
    const url = \`{baseUrl}/{type}/\${id}\`;

    const content = await axios.get(url, config);

    console.log(content);
</script>`,
  },
  {
    language: 'C#',
    mime: 'text/x-csharp',
    snippet: `public void Main() {

    var client = new RestClient("{baseUrl}/{type}");
    
    var request = new RestRequest("{id}", Method.GET)
                                .AddHeader("Authorization", "Bearer {token}");
    
    var response = client.Execute<dynamic>(request);

    Console.WriteLine(response.Data);
}`,
  },
  {
    language: 'Swift',
    mime: 'text/x-swift',
    snippet: `let requestUrl = URL( 
    string: "{baseUrl}/{type}/{id}"
)!
var contentRequest = URLRequest( 
    url: requestUrl
)
contentRequest.setValue( 
    "Bearer {token}",
    forHTTPHeaderField: "Authorization"
)

let cancellable = URLSession.shared
    .dataTaskPublisher(for: contentRequest) //5
    .sink(receiveCompletion: { completion in
        switch completion {
        case let .failure(reason):
            print(reason)
        case .finished:
            print("Done without errors")
        }
}) { receivedValue in
    print(String(data: receivedValue.data, encoding: .utf8) ?? "Unknown")
}`,
  },
  {
    language: 'Java',
    mime: 'text/javascript',
    snippet: `URL url = new URL("{baseUrl}/{type}/{id}");
HttpURLConnection con = (HttpURLConnection) url.openConnection();

con.setRequestProperty("Authorization", "Bearer {{token}}");
con.setRequestMethod("GET");

BufferedReader in = new BufferedReader(new InputStreamReader(con.getInputStream()));

String inputLine;

StringBuffer content = new StringBuffer();

while ((inputLine = in.readLine()) != null) {
    content.append(inputLine);
}

in.close();`,
  },
]

export { singleContentCodes, prepareSingleContentCode }
