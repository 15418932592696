<template>
  <div class="d-sm-none d-lg-block links-block">
    <div class="m-0 d-flex align-items-center justify-content-start h5 text-primary font-weight-bold mb-1">
      <feather-icon icon="LinkIcon" size="16" class="mr-50 text-primary" /> Links
    </div>
    <ul class="p-0 ml-2 mt-2 mt-xl-1">
      <li v-for="(link, index) in model.properties.links" :key="`content_link_${index}`" class="my-50">
        <a :href="composeLink(link)" target="_blank" class="text-secondary"
          ><u>{{ link.key }}</u></a
        >
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'LinksBlock',
  props: ['model', 'content', 'expanded'],
  components: {},
  data() {
    return {}
  },
  mounted() {},
  watch: {},
  computed: {},
  created() {},
  methods: {
    composeLink(link) {
      let result = '#'

      if (link.value) {
        result = link.value.replace('{_id}', this.content.id).replace('{_hash}', this.content.hash)

        this.model.attributes.forEach(a => {
          result = result.replace(`{${a.key}}`, this.content.attributes[a.key])
        })
      }

      return result
    },
  },
}
</script>
