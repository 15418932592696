<template>
  <div
    v-if="content"
    class="content-edit-sidebar menu-accordion shadow"
    :class="{
      expanded: !IsContentEditSidebarCollapsed || (IsContentEditSidebarCollapsed && isMouseHovered),
      'bg-primary': IsContentEditSidebarCollapsed,
    }"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <div class="px-0 py-1 bg-primary text-white">
      <div class="d-flex align-items-center px-1" :class="{ 'px-0 justify-content-center': IsContentEditSidebarCollapsed }">
        <div class="text-center ">
          <feather-icon :icon="toggleIcon" class="cursor-pointer m-0 p-0" @click="toggleCollapsed" size="22" />
        </div>
        <h4 class="p-0 m-0 ml-1  text-white" v-if="!IsContentEditSidebarCollapsed">Document Info</h4>
      </div>
      <!-- <hr class="mt-50" /> -->
    </div>
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area pr-1"
      ref="ps"
      tagname="ul"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
          shallShadowTop = hasScrollingSpace
        }
      "
    >
      <div class="blocks">
        <InfoBlock
          :model="model"
          :cultures="cultures"
          :content="content"
          :contents="contents"
          @add-locale="onAddContentLocale"
          :expanded="!IsContentEditSidebarCollapsed || (IsContentEditSidebarCollapsed && isMouseHovered)"
          class="mt-0"
        />

        <LinksBlock
          :model="model"
          :content="content"
          v-if="model.properties.links && model.properties.links.length"
          :expanded="!IsContentEditSidebarCollapsed || (IsContentEditSidebarCollapsed && isMouseHovered)"
          class="mt-3"
        />

        <CodesBlock
          :model="model"
          :content="content"
          :expanded="!IsContentEditSidebarCollapsed || (IsContentEditSidebarCollapsed && isMouseHovered)"
          class="mt-3"
        />
      </div>
      <div class="p-0 d-sm-block d-md-none">
        <feather-icon icon="ChevronLeftIcon" class="cursor-pointer" />
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>
<script>
import LinksBlock from './LinksBlock.vue'
import InfoBlock from './InfoBlock.vue'
import CodesBlock from './CodesBlock.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'EditContent',
  props: ['content', 'contents', 'cultures', 'model'],
  components: {
    LinksBlock,
    InfoBlock,
    CodesBlock,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      shallShadowBottom: false,
      shallShadowTop: false,
      isMouseHovered: false,
    }
  },
  computed: {
    ...mapGetters(['IsContentEditSidebarCollapsed']),
    toggleIcon() {
      return this.IsContentEditSidebarCollapsed ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'
      // return this.IsContentEditSidebarCollapsed ? 'ArrowLeftCircleIcon' : 'ArrowRightCircleIcon'
      //return this.IsContentEditSidebarCollapsed ? 'MoreHorizontalIcon' : 'XIcon'
      // return this.IsContentEditSidebarCollapsed ? 'MenuIcon' : 'ChevronsRightIcon'
    },
    hasScrollingSpace() {
      const self = this
      var element = self.$refs.ps.$el
      if (element) {
        const overflow = element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth
        const scrolledToBottom = element.scrollTop === element.scrollHeight - element.offsetHeight

        return overflow && !scrolledToBottom
      }
      return false
    },
  },
  methods: {
    confirmDeleteContent() {
      this.deleteType = 'all'
      this.$bvModal.show('deleteConfirmModal')
    },
    confirmDeleteSingleContent() {
      this.deleteType = 'single'
      this.$bvModal.show('deleteConfirmModal')
    },
    onAddContentLocale(locale) {
      let self = this

      contents
        .createContentTranslation(self.projectName, self.model.key, self.id, locale)
        .then(res => {
          contents.getContent(self.projectName, self.model.key, res.data.result, { resolve: 'media,mediagallery' }).then(content => {
            const newContent = content.data

            self.originals.push(self.$_.cloneDeep(newContent))
            self.contents.push(newContent)

            self.syncCrossLocaleData(newContent, self.currentContent)
            self.currentContent = self.$_.find(self.contents, c => c.id == newContent.id)

            self.prepareContentStages()

            self.resetErrors()
          })
        })
        .catch(err => {
          self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
        })
    },
    async onDuplicateContent() {
      var self = this

      contents
        .duplicateContentAll(self.projectName, self.model.key, self.currentContent.id)
        .then(res => {
          this.$emit('dirty-change', false)
          self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)

          if (Array.isArray(res.data.result)) {
            this.$router.push({ name: 'atlas_edit_' + self.model.key, params: { id: res.data.result[0] } })
          } else {
            this.$router.push({ name: 'atlas_edit_' + self.model.key, params: { id: res.data.result } })
          }
        })
        .catch(err => {
          self.formErrors = true
          if (err.response.stage == 422) {
            self.errors = err.response.data.errors
          } else {
            self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
          }
        })
    },
    toggleCollapsed() {
      this.$store.commit('UpdateContentEditSidebarCollapsed', !this.IsContentEditSidebarCollapsed)
    },
    updateMouseHovered(val) {
      //this.isMouseHovered = val
    },
  },
}
</script>
