<template>
  <div>
    <b-form-group class="w-25">
      <validation-provider #default="{ errors }" :name="field.label" :rules="rules">
        <input
          :id="field.key"
          v-model="internal"
          type="number"
          :placeholder="placeholder"
          :state="errors.length > 0 ? false : null"
          class="form-control form-control-lg"
          @input="onInputChange(internal)"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import { numRange } from '../Validators/FieldValidators'
export default {
  name: 'NumberField',
  props: ['field', 'value', 'errors', 'index'],
  data() {
    return {
      internal: null,
      numRange,
    }
  },
  computed: {
    rules() {
      const ruleSet = []

      if (this.field.required) {
        ruleSet.push('required')
      }

      if (this.field.editor === 'integer') {
        ruleSet.push('integer')
      }

      if (this.field.min > 0 || this.field.max > 0) {
        ruleSet.push(`num_range:${this.field.min},${this.field.max}`)
      }

      return ruleSet.join('|')
    },
    placeholder() {
      if (this.field.editor === 'integer') return '123...'

      return '123.00...'
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.internal = val
      },
    },
    // internal: {
    //   handler(val) {
    //     if (this.field.editor === 'integer') {
    //       this.$emit('input', parseInt(val, 10))
    //     } else {
    //       this.$emit('input', parseFloat(val))
    //     }
    //   },
    // },
  },
  methods: {
    onInputChange(val) {
      if (this.field.editor === 'integer') {
        this.$emit('input', parseInt(val, 10))
      } else {
        this.$emit('input', parseFloat(val))
      }
    },
  },
}
</script>
