<template> </template>
<script>
export default {
  name: 'KeyValueFieldSettings',
  props: ['value'],
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>

