<template>
  <div class="">
    <page-loader v-if="loading" type="content" :fluid="false" />
    <portal to="nav-bar-left-column" v-if="!loading">
      <ContentPageTitle :model="model" />
    </portal>

    <portal to="nav-bar-right-column" v-if="!loading">
      <EditActions
        :model="model"
        :content="currentContent"
        :contents="contents"
        :content-stages="contentStages"
        :cultures="cultures"
        :is-empty-model="isEmptyModel"
        :is-dirty="isDirty"
        :form-errors="formErrors"
        @save="onSave"
        @delete="onDeleteContent"
        @change-status="onSetStageForContent"
        @duplicate="onDuplicateContent"
      />
    </portal>
    <div class="loading-hack" />

    <div class="content-edit-wrapper justify-content-start" :class="{ expanded: IsContentEditSidebarCollapsed }">
      <div class="content-edit-panel flex-grow-1 container">
        <form-alert :errors="displayErrors" />
        <div class="">
          <b-tabs v-if="!loading" nav-class="mb-1" @activate-tab="onActivateTab" @changed="onChangeTab" v-model="activeTab" ref="contentLocaleTabs">
            <b-tab v-for="content in orderedContents" :key="content.id">
              <template #title>
                <flag-icon :country-code="cultures.getCultureByName(content.locale).isoCountryCode2" class="mr-1" />
                {{ cultures.getCultureByName(content.locale).isoCode3.toUpperCase() }}
                <!-- <span class="pl-25 d-flex align-items-center"><feather-icon icon="AlertCircleIcon" class="m-0 p-0 text-danger"/></span> -->
              </template>
              <div class="d-flex">
                <div class="flex-grow-1 pb-0 mb-0">
                  <div class="justify-content-start" v-if="!isEmptyModel">
                    <validation-observer :ref="'contentsForm_' + content.locale">
                      <fieldset :disabled="!$acl.canOneOf(updateContentsPermission)">
                        <b-form @submit.prevent v-on:keyup.enter="onFormEnterKey">
                          <div v-for="field in fields" :key="field.key">
                            <field-editor
                              v-model="content.attributes[field.key]"
                              :field="field"
                              :locale="content.locale"
                              :errors="errors"
                              @cross-locale-value-change="onCrossLocaleValueChange"
                            />
                          </div>
                        </b-form>
                      </fieldset>
                    </validation-observer>
                  </div>
                  <div class="d-flex justify-content-center align-items-center pt-1 flex-column h-75" v-else>
                    <b-img fluid :src="emptyModelImageUrl" />
                    <h2 class="font-weight-normal mb-2 mt-2">The Model has no fields available.</h2>
                  </div>
                </div>
              </div>
            </b-tab>
            <template #tabs-end>
              <div class="ml-2 flex-grow-1 text-right align-self-end">
                <b-dropdown
                  text="Add Language"
                  variant="outline-primary"
                  size="sm"
                  :right="true"
                  v-if="model.localizable && availableLocales.length > 0"
                  class="right add-locale-dropdown"
                >
                  <b-dropdown-item v-for="al in availableLocales" :key="'al_' + al.culture" @click="onAddContentLocale(al.culture)">
                    <flag-icon :country-code="cultures.getCultureByName(al.culture).isoCountryCode2" class="mr-1" />
                    {{ cultures.getCultureByName(al.culture).displayName }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-tabs>
        </div>
      </div>
    </div>
    <transition name="zoom-fade" mode="out-in">
      <RightSidebar :content="currentContent" :contents="contents" :cultures="cultures" :model="model" />
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { contents } from '@/api/index'
import cultures from '@/libs/cultures-db'
import FlagIcon from '@/components/FlagIcon/Index.vue'
import DefaultNotifications from '@/components/Notification/default'
import EditActions from './EditActions.vue'
import RightSidebar from './RightSidebar.vue'
import ContentPageTitle from './ContentPageTitle.vue'
import FormComponent from '@/mixins/FormComponent'

export default {
  name: 'EditContent',
  props: ['model'],
  components: {
    FlagIcon,
    EditActions,
    RightSidebar,
    ContentPageTitle,
  },
  mixins: [FormComponent],
  data() {
    return {
      cultures,
      formErrors: false,
      displayErrors: [],
      errors: [],
      isDirty: false,
      contents: [],
      originals: [],
      contentStages: [],
      currentContent: null,
      deleteType: 'single',
      activeTab: 0,
      loading: false,
      emptyModelImage: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  mounted() {},
  watch: {
    contents: {
      deep: true,
      handler(newValue) {
        this.isDirty = !this.$_.isEqual(newValue, this.originals)
        this.$emit('dirty-change', this.isDirty)
      },
    },
  },
  computed: {
    ...mapGetters(['Project', 'Settings', 'IsContentEditSidebarCollapsed']),
    settingsLocales() {
      let orderedLocales = this.$_.orderBy(this.Settings.locales, ['isDefault', 'locale'], ['desc', 'asc'])

      let locales = []

      this.$_.each(orderedLocales, l => {
        locales.push(this.cultures.getCultureByName(l.locale))
      })

      return locales
    },
    availableLocales() {
      let locales = []

      this.$_.each(this.settingsLocales, l => {
        let index = this.$_.findIndex(this.contents, c => c.locale == l.culture)

        if (index < 0) {
          locales.push(l)
        }
      })

      return locales
    },
    id() {
      return this.$route.params.id
    },
    fields() {
      return this.model.attributes
    },
    orderedContents() {
      let self = this
      let locales = self.settingsLocales
      return self.$_.sortBy(self.contents, c => {
        return self.$_.findIndex(locales, l => l.culture == c.locale)
      })
    },
    updateContentsPermission() {
      return [`contents.${this.model.id}.update`, `contents.*.update`]
    },
    emptyModelImageUrl() {
      return this.emptyModelImage
    },
    isEmptyModel() {
      return !this.fields || this.fields.length == 0
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData(noLoading) {
      const self = this

      if (!noLoading) self.loading = true

      let tempContents = []

      let mainContent = (await contents.getContent(self.projectName, self.model.key, self.id, { resolve: 'media,mediagallery' })).data

      tempContents.push(mainContent)

      if (mainContent.locales) {
        for (let index = 0; index < mainContent.locales.length; index++) {
          let existLocale = this.$_.findIndex(self.settingsLocales, c => c.culture == mainContent.locales[index].locale)
          if (existLocale >= 0) {
            let lc = (
              await contents.getContent(self.projectName, self.model.key, mainContent.locales[index].id, {
                resolve: 'media,mediagallery',
              })
            ).data
            tempContents.push(lc)
          }
        }
      }

      self.loading = false

      self.prepareContents(tempContents)
      self.prepareContentStages()
    },
    prepareContents(contents) {
      const self = this

      self.originals = contents
      self.contents = self.$_.cloneDeep(contents)

      if (!self.currentContent) {
        self.currentContent = self.$_.find(self.contents, c => c.id == self.id)
      } else {
        self.currentContent = self.$_.find(self.contents, c => c.id == self.currentContent.id)
      }
    },
    prepareContentStages() {
      const self = this
      self.contentStages = self.originals.map(m => ({
        id: m.id,
        locale: m.locale,
        stage: m.stage,
      }))
    },
    async onFormEnterKey() {
      const self = this
      if (self.contents.length > 0 && self.isDirty && !self.isEmptyModel) {
        await self.onSave()
      }
    },
    async onSetStageForContent(evt) {
      const self = this

      const contentIndex = self.$_.findIndex(self.contents, c => c.id == evt.id)
      const stageContent = self.$_.find(self.contentStages, c => c.id == evt.id)

      stageContent.stage = evt.stage

      let fx = stageContent.stage == 'published' ? contents.publishContent : contents.unpublishContent

      await fx(self.projectName, self.model.key, stageContent.id)

      let updatedContent = await contents.getContent(self.projectName, self.model.key, stageContent.id, {
        resolve: 'media,mediagallery',
      })

      self.contents[contentIndex] = updatedContent.data
      self.prepareContents(self.contents)
      self.prepareContentStages()
    },
    async onSave() {
      var self = this

      var validations = await self.validateForms()

      if (!self.formErrors) {
        let payload = {
          contents: self.contents.map(c => {
            return { id: c.id, attributes: c.attributes }
          }),
        }

        contents
          .updateLocaleSet(self.projectName, self.model.key, payload)
          .then(res => {
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
            self.originals = self.$_.cloneDeep(self.contents)
            self.contents = self.$_.cloneDeep(self.originals)
            self.resetErrors()
          })
          .catch(err => {
            self.formErrors = true
            if (err.response.status == 422) {
              self.handleServerErrorMessages(err.response.data)
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        //console.log(validations)
      }
    },
    async onDuplicateContent() {
      var self = this

      contents
        .duplicateContentAll(self.projectName, self.model.key, self.currentContent.id)
        .then(res => {
          self.$emit('dirty-change', false)
          self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
          if (Array.isArray(res.data.result)) {
            self.$router.push({ name: 'atlas_edit_' + self.model.key, params: { id: res.data.result[0] } })
          } else {
            self.$router.push({ name: 'atlas_edit_' + self.model.key, params: { id: res.data.result } })
          }
        })
        .catch(err => {
          self.formErrors = true
          if (err.response.stage == 422) {
            self.errors = err.response.data.errors
          } else {
            self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
          }
        })
    },
    async onDeleteContent(evt) {
      const self = this
      if (evt.deleteType == 'single' && self.contents.length > 1) {
        await contents.deleteContent(self.projectName, self.model.key, self.currentContent.id, false)

        const contentIndex = self.contents.findIndex(p => p.id === self.currentContent.id)
        self.contents.splice(contentIndex, 1)

        const originalIndex = self.originals.findIndex(p => p.id === self.currentContent.id)
        self.originals.splice(originalIndex, 1)

        self.resetErrors()

        if (self.contents.findIndex(p => p.id === self.currentContent.id) < 0 && self.currentContent.id == self.id) {
          self.isDirty = false
          self.$router.push({ name: 'atlas_edit_' + self.model.key, params: { id: self.contents[0].id } })
        } else {
          self.$refs.contentLocaleTabs.tabs[0].activate()
          self.prepareContentStages()
        }
      } else {
        await contents.deleteContent(self.projectName, self.model.key, self.currentContent.id, true)

        self.isDirty = false
        self.resetErrors()

        if (self.model.isSingle) {
          self.$router.push({ name: 'atlas_single_' + self.model.key })
        } else {
          self.$router.push({ name: 'atlas_list_' + self.model.key })
        }
      }

      self.$bvModal.hide('deleteConfirmModal')

      self.$bus.$emit('notification', DefaultNotifications.deleteSuccessful)
    },
    async validateForms() {
      let self = this

      self.formErrors = false

      const validations = []

      for (let index = 0; index < self.contents.length; index++) {
        const v = await self.$refs['contentsForm_' + self.contents[index].locale][0].validateWithInfo()
        if (!v.isValid) {
          self.formErrors = true
          validations.push(v)
        }
      }

      return validations
    },
    onAddContentLocale(locale) {
      let self = this

      contents
        .createContentTranslation(self.projectName, self.model.key, self.id, locale)
        .then(res => {
          contents.getContent(self.projectName, self.model.key, res.data.result, { resolve: 'media,mediagallery' }).then(content => {
            const newContent = content.data

            self.originals.push(self.$_.cloneDeep(newContent))
            self.contents.push(newContent)

            self.syncCrossLocaleData(newContent, self.currentContent)
            self.currentContent = self.$_.find(self.contents, c => c.id == newContent.id)

            self.prepareContentStages()

            self.resetErrors()
          })
        })
        .catch(err => {
          self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
        })
    },
    onChangeTab(c, p) {
      let tab = this.orderedContents.findIndex(p => p.id === this.currentContent.id)
      this.activeTab = tab
    },
    async onActivateTab(newTab) {
      this.currentContent = this.orderedContents[newTab]

      // const self = this
      // this.$router.push({ name: 'atlas_edit_' + self.model.key, params: { id: this.currentContent.id } })
    },
    onCrossLocaleValueChange(evt) {
      // console.log(evt)
      const self = this
      const path = evt.path
      const locale = evt.locale
      self.contents.forEach((language, i) => {
        if (language.locale !== locale) {
          self.contents[i].attributes[path] = self.$_.clone(evt.event)
        }
      })
    },
    syncCrossLocaleData(newContent, content) {
      for (var index in this.model.attributes) {
        if (this.model.attributes[index].localizable === false) {
          if (newContent.attributes.hasOwnProperty(this.model.attributes[index].key)) {
            newContent.attributes[this.model.attributes[index].key] = content.attributes[this.model.attributes[index].key]
          }
        }
      }
    },
    resetErrors() {
      this.displayErrors = []
      this.errors = []
    },
    handleServerErrorMessages(response) {
      const uniqueErrors = []
      const structuredErrors = []

      for (let i = 0; i < response.errors.length; i++) {
        let serverError = response.errors[i]

        serverError.paths = []

        let error = uniqueErrors.find(e => e.message == serverError.message)

        if (!error) {
          uniqueErrors.push(serverError)
        }

        let errorPropertyPath = serverError.propertyName.split('.')

        let index = 0

        for (let pi = 0; pi < errorPropertyPath.length; pi++) {
          let path = errorPropertyPath[pi]
          if (path.startsWith('[') && path.endsWith(']')) {
            index = path.replace(/^\[+|\]+$/g, '')
          } else {
            if (pi > 0) {
              serverError.paths.push({ path: serverError.paths[serverError.paths.length - 1].path + '.' + path, index: index })
            } else {
              serverError.paths.push({ path: path, index: index })
            }
          }
        }

        structuredErrors.push(serverError)
      }

      this.displayErrors = uniqueErrors
      this.errors = structuredErrors
    },
    prepareToExit() {
      if (this.isDirty) {
        const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
        if (answer) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    checkDirtyDataOnExit(e) {
      if (!this.isDirty) return
      e.preventDefault()
      e.returnValue = ''
    },
  },
}
</script>
