<template>
  <div></div>
</template>
<script>
export default {
  name: 'ColorPickerFieldSettings',
  props: ['value'],
  data() {
    return {}
  },
  computed: {
    item() {
      return this.value ? this.value : { name: '' }
    },
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.item, [key]: value })
    },
  },
}
</script>
