<template>
  <b-card no-body class="mb-1" :class="{ 'field-error': hasError }">
    <div class="p-1 rounded-top" :class="{ 'cursor-move': dragging }">
      <div class="d-flex align-items-center">
        <div class="mr-1">
          <feather-icon :icon="visible ? 'ChevronUpIcon' : 'ChevronRightIcon'" size="18" class="cursor-pointer" @click="onToggleBox" />
        </div>
        <div class="flex-grow-1 cursor-pointer drag-handle" @click="onToggleBox">
          <b-media no-body>
            <b-media-aside class="mr-50" v-if="getImage(internal)">
              <b-avatar size="36" icon="card-image" :src="getImage(internal)" variant="primary"></b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <div v-if="getTitle(internal)">
                <h5 class="font-weight-bolder mb-0">
                  {{ getTitle(internal) }}
                </h5>
                <b-card-text class="font-small-2 mb-0">
                  {{ component.name }}
                </b-card-text>
              </div>
              <div v-else>
                <h5 class="font-weight-bolder mb-0">
                  {{ component.name }}
                </h5>
              </div>
            </b-media-body>
          </b-media>
        </div>
        <div class="ml-2">
          <b-dropdown variant="link" no-caret toggle-class="p-0" right>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="$emit('on-remove-item')">
              <feather-icon icon="TrashIcon" class="text-danger darken-2" />
              <span class="align-middle ml-50 text-danger text-darken-2">Remove</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <b-collapse class="mt-0" :visible="visible">
      <b-card-body class="mt-0 pt-0">
        <div class="d-flex align-items-start mt-0">
          <singlecomponent-field
            :component="component"
            :value="internal"
            :parent-key="parentKey"
            :locale="locale"
            :errors="errors"
            :index="index"
            class="flex-grow-1"
            @input="onInput($event)"
            @on-error-status-change="e => (hasError = e)"
          />
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>
export default {
  name: 'MultiComponentField',
  props: {
    field: {
      type: Object,
      default: null,
    },
    value: {},
    nested: {
      type: Boolean,
      default: false,
    },
    parentKey: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
    component: {
      type: Object,
      default: {},
    },
    model: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: [],
    },
    index: {
      type: Number,
      default: 0,
    },
    dragging: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      internal: [],
      hasError: false,
      visible: false,
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: this.field.key,
        disabled: false,
        ghostClass: 'ghost',
        scrollSensitivity: 200,
        forceFallback: true,
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.internal = val ? val : {}
      },
    },
    open: {
      immediate: true,
      deep: true,
      handler(val) {
        this.visible = val
      },
    },
  },
  mounted() {},
  methods: {
    onInput(event) {
      this.$emit('input', event)
    },
    getTitle(item) {
      for (let index = 0, len = this.component.attributes.length; index < len; index++) {
        let attribute = this.component.attributes[index]
        if (attribute.type == 'text') {
          let value = item[attribute.key]

          if (value) return value
        }
      }
      return null
    },
    getImage(item) {
      for (let index = 0, len = this.component.attributes.length; index < len; index++) {
        let attribute = this.component.attributes[index]
        if (attribute.type == 'media') {
          let value = item[attribute.key]

          if (value) return value.url
        }
      }
      return null
    },
    onToggleBox() {
      this.visible = !this.visible
    },
  },
}
</script>
