export default {
  data() {
    return {
      genericError: false,
      serverErrors: [],
      clientValidationsErrors: [],
    }
  },
  computed: {
    hasServerErrors() {
      return (this.serverErrors && this.serverErrors.length) || this.genericError
    },
    hasClientErrors() {
      return this.clientValidationsErrors && this.clientValidationsErrors.length
    },
    hasErrors() {
      return this.hasServerErrors || this.hasClientErrors
    },
  },
  methods: {
    ResetErrors() {
      this.genericError = false
      this.serverErrors = []
      this.clientValidationsErrors = []
    },
    HandleUnprocessableEntity(error) {
      if (error && error.response && error.response.status == 422) {
        if (error.response.data && error.response.data.code && error.response.data.code == 'plan_limit_exceeded') {
          this.$bus.$emit('plan-limit-exceeded', true)
        } else {
          this.serverErrors = error.response.data.errors
        }
      }
    },
  },
}
