<template>
  <div>
    <b-link class="font-weight-normal text-secondary" v-b-toggle.sidebar-filters v-b-tooltip.hover.v-secondary.top title="Advanced Filters">
      <FeatherIcon icon="FilterIcon" size="18" />
    </b-link>
    <!-- <b-link v-b-tooltip.hover.v-secondary.top title="Reset Filters" class="font-weight-normal text-secondary d-block">
      <span v-html="FilterOff" style="width:18px;" class="d-block" />
    </b-link> -->
    <b-sidebar id="sidebar-filters" bg-variant="white" right backdrop shadow width="30%">
      <div class="text-center px-1">
        <!-- <b-avatar size="72px" class="mr-1" :src="require('@/assets/images/portrait/small/avatar-s-8.jpg')" /> -->
        <b-card-text class="mt-2 h4 color-inherit text-reset">
          Filters
        </b-card-text>
        <small> Add or remove filters to apply on the {{ model.name }} collection. </small>
      </div>
      <b-card no-body>
        <b-card-body> </b-card-body>
      </b-card>
    </b-sidebar>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import FilterOff from '!!raw-loader!@/assets/images/icons/filter-off.svg'

export default {
  name: 'FiltersPanel',
  components: { vSelect, FeatherIcon },
  props: ['model'],
  data() {
    return {
      FilterOff,
    }
  },
  watch: {
    model: {
      immediate: true,
      handler(val) {
        if (val) {
        }
      },
    },
  },
  computed: {},
  methods: {},
}
</script>
<style lang="scss">
#sidebar-right {
  min-width: 500px;
}
</style>
