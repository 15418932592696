import Contents from '@/views/Project/Contents/Index.vue'
import navMenuItems from '@/navigation'

const createContentRoutes = (models, org, project) => {
  let routes = []

  if (models.length) {
    _.each(
      models.filter(m => m.system === false),
      function(t) {
        if (t.isSingle) {
          routes.push({
            name: 'atlas_single_' + t.key,
            component: Contents,
            path: `/app/:project/contents/${t.key}`,
            meta: { active: true, layout: '', requiresAuth: true, type: t.key, typeId: t.id, isSingle: true, contentRoute: true, actionBar: true },
          })
        } else {
          routes.push({
            name: 'atlas_list_' + t.key,
            component: Contents,
            path: `/app/:project/contents/${t.key}`,
            meta: { active: true, layout: '', requiresAuth: true, type: t.key, typeId: t.id, isList: true, contentRoute: true, actionBar: true },
          })
        }

        routes.push({
          name: 'atlas_new_' + t.key,
          component: Contents,
          path: `/app/:project/contents/${t.key}/new`,
          meta: { active: true, layout: '', requiresAuth: true, type: t.key, typeId: t.id, isNew: true, contentRoute: true, actionBar: true },
        })

        routes.push({
          name: 'atlas_edit_' + t.key,
          component: Contents,
          path: `/app/:project/contents/${t.key}/:id`,
          meta: { active: true, layout: '', requiresAuth: true, type: t.key, typeId: t.id, isEdit: true, contentRoute: true, actionBar: true },
        })
      }
    )
  }

  return routes
}

const createContentMenu = (models, org, project) => {
  const contentReadPermissions = []

  let menu = [
    {
      title: 'Dashboard',
      icon: 'HomeIcon',
      route: 'Dashboard',
      params: { project: '' },
    },
  ]

  if (models && models.filter(m => m.system === false).length) {
    menu.push({
      header: 'Contents',
      permissions: contentReadPermissions,
    })

    _.each(
      models.filter(m => m.system === false),
      function(t) {
        let permission = [`contents.${t.id}.read`, `contents.*.read`]
        contentReadPermissions.push(...permission)

        if (t.isSingle) {
          menu.push({
            title: t.name,
            route: 'atlas_single_' + t.key,
            icon: t.properties.icon ? `${t.properties.icon}Icon` : 'EditIcon',
            meta: { contentRoute: true },
            permissions: [...permission],
          })
        } else {
          menu.push({
            title: t.name,
            route: 'atlas_list_' + t.key,
            icon: t.properties.icon ? `${t.properties.icon}Icon` : 'EditIcon',
            meta: { contentRoute: true },
            permissions: [...permission],
          })
        }
      }
    )
  }

  menu.push(...navMenuItems)

  menu.forEach(m => {
    if (m.params) {
      if (m.params.hasOwnProperty('project')) {
        m.params.project = project.name
      }
    }
  })

  return menu
}

export { createContentMenu, createContentRoutes }
