<template>
  <b-card no-body class="mb-1">
    <b-card-body class="d-flex justify-content-between align-items-center p-1">
      <feather-icon icon="AlignJustifyIcon" size="17" class="cursor-move d-sm-block d-none mr-1 drag-handle" />
      <b-avatar size="45" alt="" class="field-avatar" v-b-tooltip.hover.v-secondary :title="fieldTitle()">
        <img :src="imageUrl()" width="30%" height="40%" />
      </b-avatar>
      <div class="truncate flex-grow-1 ml-2">
        <h4 class="mb-0 font-weight-bolder">
          {{ field.label }}
        </h4>
        <div>{{ field.description }}</div>
        <div class="inline-spacing small-spacing">
          <b-badge v-b-tooltip.hover.v-secondary.bottom title="API key" pill variant="light-dark font-weight-normal"> {{ field.key }} </b-badge>
          <b-badge v-b-tooltip.hover.v-secondary.bottom title="Required" pill variant="light-dark font-weight-normal" v-if="field.required">
            <feather-icon icon="CheckIcon" size="17" class="align-middle text-body" />
          </b-badge>
          <b-badge v-b-tooltip.hover.v-secondary.bottom title="Hidden" pill variant="light-dark font-weight-normal" v-if="field.hidden">
            <feather-icon icon="EyeOffIcon" size="17" class="align-middle text-body" />
          </b-badge>
          <b-badge v-b-tooltip.hover.v-secondary.bottom title="Localizable" pill variant="light-dark font-weight-normal" v-if="field.localizable">
            <feather-icon icon="GlobeIcon" size="17" class="align-middle text-body" />
          </b-badge>
        </div>
      </div>
      <div>
        <b-button variant="link" icon="form" @click="$emit('edit')">Edit</b-button>
        <b-dropdown variant="link" no-caret toggle-class="p-0" right class="ml-2">
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body" />
          </template>
          <b-dropdown-item @click="$emit('field-duplicate')">
            <feather-icon icon="CopyIcon" />
            <span class="align-middle ml-50">Duplicate</span>
          </b-dropdown-item>
          <b-dropdown-item @click="$emit('field-delete')">
            <feather-icon icon="TrashIcon" class="text-danger darken-2" />
            <span class="align-middle ml-50 text-danger text-darken-2">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import { fields } from './manifest'
export default {
  name: 'FieldSettingsCard',
  props: ['field', 'image'],
  data() {
    return {
      fields,
    }
  },
  computed: {},
  watch: {},
  methods: {
    imageUrl() {
      const field = this.$_.find(this.fields, f => f.settings.type === this.field.type)
      return require(`@/assets/images/fields/${field.image}`)
    },
    fieldTitle() {
      const field = this.$_.find(this.fields, f => f.settings.type === this.field.type)
      return field.name
    },
  },
}
</script>

