<template>
  <div>
    <div class="d-flex align-items-center justify-content-start">
      <div>
        <h4 class="mb-0 pb-0 page-title">
          {{ title }}
        </h4>
      </div>
      <div class="ml-50">
        <slot name="after-title"></slot>
      </div>
    </div>
    <div>
      <small class="font-weight-normal mt-0 p-0" v-if="subtitle">{{ subtitle }}</small>
    </div>
  </div>
</template>
<script>
export default {
  props: ['icon', 'title', 'section', 'subtitle'],
}
</script>
<style lang="scss">
.page-title {
  line-height: 1.4rem;
}
</style>

