import { extend, localize } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'

// Text & Rich Text
const textLengthRangeValidator = {
  message: (fieldName, { min, max }) => {
    if (min > 0 && max > 0) {
      return `The ${fieldName} field must be between ${min} and ${max} characters`
    } else if (min > 0 && (max == 0 || !max)) {
      return `The ${fieldName} field must be at least ${min} characters`
    } else if (max > 0 && (min == 0 || !min)) {
      return `The ${fieldName} field must be at max ${max} characters`
    }
  },
  validate(value, { min, max }) {
    var length = value.length

    if (min > 0 && max > 0) {
      return length >= min && length <= max
    } else if (min > 0 && (max == 0 || !max)) {
      return length >= min
    } else if (max > 0 && (min == 0 || !min)) {
      return length >= min
    }

    return true
  },
  params: ['min', 'max'],
}

export const textLengthRange = extend('text_length_range', textLengthRangeValidator)

const numRangeValidator = {
  message: (fieldName, { min, max }) => {
    if (min > 0 && max > 0) {
      return `The ${fieldName} field must be between ${min} and ${max}`
    } else if (min > 0 && (max == 0 || !max)) {
      return `The ${fieldName} field must be greater or equal to ${min}`
    } else if (max > 0 && (min == 0 || !min)) {
      return `The ${fieldName} field must be less or equal than ${max}`
    }
  },
  validate(value, { min, max }) {
    if (min > 0 && max > 0) {
      return value >= min && value <= max
    } else if (min > 0 && (max == 0 || !max)) {
      return value >= min
    } else if (max > 0 && (min == 0 || !min)) {
      return value >= min
    }

    return true
  },
  params: ['min', 'max'],
}

export const numRange = extend('num_range', numRangeValidator)

//JSON
const jsonValidator = {
  validate: val => {
    const result = isValidJson(val)
    return result
  },
  message: 'The content is not a valid Json',
}

const isValidJson = val => {
  val = typeof val !== 'string' ? JSON.stringify(val) : val

  try {
    val = JSON.parse(val)
  } catch (e) {
    return false
  }

  if (typeof val === 'object' && val !== null) {
    return true
  }

  return false
}

export const validJson = extend('validate_json', jsonValidator)

export const helpers = { isValidJson }
