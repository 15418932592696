<template>
  <div>
    <div>
      <b-form-group>
        <validation-provider #default="{ errors }" :name="field.label" :rules="rules">
          <div v-if="!readOnly && !field.readOnly">
            <b-form-tags
              :value="value"
              input-id="tags-pills"
              tag-class="b-form-tag-success"
              size="lg"
              placeholder="Enter new tags"
              class="mb-2"
              add-button-variant="primary"
              :disabled="field.readOnly"
              @input="$emit('input', $event)"
            />
            <!-- <v-select v-model="internal" :taggable="field.enum == 0" multiple class="select-size-lg" :options="field.enum" :disabled="field.readOnly"></v-select> -->
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
          <div v-else>
            <b-badge v-for="(item, index) in value" :key="index" variant="success" class="mr-1">
              {{ item }}
            </b-badge>
          </div>
        </validation-provider>
      </b-form-group>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TagField',
  props: ['field', 'value', 'readOnly', 'errors', 'index'],
  data() {
    return {
      internal: [],
    }
  },
  //   watch: {
  //     value: {
  //       immediate: true,
  //       handler(val) {
  //         this.internal = val
  //       },
  //     },
  //     internal: {
  //       handler(val) {
  //         this.$emit('input', this.internal)
  //       },
  //     },
  //   },
  computed: {
    rules() {
      const ruleSet = []

      if (this.field.required) {
        ruleSet.push('required')
      }

      return ruleSet.join('|')
    },
  },
  methods: {},
}
</script>
