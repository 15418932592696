<template>
  <div>
    <div v-if="$acl.canOneOf(createContentsPermission)">
      <b-container class="ml-auto mb-2">
        <portal to="nav-bar-left-column">
          <ContentPageTitle :model="model" />
        </portal>
        <portal to="nav-bar-right-column">
          <div class="d-flex align-items-center" v-if="!isEmptyModel">
            <span v-if="formErrors" class="pl-25 d-flex align-items-center text-danger mr-1"
              ><feather-icon icon="AlertCircleIcon" class="m-0 p-0 ml-1 mr-25" />There are errors on the page that prevent to save changes</span
            >
            <b-button variant="primary" @click="save(false)">Save</b-button>
          </div>
        </portal>
        <div class="d-flex justify-content-center" v-if="!isEmptyModel">
          <div class="w-100 pb-0 mb-0">
            <div>
              <validation-observer ref="contentForm">
                <b-form>
                  <div v-for="field in fields" :key="field.key">
                    <field-editor :field="field" v-model="content.attributes[field.key]" :locale="content.locale" :errors="errors" />
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center pt-1 flex-column h-75" v-else>
          <b-img fluid :src="emptyModelImageUrl" />
          <h2 class="font-weight-normal mb-2 mt-2">The Model has no fields available, do you want to create the element anyway?</h2>
          <div class="d-flex flex-column justify-content-center">
            <b-button variant="primary" @click="save(true)" class="mb-1 text-center">Create</b-button>
          </div>
        </div>
      </b-container>
    </div>
    <div v-else>
      <forbidden />
    </div>
  </div>
</template>
<script>
import { contents } from '@/api/index'
import { mapGetters } from 'vuex'
import cultures from '@/libs/cultures-db'
import DefaultNotifications from '@/components/Notification/default'
import ContentPageTitle from './ContentPageTitle.vue'
import FormComponent from '@/mixins/FormComponent'

export default {
  name: 'NewContent',
  props: ['model'],
  components: {
    ContentPageTitle,
  },
  mixins: [FormComponent],
  data() {
    return {
      cultures,
      formErrors: false,
      isDirty: false,
      original: { id: '', locale: '', attributes: {} },
      content: { id: '', locale: '', attributes: {} },
      emptyModelImage: require('@/assets/images/pages/not-authorized.svg'),
      errors: [],
    }
  },
  mounted() {
    this.content.locale = this.currentLocale.culture
    this.original.locale = this.currentLocale.culture
  },
  watch: {
    content: {
      deep: true,
      handler(newValue) {
        this.isDirty = !this.$_.isEqual(newValue, this.original)
        this.$emit('dirty-change', this.isDirty)
      },
    },
  },
  computed: {
    ...mapGetters(['Project', 'Settings']),
    fields() {
      return this.model.attributes
    },
    createContentsPermission() {
      return [`contents.${this.model.id}.create`, `contents.*.create`]
    },
    defaultLocale() {
      var defaultLocale = this.$_.find(this.Settings.locales, l => l.isDefault)

      return this.getLocaleData(defaultLocale.locale)
    },
    currentLocale() {
      let locale = this.$route.query.locale
      let settingLocaleIndex = this.$_.findIndex(this.settingsLocales, l => l.culture == locale)

      if (settingLocaleIndex >= 0) {
        return this.getLocaleData(locale)
      }

      return this.defaultLocale
    },
    settingsLocales() {
      let orderedLocales = this.$_.orderBy(this.Settings.locales, ['isDefault', 'locale'], ['desc', 'asc'])

      let locales = []

      this.$_.each(orderedLocales, l => {
        locales.push(this.getLocaleData(l.locale))
      })

      return locales
    },
    emptyModelImageUrl() {
      return this.emptyModelImage
    },
    isEmptyModel() {
      return !this.fields || this.fields.length == 0
    },
  },
  created() {},
  methods: {
    async save(empty) {
      const self = this
      let valid = true

      if (!empty) {
        const validation = await self.$refs.contentForm.validateWithInfo()
        self.formErrors = !validation.isValid
        valid = validation.isValid
      }

      if (valid) {
        contents
          .createContent(self.projectName, this.model.key, this.content)
          .then(res => {
            this.$emit('dirty-change', false)
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
            self.$router.push({ name: 'atlas_edit_' + self.model.key, params: { id: res.data.id } })
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.HandleUnprocessableEntity(err)
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      }
    },
    onChangeLocale(content) {},
    getLocaleData(locale) {
      return this.cultures.getCultureByName(locale)
    },
  },
}
</script>
