<template>
  <div class="divider mt-2 mb-1 text-left">
    <div class="divider-text p-0 pr-1">
      <h6 class="text-primary font-weight-normal mb-0 text-uppercase">{{ text }}</h6>
    </div>
  </div>
</template>
<script>
export default {
  props: ['text'],
}
</script>
