<template>
  <div>
    <b-row class="mb-2">
      <b-col lg="6" md="12">
        <b-input-group>
          <b-form-input placeholder="Search by address..." v-model="address" size="md" v-on:keypress.enter.prevent="onSearchByAddress" />
          <b-input-group-append>
            <b-button variant="outline-primary" @click="onSearchByAddress">
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <span class="text-danger" v-if="searchByAddressError">Address not found!</span>
      </b-col>
      <b-col lg="6" sm="12">
        <b-input-group>
          <b-form-input placeholder="Set coordinates (lat,lng)..." v-model="coordinates" size="md" v-on:keypress.enter.prevent="onSetPin" />
          <b-input-group-append>
            <b-button variant="outline-primary" @click="onSetPin">
              <feather-icon icon="MapPinIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <span class="mt-1 text-danger" v-if="setPinByCoordinatesError">Coordinates not valid. Be sure they are in the form of lat,lng!</span>
      </b-col>
    </b-row>

    <l-map
      @dblclick="onMapClick"
      style="height: 450px"
      :zoom="zoom"
      :center="[(internal && internal.lat) || defaultLocation.lat, (internal && internal.lng) || defaultLocation.lng]"
      ref="map"
      v-observe-visibility="onMapVisibilityChanged"
    >
      <l-tile-layer :url="tileProvider.url" :attribution="tileProvider.attribution" />
      <l-marker
        v-if="internal && internal.lat && internal.lng"
        visible
        draggable
        :icon="icon"
        :lat-lng.sync="internal"
        @dragstart="onDragStart"
        @dragend="onDragEnd"
        @update:latLng="onUpdateMarker"
      >
      </l-marker>
    </l-map>
  </div>
</template>
<script>
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import { icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/dist/geosearch.css'

export default {
  name: 'LeafletMapField',
  props: ['field', 'value', 'errors', 'index'],
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
      loading: false,
      address: '',
      searchByAddressError: false,
      coordinates: '',
      setPinByCoordinatesError: false,
      internal: { lat: 0, lng: 0 },
      defaultLocation: { lat: 0, lng: 0 },
      tileProvider: {
        attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
      icon: icon({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        iconSize: [24, 36],
        iconAnchor: [12, 36],
      }),
      dragging: false,
      zoom: 12,
      markerLatLng: [51.504, -0.159],
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.internal = val
      },
    },
  },
  computed: {},
  mounted() {
    const self = this

    if (!self.internal) {
      self.getUserPosition()
    }

    setTimeout(() => {
      self.$refs.map.mapObject.invalidateSize()
    }, 0)
  },
  methods: {
    onMapVisibilityChanged(event) {
      if (event) {
        this.$refs.map.mapObject.invalidateSize()
      }
    },
    async onMapClick(value) {
      this.internal = value.latlng
      this.$emit('input', this.internal)
    },
    onSearch(value) {
      const loc = value.location
      this.internal = { lat: loc.y, lng: loc.x }
    },
    async getUserPosition() {
      // check if API is supported
      if (navigator.geolocation) {
        // get  geolocation
        navigator.geolocation.getCurrentPosition(pos => {
          // set user location
          this.internal = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          }
        })
      }
    },
    async onSearchByAddress(e) {
      if (this.address) {
        this.loading = true
        try {
          const result = await fetch(`https://nominatim.openstreetmap.org/search?format=jsonv2&q=${this.address}`)
          if (result.status === 200) {
            const body = await result.json()

            if (!body || body.length == 0) {
              this.searchByAddressError = true
            } else {
              this.internal.lat = body[0].lat
              this.internal.lng = body[0].lon
              this.searchByAddressError = false
              this.$emit('input', this.internal)
            }
          }
        } catch (e) {
          console.error('Reverse Geocode Error->', e)
        }
        this.loading = false
      }
    },
    onSetPin() {
      if (this.coordinates) {
        try {
          const coordinates = this.coordinates.split(',')
          if (coordinates && coordinates.length == 2) {
            this.internal = { lat: coordinates[0], lng: coordinates[1] }
            this.$emit('input', this.internal)
            this.setPinByCoordinatesError = false
          } else {
            this.setPinByCoordinatesError = true
          }
        } catch (e) {
          this.setPinByCoordinatesError = true
        }
      }
    },
    onUpdateMarker(val) {
      this.internal = { lat: val.lat, lng: val.lng }
      this.$emit('input', this.internal)
    },
    onDragStart(val) {
      this.dragging = true
    },
    onDragEnd(val) {
      this.dragging = false
    },
  },
}
</script>
