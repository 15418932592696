<template>
  <div v-if="model">
    <feather-icon
      icon="ColumnsIcon"
      size="18"
      class="cursor-pointer d-sm-block d-none mr-1"
      v-b-toggle.sidebar-column-selector
      v-b-tooltip.hover.v-secondary.top
      title="Column Selector"
    />
    <b-sidebar id="sidebar-column-selector" bg-variant="white" right backdrop shadow width="25%">
      <div class="text-center px-1">
        <!-- <b-avatar size="72px" class="mr-1" :src="require('@/assets/images/portrait/small/avatar-s-8.jpg')" /> -->
        <b-card-text class="mt-2 h4 color-inherit text-reset">
          Column selector
        </b-card-text>
        <small>
          Add or remove columns. To change the column order, drag and drop a field.
        </small>
      </div>
      <b-card no-body>
        <b-card-body v-if="visibleColumns.length == 0">
          NO COLUMNS
        </b-card-body>
        <b-card-body>
          <b-form class="p-2">
            <draggable
              v-bind="dragOptions"
              v-model="visibleColumns"
              tag="ul"
              class="list-group"
              handle=".drag-handle"
              @start="onDragFieldStart"
              @end="onDragFieldEnd"
              :group="{ name: 'columns' }"
              v-if="visibleColumns.length > 0"
            >
              <transition-group type="transition" name="flip-list">
                <div class="shadow rounded mb-1 p-1" v-for="(column, index) in visibleColumns" :key="'c_' + index">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <feather-icon icon="AlignJustifyIcon" size="17" class="cursor-move d-sm-block d-none mr-1 drag-handle" />
                    <div class="flex-grow-1">
                      <h5 class="m-0">{{ column.label }}</h5>
                    </div>
                    <div class="justify-self-end">
                      <feather-icon icon="XIcon" size="16" class="d-sm-block d-none text-danger cursor-pointer" @click="removeVisibleColumn(index)" />
                    </div>
                  </div>
                </div>
              </transition-group>
            </draggable>
            <div class="text-left mt-2">
              <b-row class="">
                <b-col md="9" class="align-items-end flex-column mt-auto">
                  <b-form-group label="Select a column and click add to show it on the table." label-for="assignee" class="mb-0">
                    <v-select id="columns" v-model="selectedColumn" :options="availableColumns" size="md" label="label" />
                  </b-form-group>
                </b-col>
                <b-col md="3" class="text-right align-items-end flex-column mt-auto">
                  <b-button variant="primary" @click="addVisibleColumn" :enabled="selectedColumn">
                    Add
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </b-card-body>
      </b-card>
    </b-sidebar>
  </div>
</template>
<script>
import columns from '../columns'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'

export default {
  name: 'ColumnSelector',
  components: { draggable, vSelect },
  props: ['model'],
  data() {
    return {
      visibleColumns: [],
      availableColumns: [],
      selectedColumn: null,
    }
  },
  watch: {
    model: {
      immediate: true,
      handler(val) {
        if (val) {
          this.prepareData()
        }
      },
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'columns',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  methods: {
    prepareData() {
      this.visibleColumns = columns.getVisibleColumns(this.model)
      this.availableColumns = columns.getAvailableColumns(this.model)
    },
    addVisibleColumn() {
      this.visibleColumns.push({ ...this.selectedColumn })

      this.selectedColumn = null

      columns.updateVisibleColumns({
        model: this.model.key,
        columns: this.visibleColumns,
      })

      this.availableColumns = columns.getAvailableColumns(this.model)
      this.$emit('update')
    },
    removeVisibleColumn(index) {
      this.visibleColumns.splice(index, 1)

      columns.updateVisibleColumns({
        model: this.model.key,
        columns: this.visibleColumns,
      })

      this.availableColumns = columns.getAvailableColumns(this.model)
      this.$emit('update')
    },
    onDragFieldEnd(e) {
      this.drag = false

      columns.updateVisibleColumns({
        model: this.model.key,
        columns: this.visibleColumns,
      })
      this.$emit('update')
    },
    onDragFieldStart(e) {
      this.drag = true
    },
  },
}
</script>
<style lang="scss">
#sidebar-right {
  min-width: 500px;
}
</style>
