import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import app from './app'
import appConfig from './app-config'
import auth from './auth'
import settings from './settings'
import env from './environment'
import plan from './plan'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    appConfig,
    auth,
    settings,
    env,
    plan,
  },
  strict: process.env.DEV,
  plugins: [createPersistedState({ key: 'atlas', paths: ['app', 'appConfig', 'auth.token', 'auth.me', 'settings', 'orgs', 'env'] })],
})

export default store
