import Vue from 'vue'

import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
Vue.component(FeatherIcon.name, FeatherIcon)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
Vue.component(VuePerfectScrollbar.name, VuePerfectScrollbar)

import { ValidationProvider, ValidationObserver } from 'vee-validate'
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  lazyComponent: true,
})

import resize from 'vue-element-resize-detector'
Vue.use(resize)

import VLazyImage from 'v-lazy-image/v2/v-lazy-image.es.js'
Vue.component('v-lazy-image', VLazyImage)

import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

import { VclFacebook, VclInstagram, VueContentLoading } from 'vue-content-loading'
Vue.component('vcl-facebook', VclFacebook)
Vue.component('vcl-instagram', VclInstagram)
Vue.component('vue-content-loading', VueContentLoading)

import VueDragscroll from 'vue-dragscroll'
Vue.use(VueDragscroll)

// Acl Helpers
import aclPlugin from './libs/acl'
Vue.use(aclPlugin)

// Content Types Helper
import contentTypesHelper from './libs/content-types'
Vue.use(contentTypesHelper)

// Event Bus
import EventBus from './event-bus'
Vue.prototype.$bus = EventBus

// Lodash
import _ from 'lodash'
Vue.prototype.$_ = _

// Lodash
import moment from 'moment'
Vue.prototype.$moment = moment

import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

if (process.env.NODE_ENV == 'development') {
  Vue.component('vue-json-pretty', VueJsonPretty)
}
