import { helpers as manifestHelpers } from '@/components/Fields/manifest'
import store from '@/store'
import _ from 'lodash'
import moment from 'moment'

const systemColumns = [
  {
    key: 'id',
    label: 'Id',
    value: 'id',
    type: 'system',
    sortable: true,
  },
  {
    key: 'createdAt',
    label: 'Created At',
    value: 'createAt',
    type: 'system',
    sortable: true,
    formatter: (value, key, item) => {
      if (value) {
        return moment(value).format('YYYY-MM-DD HH:mm')
      }
      return value
    },
  },
  {
    key: 'modifiedAt',
    label: 'Modified At',
    value: 'modifiedAt',
    type: 'system',
    sortable: true,
    formatter: (value, key, item) => {
      if (value) {
        let dateString = moment(value).format('YYYY-MM-DD HH:mm')
        return `<small>${dateString}</small>`
      }
      return value
    },
  },
  {
    key: 'locales',
    label: 'Locales',
    value: 'locales',
    type: 'system',
    sortable: false,
  },
  {
    key: 'stage',
    label: 'Stage',
    value: 'stage',
    type: 'system',
    sortable: true,
  },
]

const columns = {
  getStoredColumns(model) {
    const projectId = store.getters.Project.id
    const modelKey = model.key
    const contentColumns = store.getters.ContentColumns
    let self = this
    let storeColumns = []
    if (contentColumns) {
      let index = -1
      for (let i = 0, len = contentColumns.length; i < len; i++) {
        const item = contentColumns[i]
        // console.log(item.data.model)
        // console.log(modelKey)
        // console.log(item.data.model == modelKey)
        if (item.projectId == projectId && item.data.model == modelKey) {
          index = i
          break
        }
      }

      //console.log(index)
      if (index >= 0) {
        storeColumns = store.getters.ContentColumns[index].data
      }
    }

    return storeColumns
  },
  getSystemColumn(key) {
    let index = _.findIndex(systemColumns, function(o) {
      return o.key == key
    })
    return systemColumns[index]
  },
  getVisibleColumns(model) {
    let self = this
    let storeColumnsByModel = self.getStoredColumns(model)
    let visibleColumns = []

    if (!storeColumnsByModel || !storeColumnsByModel.columns) {
      storeColumnsByModel = {
        model: model.key,
        columns: [...systemColumns],
      }
      this.updateVisibleColumns(storeColumnsByModel)
    }

    _.each(storeColumnsByModel.columns, function(o) {
      if (o.type == 'system') {
        if (o.key != 'stage' || (o.key == 'stage' && model.enableStageMode)) {
          let sysCol = self.getSystemColumn(o.key)

          if (sysCol) {
            o.formatter = sysCol.formatter
            visibleColumns.push(o)
          }
        }
      } else {
        let attributeIndex = _.findIndex(model.attributes, function(a) {
          return 'attributes.' + a.key == o.key
        })

        if (attributeIndex >= 0) {
          let manifest = manifestHelpers.getManifestByType(o.type)
          o.formatter = manifest.ui.tables.cellFormatter
          o.class = manifest.ui.tables.class
          visibleColumns.push(o)
        }
      }
    })

    return visibleColumns
  },
  getAvailableColumns(model) {
    let self = this
    let storeColumnsByModel = self.getStoredColumns(model)
    let availableColumns = []

    _.each(systemColumns, c => {
      if (
        _.findIndex(storeColumnsByModel.columns, function(o) {
          return o.key == c.key && o.type == 'system'
        }) < 0
      ) {
        availableColumns.push(c)
      }
    })

    _.each(model.attributes, a => {
      let storeColumnsByModelIndex = _.findIndex(storeColumnsByModel.columns, function(o) {
        return o.key == 'attributes.' + a.key
      })

      let manifest = manifestHelpers.getManifestByType(a.type)

      if (storeColumnsByModelIndex < 0 && manifest.ui.tables.showable) {
        availableColumns.push({
          key: 'attributes.' + a.key,
          label: a.label,
          value: a.key,
          type: a.type,
          sortable: manifest.ui.tables.sortable,
        })
      }
    })

    return availableColumns
  },
  updateVisibleColumns(data) {
    store.dispatch('SetContentColumns', { projectId: store.getters.Project.id, data: data })
  },
}

export default columns
