<template>
  <div class="d-sm-none d-lg-block info-block">
    <div class="m-0 d-flex align-items-center justify-content-start h5 text-primary font-weight-bold mb-1">
      <feather-icon icon="InfoIcon" size="16" class="mr-50" /> System
    </div>
    <table class="mt-2 mt-xl-1 font-small-3">
      <tr>
        <td class="pb-50 pr-1 text-left">
          <span class="font-weight-normal">Language:</span>
        </td>
        <td class="pb-50">
          <flag-icon :country-code="cultures.getCultureByName(content.locale).isoCountryCode2" />
          {{ cultures.getCultureByName(content.locale).displayName }}
        </td>
      </tr>
      <tr v-if="model.enableStageMode">
        <td class="pb-50 pr-1 text-left">
          <span class="font-weight-normal">Stage:</span>
        </td>
        <td class="pb-50">
          <b-badge pill v-if="content.stage == 'published'" variant="success">
            Published
          </b-badge>
          <b-badge pill v-if="content.stage == 'unpublished'" variant="warning">
            Unpublished
          </b-badge>
        </td>
      </tr>
      <tr>
        <td class="pb-50 pr-1 text-left">
          <span class="font-weight-normal">ID:</span>
        </td>
        <td class="pb-50">
          {{ content.id }}
        </td>
      </tr>
      <tr>
        <td class="pb-50 pr-1 text-left">
          <span class="font-weight-normal">Created at:</span>
        </td>
        <td class="pb-50">
          {{ content.createdAt }}
        </td>
      </tr>
      <tr>
        <td class="pb-50 pr-1 text-left">
          <span class="font-weight-normal">Modified at:</span>
        </td>
        <td class="pb-50">
          {{ content.modifiedAt }}
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import FlagIcon from '@/components/FlagIcon/Index.vue'

export default {
  name: 'InfoBlock',
  props: ['model', 'content', 'contents', 'cultures', 'expanded'],
  components: { FlagIcon },
  data() {
    return {}
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters(['Project', 'Settings', 'IsContentEditSidebarCollapsed']),
    settingsLocales() {
      let orderedLocales = this.$_.orderBy(this.Settings.locales, ['isDefault', 'locale'], ['desc', 'asc'])

      let locales = []

      this.$_.each(orderedLocales, l => {
        locales.push(this.cultures.getCultureByName(l.locale))
      })

      return locales
    },
    availableLocales() {
      let locales = []

      this.$_.each(this.settingsLocales, l => {
        let index = this.$_.findIndex(this.contents, c => c.locale == l.culture)

        if (index < 0) {
          locales.push(l)
        }
      })

      return locales
    },
  },
  created() {},
  methods: {},
}
</script>
