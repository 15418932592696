<template>
  <div>
    <div>
      <b-table-simple striped responsive class="position-relative">
        <b-thead>
          <b-th v-for="col in columns" :key="field.key + '_' + col.key">
            {{ col.label }}
          </b-th>
        </b-thead>

        <draggable
          v-bind="dragOptions"
          v-model="items"
          tag="tbody"
          handle=".drag-handle"
          @start="onDragFieldStart"
          @end="onDragFieldEnd"
          @change="onDragChanged"
        >
          <b-tr v-for="item in items" :key="field.key + '_row_' + item.id">
            <td v-for="col in columns" :key="field.key + '_row_' + item.id + '_col_' + col.key" :class="getCellClass(col)">
              <span v-if="col.key == '$drag'">
                <feather-icon icon="AlignJustifyIcon" size="17" class="cursor-move d-sm-block d-none m-0 p-0 drag-handle" />
              </span>
              <span v-if="col.key == '$action'">
                <div class="text-right">
                  <b-link variant="link" class="text-secondary mr-1" style="line-height:15px" size="md" @click="onViewRelation($event, item.id)"
                    ><feather-icon icon="EyeIcon" size="18"
                  /></b-link>
                  <b-link variant="link" class="text-danger" style="line-height:15px" size="md" @click="removeRelation($event, item.id)"
                    ><feather-icon icon="XIcon" size="18"
                  /></b-link>
                </div>
              </span>
              <span v-if="col.key == 'id'">
                {{ item.id }}
              </span>
              <span v-else>
                <span v-b-tooltip.hover :title="item.id">{{ getPropByString(item, col.key) }}</span>
              </span>
            </td>
          </b-tr>
        </draggable>
      </b-table-simple>

      <content-selector :model="model" :selected="items" v-if="model" @selection-done="onSelectionDone" :locale="locale" />
    </div>
  </div>
</template>
<script>
import { contentTypes, contents as contentsRepository } from '@/api/index'
import ContentSelector from './ContentSelector.vue'
import draggable from 'vuedraggable'

export default {
  name: 'RelationField',
  props: ['field', 'value', 'contents', 'parentKey', 'locale', 'errors', 'index'],
  components: {
    ContentSelector,
    draggable,
  },
  data() {
    return {
      internal: [],
      model: {},
      items: [],
      drag: false,
    }
  },
  computed: {
    rules() {
      const ruleSet = []
      if (this.field.required) {
        //ruleSet.push('required')
      }

      return ruleSet.join('|')
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    selected() {
      return this.internal
    },
    columns() {
      const titleFields = this.$_.filter(this.model.attributes, function(a) {
        return a.isTitle
      })

      let cols = [
        {
          key: '$drag',
          label: '',
          value: '',
          type: 'system',
          sortable: false,
        },
        {
          key: 'id',
          label: 'ID',
          value: 'id',
          type: 'system',
          sortable: false,
        },
        {
          key: 'locale',
          label: 'Locale',
          value: 'locale',
          type: 'system',
          sortable: false,
        },
        { key: '$action', tdClass: 'action-content', label: '' },
      ]
      if (titleFields && titleFields.length > 0) {
        return [
          cols[0],
          ...titleFields.map(f => ({
            key: 'attributes.' + f.key,
            label: f.label,
            value: 'attributes.' + f.key,
            type: f.type,
            sortable: false,
          })),
          cols[2],
          cols[3],
        ]
      }
      return cols
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.internal = val
      },
    },
  },
  mounted() {
    this.model = this.$ContentTypes.findModel(this.field.allowedType)
    this.fetchContents()
  },
  methods: {
    getCellClass(col) {
      if (col.key == '$drag') return 'cell-drag'
    },
    getPropByString(obj, propString) {
      if (!propString) return obj

      var prop,
        props = propString.split('.')

      for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
        prop = props[i]

        var candidate = obj[prop]
        if (candidate !== undefined) {
          obj = candidate
        } else {
          break
        }
      }
      return obj[props[i]]
    },
    fetchContents() {
      if (this.internal && this.internal.length > 0 && this.model) {
        const self = this
        const query = {}
        query[contentsRepository.createFilter('_id', 'any')] = this.internal.join(',')

        if (self.model.enableStageMode) {
          query['stage'] = 'all'
        }

        contentsRepository.getContents(self.model.key, query).then(res => {
          self.items = res.data.data
          // self.totalRows = res.data.metadata.count
          // self.totalPages = res.data.metadata.totalPages
          // self.currentPage = res.data.metadata.currentPage == 0 ? 1 : res.data.metadata.currentPage
        })
      }
    },
    onDragChanged(e) {
      const ids = this.items.map(c => c.id)
      this.$emit('input', ids)
    },
    onDragFieldEnd(e) {
      this.drag = false
    },
    onDragFieldStart(e) {
      this.drag = true
    },
    onViewRelation(event, id) {
      this.$router.push({ name: 'atlas_edit_' + this.model.key, params: { id: id } })
    },
    removeRelation(event, id) {
      const internalIndex = this.internal.findIndex(p => p === id)
      this.internal.splice(internalIndex, 1)

      const itemIndex = this.items.findIndex(p => p.id === id)
      this.items.splice(itemIndex, 1)
    },
    onSelectionDone(contents) {
      const ids = contents.map(c => c.id)
      this.items = contents
      this.$emit('input', ids)
    },
  },
}
</script>
<style lang="scss">
.cell-drag {
  width: 30px;
  padding: 0.72rem 1rem !important;
}
</style>
