<template>
  <div class="mb-1">
    <b-nav>
      <b-nav-item :active="isActive('SettingsGeneral')">
        <h6><router-link :to="{ name: 'SettingsGeneral' }">General</router-link></h6>
      </b-nav-item>
      <b-nav-item :active="isActive('SettingsMemberships')" v-if="$acl.can('membership.accounts.read')">
        <h6><router-link :to="{ name: 'SettingsMemberships' }">Members</router-link></h6>
      </b-nav-item>
      <b-nav-item :active="isActive('SettingsRoles')" v-if="$acl.can('membership.roles.read')">
        <h6><router-link :to="{ name: 'SettingsRoles' }">Roles</router-link></h6>
      </b-nav-item>
      <b-nav-item :active="isActive('SettingsApiTokens')" v-if="$acl.can('admin.tokens.manage')">
        <h6><router-link :to="{ name: 'SettingsApiTokens' }">API Tokens</router-link></h6>
      </b-nav-item>
      <b-nav-item :active="isActive('SettingsWebhooks')" v-if="$acl.can('admin.webhooks.read')">
        <h6><router-link :to="{ name: 'SettingsWebhooks' }">Webhooks</router-link></h6>
      </b-nav-item>
      <b-nav-item :active="isActive('SettingsBilling')" v-if="1 == 1">
        <!-- $acl.can('admin.webhooks.billing') -->
        <h6><router-link :to="{ name: 'SettingsBilling' }">Billing</router-link></h6>
      </b-nav-item>
    </b-nav>
  </div>
</template>
<script>
export default {
  props: [],
  components: {},
  data() {
    return {}
  },
  created() {},
  methods: {
    isActive(section) {
      return this.$route.name == section
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/variables/_variables.scss';

h6 {
  a {
    color: $secondary;
  }
}

.active h6 {
  a {
    padding-bottom: 0.2rem;
    border-bottom: 2px solid;
    color: $primary !important;
  }
}
</style>
