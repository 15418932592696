<template>
  <div>
    <page-loader v-if="loading" type="list" :fluid="true" />
    <b-card no-body :class="{ 'd-none': loading }">
      <b-card-body>
        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :current-page="currentPage"
          :items="items"
          :fields="columns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sticky-header="false"
          @sort-changed="onSortChanged"
          no-local-sorting
        >
          <template #cell($type)="data">
            <feather-icon icon="InfoIcon" size="16" class="text-primary" v-if="data.item.type == 'information'" />
            <feather-icon icon="AlertTriangleIcon" size="18" class="text-warning" v-if="data.item.type == 'warning'" />
          </template>
          <template #cell()="data">
            <span v-html="data.value"></span>
          </template>
          <template #cell($actions)="data">
            <feather-icon icon="EyeIcon" size="16" class="text-secondary" v-if="1 == 0" />
          </template>
        </b-table>
      </b-card-body>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0" v-if="!loading">
        <!-- page length -->
        <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect" class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" @change="onRowsPerPageChanged" />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-if="totalPages > 1"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import { audits } from '@/api/index'

export default {
  name: 'Audit',
  props: ['entityId', 'entityType'],
  data() {
    return {
      items: [],
      sortBy: 'timestamp',
      sortDesc: false,
      sortDirection: 'desc',
      searchText: '',
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      totalPages: 1,
      loading: false,
      columns: [
        {
          key: '$type',
          label: '',
          sortable: false,
          tdClass: 'icon-cell',
        },
        {
          key: 'timestamp',
          label: 'Date',
          value: 'timestamp',
          sortKey: 'timestamp',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              let dateString = this.$moment(value).format('YYYY-MM-DD HH:mm')
              return `<small>${dateString}</small>`
            }
            return value
          },
          tdClass: 'date-cell',
        },
        {
          key: 'event',
          label: 'Event',
          sortable: true,
          tdClass: 'event-cell',
          formatter: (value, key, item) => {
            return `<small>${value}</small>`
          },
        },
        {
          key: 'message',
          label: 'Message',
          sortable: false,
          formatter: (value, key, item) => {
            return `<small>${value}</small>`
          },
        },
        {
          key: '$actions',
          label: '',
          tdClass: 'action-cell',
        },
      ],
    }
  },
  computed: {},
  created() {
    let self = this
    self.fetchData(self.$route.query)
  },
  methods: {
    async fetchData() {
      const self = this

      const query = self.getCurrentFilters()

      self.loading = true
      await audits.getAudits(query).then(res => {
        self.items = res.data.data
        self.totalRows = res.data.metadata.count
        self.totalPages = res.data.metadata.totalPages
        self.currentPage = res.data.metadata.currentPage == 0 ? 1 : res.data.metadata.currentPage
        self.loading = false
      })
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy
      this.sortDirection = e.sortDesc ? 'desc' : 'asc'
      this.fetchData()
    },
    onPageChanged(e) {
      this.currentPage = e
      this.fetchData()
    },
    onRowsPerPageChanged(e) {
      this.perPage = e
      this.fetchData()
    },
    getCurrentFilters() {
      return {
        entityId: this.entityId,
        entityType: this.entityType,
        page: this.currentPage,
        size: this.perPage,
        search: this.searchText,
        sort: `${this.sortBy}|${this.sortDirection}`,
      }
    },
  },
}
</script>

