export default [
  {
    header: 'Media',
    permissions: ['medialibrary.*'],
  },
  {
    title: 'Media Library',
    route: 'MediaLibrary',
    icon: 'ImageIcon',
    params: { project: '' },
    permissions: ['medialibrary.*'],
  },
  {
    header: 'Users & Roles',
    permissions: ['usersroles.users.read', 'usersroles.roles.read'],
  },
  {
    title: 'Users',
    route: 'Users',
    icon: 'UsersIcon',
    params: { project: '' },
    permissions: ['usersroles.users.read'],
  },
  {
    title: 'Roles',
    route: 'Roles',
    icon: 'KeyIcon',
    params: { project: '' },
    permissions: ['usersroles.roles.read'],
  },
  {
    header: 'Administration',
    permissions: [],
  },
  {
    title: 'Model Builder',
    route: 'ModelBuilder',
    icon: 'LayersIcon',
    params: { project: '' },
    permissions: ['modelbuilder.models.manage', 'modelbuilder.components.manage'],
  },
  {
    title: 'Settings',
    route: 'Settings',
    icon: 'SlidersIcon',
    params: { project: '' },
    permissions: [],
  },
  {
    header: 'Playground',
    permissions: [],
  },
  {
    title: 'GraphQL',
    route: 'GraphQlPlayground',
    params: { project: '' },
    iconimage: require('../assets/images/icons/graphql.png'),
    permissions: [],
  },
]
