<template>
  <validation-provider :rules="validationRules" :name="label" v-slot="{ errors }" :vid="vid">
    <b-form-group :label="label" :label-for="id" :class="groupCss" :description="hint">
      <b-input-group class="input-group-merge" :class="{ 'is-invalid': errors.length > 0, css }">
        <b-form-textarea
          :id="id"
          :placeholder="placeholder"
          :type="localType"
          :value="value"
          :state="errors.length > 0 ? false : null"
          :readonly="readonly"
          :size="size"
          rows="3"
          max-rows="6"
          @input="$emit('input', $event)"
        />
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  name: 'TextArea',
  props: {
    value: {
      type: String,
    },
    vid: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    validationRules: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    hint: {
      type: String,
      default: '',
    },
    css: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      instance: '',
      localType: this.type,
    }
  },
  computed: {
    id() {
      return 'text_input_' + this.instance
    },
    passwordToggleIcon() {
      return this.localType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    groupCss() {
      let result = []

      if (this.required) result.push('required-input')

      result.push(this.css)

      return result.join(' ')
    },
  },
  created() {
    this.instance = Math.random()
  },
  methods: {
    togglePasswordVisibility() {
      this.localType = this.localType === 'password' ? 'text' : 'password'
    },
  },
}
</script>
<style scoped lang="scss">
.required-input {
  font-weight: 600 !important;
}
</style>
