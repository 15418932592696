import http from '@/http/index'

const contents = {
  async getContents(project, type, query) {
    return await http.get(`${project}/contents/${type}`, { params: query })
  },
  async getContent(project, type, id, query) {
    return await http.get(`${project}/contents/${type}/${id}`, { params: query })
  },
  async createContent(project, type, data) {
    return await http.post(`${project}/contents/${type}`, data)
  },
  async publishContent(project, type, id) {
    return await http.post(`${project}/contents/${type}/${id}/publish`)
  },
  async unpublishContent(project, type, id) {
    return await http.post(`${project}/contents/${type}/${id}/unpublish`)
  },
  async createContentTranslation(project, type, id, locale) {
    return await http.post(`${project}/contents/${type}/${id}/create-translation`, { id: id, locale: locale })
  },
  async updateContent(project, type, id, data) {
    return await http.put(`${project}/contents/${type}/${id}`, data)
  },
  async duplicateContentAll(project, type, id) {
    return await http.post(`${project}/contents/${type}/${id}/duplicate`, { locales: true })
  },
  async updateLocaleSet(project, type, data) {
    return await http.put(`${project}/contents/${type}/locale-set`, data)
  },
  async deleteContent(project, type, id, allLocales) {
    if (allLocales) {
      return await http.delete(`${project}/contents/${type}/${id}`, { params: { locales: allLocales } })
    } else {
      return await http.delete(`${project}/contents/${type}/${id}`)
    }
  },
  async deleteAllContents(project, type) {
    return await http.delete(`${project}/contents/${type}/clear`)
  },
  createFilter(name, operator) {
    return `filter[${name}][${operator}]`
  },
}

export default contents
