import _ from 'lodash'

const db = [
  {
    "culture": "af-ZA",
    "displayName": "Afrikaans (South Africa)",
    "isoCode2": "af",
    "isoCode3": "afr",
    "isoCountryCode2": "ZA",
    "isoCountryCode3": "ZA",
    "countryName": "South Africa",
    "currency": "ZAR",
    "rtl": false
  },
  {
    "culture": "am-ET",
    "displayName": "Amharic (Ethiopia)",
    "isoCode2": "am",
    "isoCode3": "amh",
    "isoCountryCode2": "ET",
    "isoCountryCode3": "ET",
    "countryName": "Ethiopia",
    "currency": "ETB",
    "rtl": false
  },
  {
    "culture": "ar-AE",
    "displayName": "Arabic (United Arab Emirates)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "AE",
    "isoCountryCode3": "AE",
    "countryName": "United Arab Emirates",
    "currency": "AED",
    "rtl": true
  },
  {
    "culture": "ar-BH",
    "displayName": "Arabic (Bahrain)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "BH",
    "isoCountryCode3": "BH",
    "countryName": "Bahrain",
    "currency": "BHD",
    "rtl": true
  },
  {
    "culture": "ar-DZ",
    "displayName": "Arabic (Algeria)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "DZ",
    "isoCountryCode3": "DZ",
    "countryName": "Algeria",
    "currency": "DZD",
    "rtl": true
  },
  {
    "culture": "ar-EG",
    "displayName": "Arabic (Egypt)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "EG",
    "isoCountryCode3": "EG",
    "countryName": "Egypt",
    "currency": "EGP",
    "rtl": true
  },
  {
    "culture": "ar-IQ",
    "displayName": "Arabic (Iraq)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "IQ",
    "isoCountryCode3": "IQ",
    "countryName": "Iraq",
    "currency": "IQD",
    "rtl": true
  },
  {
    "culture": "ar-JO",
    "displayName": "Arabic (Jordan)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "JO",
    "isoCountryCode3": "JO",
    "countryName": "Jordan",
    "currency": "JOD",
    "rtl": true
  },
  {
    "culture": "ar-KW",
    "displayName": "Arabic (Kuwait)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "KW",
    "isoCountryCode3": "KW",
    "countryName": "Kuwait",
    "currency": "KWD",
    "rtl": true
  },
  {
    "culture": "ar-LB",
    "displayName": "Arabic (Lebanon)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "LB",
    "isoCountryCode3": "LB",
    "countryName": "Lebanon",
    "currency": "LBP",
    "rtl": true
  },
  {
    "culture": "ar-LY",
    "displayName": "Arabic (Libya)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "LY",
    "isoCountryCode3": "LY",
    "countryName": "Libya",
    "currency": "LYD",
    "rtl": true
  },
  {
    "culture": "ar-MA",
    "displayName": "Arabic (Morocco)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "MA",
    "isoCountryCode3": "MA",
    "countryName": "Morocco",
    "currency": "MAD",
    "rtl": true
  },
  {
    "culture": "ar-OM",
    "displayName": "Arabic (Oman)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "OM",
    "isoCountryCode3": "OM",
    "countryName": "Oman",
    "currency": "OMR",
    "rtl": true
  },
  {
    "culture": "ar-QA",
    "displayName": "Arabic (Qatar)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "QA",
    "isoCountryCode3": "QA",
    "countryName": "Qatar",
    "currency": "QAR",
    "rtl": true
  },
  {
    "culture": "ar-SA",
    "displayName": "Arabic (Saudi Arabia)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "SA",
    "isoCountryCode3": "SA",
    "countryName": "Saudi Arabia",
    "currency": "SAR",
    "rtl": true
  },
  {
    "culture": "ar-SY",
    "displayName": "Arabic (Syria)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "SY",
    "isoCountryCode3": "SY",
    "countryName": "Syria",
    "currency": "SYP",
    "rtl": true
  },
  {
    "culture": "ar-TN",
    "displayName": "Arabic (Tunisia)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "TN",
    "isoCountryCode3": "TN",
    "countryName": "Tunisia",
    "currency": "TND",
    "rtl": true
  },
  {
    "culture": "ar-YE",
    "displayName": "Arabic (Yemen)",
    "isoCode2": "ar",
    "isoCode3": "ara",
    "isoCountryCode2": "YE",
    "isoCountryCode3": "YE",
    "countryName": "Yemen",
    "currency": "YER",
    "rtl": true
  },
  {
    "culture": "arn-CL",
    "displayName": "Mapuche (Chile)",
    "isoCode2": "arn",
    "isoCode3": "arn",
    "isoCountryCode2": "CL",
    "isoCountryCode3": "CL",
    "countryName": "Chile",
    "currency": "CLP",
    "rtl": false
  },
  {
    "culture": "as-IN",
    "displayName": "Assamese (India)",
    "isoCode2": "as",
    "isoCode3": "asm",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": false
  },
  {
    "culture": "az-Cyrl-AZ",
    "displayName": "Azerbaijani (Cyrillic, Azerbaijan)",
    "isoCode2": "az",
    "isoCode3": "aze",
    "isoCountryCode2": "AZ",
    "isoCountryCode3": "AZ",
    "countryName": "Azerbaijan",
    "currency": "AZN",
    "rtl": false
  },
  {
    "culture": "az-Latn-AZ",
    "displayName": "Azerbaijani (Latin, Azerbaijan)",
    "isoCode2": "az",
    "isoCode3": "aze",
    "isoCountryCode2": "AZ",
    "isoCountryCode3": "AZ",
    "countryName": "Azerbaijan",
    "currency": "AZN",
    "rtl": false
  },
  {
    "culture": "ba-RU",
    "displayName": "Bashkir (Russia)",
    "isoCode2": "ba",
    "isoCode3": "bak",
    "isoCountryCode2": "RU",
    "isoCountryCode3": "RU",
    "countryName": "Russia",
    "currency": "RUB",
    "rtl": false
  },
  {
    "culture": "be-BY",
    "displayName": "Belarusian (Belarus)",
    "isoCode2": "be",
    "isoCode3": "bel",
    "isoCountryCode2": "BY",
    "isoCountryCode3": "BY",
    "countryName": "Belarus",
    "currency": "BYN",
    "rtl": false
  },
  {
    "culture": "bg-BG",
    "displayName": "Bulgarian (Bulgaria)",
    "isoCode2": "bg",
    "isoCode3": "bul",
    "isoCountryCode2": "BG",
    "isoCountryCode3": "BG",
    "countryName": "Bulgaria",
    "currency": "BGN",
    "rtl": false
  },
  {
    "culture": "bn-BD",
    "displayName": "Bangla (Bangladesh)",
    "isoCode2": "bn",
    "isoCode3": "ben",
    "isoCountryCode2": "BD",
    "isoCountryCode3": "BD",
    "countryName": "Bangladesh",
    "currency": "BDT",
    "rtl": false
  },
  {
    "culture": "bn-IN",
    "displayName": "Bangla (India)",
    "isoCode2": "bn",
    "isoCode3": "ben",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": false
  },
  {
    "culture": "bo-CN",
    "displayName": "Tibetan (China)",
    "isoCode2": "bo",
    "isoCode3": "bod",
    "isoCountryCode2": "CN",
    "isoCountryCode3": "CN",
    "countryName": "China",
    "currency": "CNY",
    "rtl": false
  },
  {
    "culture": "br-FR",
    "displayName": "Breton (France)",
    "isoCode2": "br",
    "isoCode3": "bre",
    "isoCountryCode2": "FR",
    "isoCountryCode3": "FR",
    "countryName": "France",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "bs-Cyrl-BA",
    "displayName": "Bosnian (Cyrillic, Bosnia & Herzegovina)",
    "isoCode2": "bs",
    "isoCode3": "bos",
    "isoCountryCode2": "BA",
    "isoCountryCode3": "BA",
    "countryName": "Bosnia & Herzegovina",
    "currency": "BAM",
    "rtl": false
  },
  {
    "culture": "bs-Latn-BA",
    "displayName": "Bosnian (Latin, Bosnia & Herzegovina)",
    "isoCode2": "bs",
    "isoCode3": "bos",
    "isoCountryCode2": "BA",
    "isoCountryCode3": "BA",
    "countryName": "Bosnia & Herzegovina",
    "currency": "BAM",
    "rtl": false
  },
  {
    "culture": "ca-ES",
    "displayName": "Catalan (Spain)",
    "isoCode2": "ca",
    "isoCode3": "cat",
    "isoCountryCode2": "ES",
    "isoCountryCode3": "ES",
    "countryName": "Spain",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "ca-ES-valencia",
    "displayName": "Catalan (Spain, Valencian)",
    "isoCode2": "ca",
    "isoCode3": "cat",
    "isoCountryCode2": "ES",
    "isoCountryCode3": "ES",
    "countryName": "Spain",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "chr-Cher-US",
    "displayName": "Cherokee (Cherokee, United States)",
    "isoCode2": "chr",
    "isoCode3": "chr",
    "isoCountryCode2": "US",
    "isoCountryCode3": "US",
    "countryName": "United States",
    "currency": "USD",
    "rtl": false
  },
  {
    "culture": "ku-Arab-IQ",
    "displayName": "Kurdish (Arabic, Iraq)",
    "isoCode2": "ku",
    "isoCode3": "kur",
    "isoCountryCode2": "IQ",
    "isoCountryCode3": "IQ",
    "countryName": "Iraq",
    "currency": "IQD",
    "rtl": true
  },
  {
    "culture": "co-FR",
    "displayName": "Corsican (France)",
    "isoCode2": "co",
    "isoCode3": "cos",
    "isoCountryCode2": "FR",
    "isoCountryCode3": "FR",
    "countryName": "France",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "cs-CZ",
    "displayName": "Czech (Czechia)",
    "isoCode2": "cs",
    "isoCode3": "ces",
    "isoCountryCode2": "CZ",
    "isoCountryCode3": "CZ",
    "countryName": "Czechia",
    "currency": "CZK",
    "rtl": false
  },
  {
    "culture": "cy-GB",
    "displayName": "Welsh (United Kingdom)",
    "isoCode2": "cy",
    "isoCode3": "cym",
    "isoCountryCode2": "GB",
    "isoCountryCode3": "GB",
    "countryName": "United Kingdom",
    "currency": "GBP",
    "rtl": false
  },
  {
    "culture": "da-DK",
    "displayName": "Danish (Denmark)",
    "isoCode2": "da",
    "isoCode3": "dan",
    "isoCountryCode2": "DK",
    "isoCountryCode3": "DK",
    "countryName": "Denmark",
    "currency": "DKK",
    "rtl": false
  },
  {
    "culture": "de-AT",
    "displayName": "German (Austria)",
    "isoCode2": "de",
    "isoCode3": "deu",
    "isoCountryCode2": "AT",
    "isoCountryCode3": "AT",
    "countryName": "Austria",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "de-CH",
    "displayName": "German (Switzerland)",
    "isoCode2": "de",
    "isoCode3": "deu",
    "isoCountryCode2": "CH",
    "isoCountryCode3": "CH",
    "countryName": "Switzerland",
    "currency": "CHF",
    "rtl": false
  },
  {
    "culture": "de-DE",
    "displayName": "German (Germany)",
    "isoCode2": "de",
    "isoCode3": "deu",
    "isoCountryCode2": "DE",
    "isoCountryCode3": "DE",
    "countryName": "Germany",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "de-LI",
    "displayName": "German (Liechtenstein)",
    "isoCode2": "de",
    "isoCode3": "deu",
    "isoCountryCode2": "LI",
    "isoCountryCode3": "LI",
    "countryName": "Liechtenstein",
    "currency": "CHF",
    "rtl": false
  },
  {
    "culture": "de-LU",
    "displayName": "German (Luxembourg)",
    "isoCode2": "de",
    "isoCode3": "deu",
    "isoCountryCode2": "LU",
    "isoCountryCode3": "LU",
    "countryName": "Luxembourg",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "dsb-DE",
    "displayName": "Lower Sorbian (Germany)",
    "isoCode2": "dsb",
    "isoCode3": "dsb",
    "isoCountryCode2": "DE",
    "isoCountryCode3": "DE",
    "countryName": "Germany",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "dv-MV",
    "displayName": "Divehi (Maldives)",
    "isoCode2": "dv",
    "isoCode3": "div",
    "isoCountryCode2": "MV",
    "isoCountryCode3": "MV",
    "countryName": "Maldives",
    "currency": "MVR",
    "rtl": true
  },
  {
    "culture": "dz-BT",
    "displayName": "Dzongkha (Bhutan)",
    "isoCode2": "dz",
    "isoCode3": "dzo",
    "isoCountryCode2": "BT",
    "isoCountryCode3": "BT",
    "countryName": "Bhutan",
    "currency": "BTN",
    "rtl": false
  },
  {
    "culture": "el-GR",
    "displayName": "Greek (Greece)",
    "isoCode2": "el",
    "isoCode3": "ell",
    "isoCountryCode2": "GR",
    "isoCountryCode3": "GR",
    "countryName": "Greece",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "en-AE",
    "displayName": "English (United Arab Emirates)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "AE",
    "isoCountryCode3": "AE",
    "countryName": "United Arab Emirates",
    "currency": "AED",
    "rtl": false
  },
  {
    "culture": "en-AU",
    "displayName": "English (Australia)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "AU",
    "isoCountryCode3": "AU",
    "countryName": "Australia",
    "currency": "AUD",
    "rtl": false
  },
  {
    "culture": "en-BZ",
    "displayName": "English (Belize)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "BZ",
    "isoCountryCode3": "BZ",
    "countryName": "Belize",
    "currency": "BZD",
    "rtl": false
  },
  {
    "culture": "en-CA",
    "displayName": "English (Canada)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "CA",
    "isoCountryCode3": "CA",
    "countryName": "Canada",
    "currency": "CAD",
    "rtl": false
  },
  {
    "culture": "en-GB",
    "displayName": "English (United Kingdom)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "GB",
    "isoCountryCode3": "GB",
    "countryName": "United Kingdom",
    "currency": "GBP",
    "rtl": false
  },
  {
    "culture": "en-HK",
    "displayName": "English (Hong Kong SAR)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "HK",
    "isoCountryCode3": "HK",
    "countryName": "Hong Kong SAR",
    "currency": "HKD",
    "rtl": false
  },
  {
    "culture": "en-IE",
    "displayName": "English (Ireland)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "IE",
    "isoCountryCode3": "IE",
    "countryName": "Ireland",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "en-IN",
    "displayName": "English (India)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": false
  },
  {
    "culture": "en-JM",
    "displayName": "English (Jamaica)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "JM",
    "isoCountryCode3": "JM",
    "countryName": "Jamaica",
    "currency": "JMD",
    "rtl": false
  },
  {
    "culture": "en-MY",
    "displayName": "English (Malaysia)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "MY",
    "isoCountryCode3": "MY",
    "countryName": "Malaysia",
    "currency": "MYR",
    "rtl": false
  },
  {
    "culture": "en-NZ",
    "displayName": "English (New Zealand)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "NZ",
    "isoCountryCode3": "NZ",
    "countryName": "New Zealand",
    "currency": "NZD",
    "rtl": false
  },
  {
    "culture": "en-PH",
    "displayName": "English (Philippines)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "PH",
    "isoCountryCode3": "PH",
    "countryName": "Philippines",
    "currency": "PHP",
    "rtl": false
  },
  {
    "culture": "en-SG",
    "displayName": "English (Singapore)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "SG",
    "isoCountryCode3": "SG",
    "countryName": "Singapore",
    "currency": "SGD",
    "rtl": false
  },
  {
    "culture": "en-TT",
    "displayName": "English (Trinidad & Tobago)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "TT",
    "isoCountryCode3": "TT",
    "countryName": "Trinidad & Tobago",
    "currency": "TTD",
    "rtl": false
  },
  {
    "culture": "en-US",
    "displayName": "English (United States)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "US",
    "isoCountryCode3": "US",
    "countryName": "United States",
    "currency": "USD",
    "rtl": false
  },
  {
    "culture": "en-ZA",
    "displayName": "English (South Africa)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "ZA",
    "isoCountryCode3": "ZA",
    "countryName": "South Africa",
    "currency": "ZAR",
    "rtl": false
  },
  {
    "culture": "en-ZW",
    "displayName": "English (Zimbabwe)",
    "isoCode2": "en",
    "isoCode3": "eng",
    "isoCountryCode2": "ZW",
    "isoCountryCode3": "ZW",
    "countryName": "Zimbabwe",
    "currency": "USD",
    "rtl": false
  },
  {
    "culture": "es-419",
    "displayName": "Spanish (Latin America)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "419",
    "isoCountryCode3": "419",
    "countryName": "Latin America",
    "currency": "¤¤",
    "rtl": false
  },
  {
    "culture": "es-AR",
    "displayName": "Spanish (Argentina)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "AR",
    "isoCountryCode3": "AR",
    "countryName": "Argentina",
    "currency": "ARS",
    "rtl": false
  },
  {
    "culture": "es-BO",
    "displayName": "Spanish (Bolivia)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "BO",
    "isoCountryCode3": "BO",
    "countryName": "Bolivia",
    "currency": "BOB",
    "rtl": false
  },
  {
    "culture": "es-CL",
    "displayName": "Spanish (Chile)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "CL",
    "isoCountryCode3": "CL",
    "countryName": "Chile",
    "currency": "CLP",
    "rtl": false
  },
  {
    "culture": "es-CO",
    "displayName": "Spanish (Colombia)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "CO",
    "isoCountryCode3": "CO",
    "countryName": "Colombia",
    "currency": "COP",
    "rtl": false
  },
  {
    "culture": "es-CR",
    "displayName": "Spanish (Costa Rica)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "CR",
    "isoCountryCode3": "CR",
    "countryName": "Costa Rica",
    "currency": "CRC",
    "rtl": false
  },
  {
    "culture": "es-CU",
    "displayName": "Spanish (Cuba)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "CU",
    "isoCountryCode3": "CU",
    "countryName": "Cuba",
    "currency": "CUP",
    "rtl": false
  },
  {
    "culture": "es-DO",
    "displayName": "Spanish (Dominican Republic)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "DO",
    "isoCountryCode3": "DO",
    "countryName": "Dominican Republic",
    "currency": "DOP",
    "rtl": false
  },
  {
    "culture": "es-EC",
    "displayName": "Spanish (Ecuador)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "EC",
    "isoCountryCode3": "EC",
    "countryName": "Ecuador",
    "currency": "USD",
    "rtl": false
  },
  {
    "culture": "es-ES",
    "displayName": "Spanish (Spain)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "ES",
    "isoCountryCode3": "ES",
    "countryName": "Spain",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "es-GT",
    "displayName": "Spanish (Guatemala)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "GT",
    "isoCountryCode3": "GT",
    "countryName": "Guatemala",
    "currency": "GTQ",
    "rtl": false
  },
  {
    "culture": "es-HN",
    "displayName": "Spanish (Honduras)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "HN",
    "isoCountryCode3": "HN",
    "countryName": "Honduras",
    "currency": "HNL",
    "rtl": false
  },
  {
    "culture": "es-MX",
    "displayName": "Spanish (Mexico)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "MX",
    "isoCountryCode3": "MX",
    "countryName": "Mexico",
    "currency": "MXN",
    "rtl": false
  },
  {
    "culture": "es-NI",
    "displayName": "Spanish (Nicaragua)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "NI",
    "isoCountryCode3": "NI",
    "countryName": "Nicaragua",
    "currency": "NIO",
    "rtl": false
  },
  {
    "culture": "es-PA",
    "displayName": "Spanish (Panama)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "PA",
    "isoCountryCode3": "PA",
    "countryName": "Panama",
    "currency": "PAB",
    "rtl": false
  },
  {
    "culture": "es-PE",
    "displayName": "Spanish (Peru)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "PE",
    "isoCountryCode3": "PE",
    "countryName": "Peru",
    "currency": "PEN",
    "rtl": false
  },
  {
    "culture": "es-PR",
    "displayName": "Spanish (Puerto Rico)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "PR",
    "isoCountryCode3": "PR",
    "countryName": "Puerto Rico",
    "currency": "USD",
    "rtl": false
  },
  {
    "culture": "es-PY",
    "displayName": "Spanish (Paraguay)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "PY",
    "isoCountryCode3": "PY",
    "countryName": "Paraguay",
    "currency": "PYG",
    "rtl": false
  },
  {
    "culture": "es-SV",
    "displayName": "Spanish (El Salvador)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "SV",
    "isoCountryCode3": "SV",
    "countryName": "El Salvador",
    "currency": "USD",
    "rtl": false
  },
  {
    "culture": "es-US",
    "displayName": "Spanish (United States)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "US",
    "isoCountryCode3": "US",
    "countryName": "United States",
    "currency": "USD",
    "rtl": false
  },
  {
    "culture": "es-UY",
    "displayName": "Spanish (Uruguay)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "UY",
    "isoCountryCode3": "UY",
    "countryName": "Uruguay",
    "currency": "UYU",
    "rtl": false
  },
  {
    "culture": "es-VE",
    "displayName": "Spanish (Venezuela)",
    "isoCode2": "es",
    "isoCode3": "spa",
    "isoCountryCode2": "VE",
    "isoCountryCode3": "VE",
    "countryName": "Venezuela",
    "currency": "VES",
    "rtl": false
  },
  {
    "culture": "et-EE",
    "displayName": "Estonian (Estonia)",
    "isoCode2": "et",
    "isoCode3": "est",
    "isoCountryCode2": "EE",
    "isoCountryCode3": "EE",
    "countryName": "Estonia",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "eu-ES",
    "displayName": "Basque (Spain)",
    "isoCode2": "eu",
    "isoCode3": "eus",
    "isoCountryCode2": "ES",
    "isoCountryCode3": "ES",
    "countryName": "Spain",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "prs-AF",
    "displayName": "prs (Afghanistan)",
    "isoCode2": "prs",
    "isoCode3": "",
    "isoCountryCode2": "AF",
    "isoCountryCode3": "AF",
    "countryName": "Afghanistan",
    "currency": "AFN",
    "rtl": true
  },
  {
    "culture": "fa-IR",
    "displayName": "Persian (Iran)",
    "isoCode2": "fa",
    "isoCode3": "fas",
    "isoCountryCode2": "IR",
    "isoCountryCode3": "IR",
    "countryName": "Iran",
    "currency": "IRR",
    "rtl": true
  },
  {
    "culture": "ff-Latn-NG",
    "displayName": "Fulah (Latin, Nigeria)",
    "isoCode2": "ff",
    "isoCode3": "ful",
    "isoCountryCode2": "NG",
    "isoCountryCode3": "NG",
    "countryName": "Nigeria",
    "currency": "NGN",
    "rtl": false
  },
  {
    "culture": "ff-Latn-SN",
    "displayName": "Fulah (Latin, Senegal)",
    "isoCode2": "ff",
    "isoCode3": "ful",
    "isoCountryCode2": "SN",
    "isoCountryCode3": "SN",
    "countryName": "Senegal",
    "currency": "XOF",
    "rtl": false
  },
  {
    "culture": "fi-FI",
    "displayName": "Finnish (Finland)",
    "isoCode2": "fi",
    "isoCode3": "fin",
    "isoCountryCode2": "FI",
    "isoCountryCode3": "FI",
    "countryName": "Finland",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "fil-PH",
    "displayName": "Filipino (Philippines)",
    "isoCode2": "fil",
    "isoCode3": "fil",
    "isoCountryCode2": "PH",
    "isoCountryCode3": "PH",
    "countryName": "Philippines",
    "currency": "PHP",
    "rtl": false
  },
  {
    "culture": "fo-FO",
    "displayName": "Faroese (Faroe Islands)",
    "isoCode2": "fo",
    "isoCode3": "fao",
    "isoCountryCode2": "FO",
    "isoCountryCode3": "FO",
    "countryName": "Faroe Islands",
    "currency": "DKK",
    "rtl": false
  },
  {
    "culture": "fr-BE",
    "displayName": "French (Belgium)",
    "isoCode2": "fr",
    "isoCode3": "fra",
    "isoCountryCode2": "BE",
    "isoCountryCode3": "BE",
    "countryName": "Belgium",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "fr-CA",
    "displayName": "French (Canada)",
    "isoCode2": "fr",
    "isoCode3": "fra",
    "isoCountryCode2": "CA",
    "isoCountryCode3": "CA",
    "countryName": "Canada",
    "currency": "CAD",
    "rtl": false
  },
  {
    "culture": "fr-CD",
    "displayName": "French (Congo [DRC])",
    "isoCode2": "fr",
    "isoCode3": "fra",
    "isoCountryCode2": "CD",
    "isoCountryCode3": "CD",
    "countryName": "Congo (DRC)",
    "currency": "CDF",
    "rtl": false
  },
  {
    "culture": "fr-CH",
    "displayName": "French (Switzerland)",
    "isoCode2": "fr",
    "isoCode3": "fra",
    "isoCountryCode2": "CH",
    "isoCountryCode3": "CH",
    "countryName": "Switzerland",
    "currency": "CHF",
    "rtl": false
  },
  {
    "culture": "fr-CI",
    "displayName": "French (Côte d’Ivoire)",
    "isoCode2": "fr",
    "isoCode3": "fra",
    "isoCountryCode2": "CI",
    "isoCountryCode3": "CI",
    "countryName": "Côte d’Ivoire",
    "currency": "XOF",
    "rtl": false
  },
  {
    "culture": "fr-CM",
    "displayName": "French (Cameroon)",
    "isoCode2": "fr",
    "isoCode3": "fra",
    "isoCountryCode2": "CM",
    "isoCountryCode3": "CM",
    "countryName": "Cameroon",
    "currency": "XAF",
    "rtl": false
  },
  {
    "culture": "fr-FR",
    "displayName": "French (France)",
    "isoCode2": "fr",
    "isoCode3": "fra",
    "isoCountryCode2": "FR",
    "isoCountryCode3": "FR",
    "countryName": "France",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "fr-HT",
    "displayName": "French (Haiti)",
    "isoCode2": "fr",
    "isoCode3": "fra",
    "isoCountryCode2": "HT",
    "isoCountryCode3": "HT",
    "countryName": "Haiti",
    "currency": "HTG",
    "rtl": false
  },
  {
    "culture": "fr-LU",
    "displayName": "French (Luxembourg)",
    "isoCode2": "fr",
    "isoCode3": "fra",
    "isoCountryCode2": "LU",
    "isoCountryCode3": "LU",
    "countryName": "Luxembourg",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "fr-MA",
    "displayName": "French (Morocco)",
    "isoCode2": "fr",
    "isoCode3": "fra",
    "isoCountryCode2": "MA",
    "isoCountryCode3": "MA",
    "countryName": "Morocco",
    "currency": "MAD",
    "rtl": false
  },
  {
    "culture": "fr-MC",
    "displayName": "French (Monaco)",
    "isoCode2": "fr",
    "isoCode3": "fra",
    "isoCountryCode2": "MC",
    "isoCountryCode3": "MC",
    "countryName": "Monaco",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "fr-ML",
    "displayName": "French (Mali)",
    "isoCode2": "fr",
    "isoCode3": "fra",
    "isoCountryCode2": "ML",
    "isoCountryCode3": "ML",
    "countryName": "Mali",
    "currency": "XOF",
    "rtl": false
  },
  {
    "culture": "fr-RE",
    "displayName": "French (Réunion)",
    "isoCode2": "fr",
    "isoCode3": "fra",
    "isoCountryCode2": "RE",
    "isoCountryCode3": "RE",
    "countryName": "Réunion",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "fr-SN",
    "displayName": "French (Senegal)",
    "isoCode2": "fr",
    "isoCode3": "fra",
    "isoCountryCode2": "SN",
    "isoCountryCode3": "SN",
    "countryName": "Senegal",
    "currency": "XOF",
    "rtl": false
  },
  {
    "culture": "fy-NL",
    "displayName": "Western Frisian (Netherlands)",
    "isoCode2": "fy",
    "isoCode3": "fry",
    "isoCountryCode2": "NL",
    "isoCountryCode3": "NL",
    "countryName": "Netherlands",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "ga-IE",
    "displayName": "Irish (Ireland)",
    "isoCode2": "ga",
    "isoCode3": "gle",
    "isoCountryCode2": "IE",
    "isoCountryCode3": "IE",
    "countryName": "Ireland",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "gd-GB",
    "displayName": "Scottish Gaelic (United Kingdom)",
    "isoCode2": "gd",
    "isoCode3": "gla",
    "isoCountryCode2": "GB",
    "isoCountryCode3": "GB",
    "countryName": "United Kingdom",
    "currency": "GBP",
    "rtl": false
  },
  {
    "culture": "gl-ES",
    "displayName": "Galician (Spain)",
    "isoCode2": "gl",
    "isoCode3": "glg",
    "isoCountryCode2": "ES",
    "isoCountryCode3": "ES",
    "countryName": "Spain",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "gn-PY",
    "displayName": "Guarani (Paraguay)",
    "isoCode2": "gn",
    "isoCode3": "grn",
    "isoCountryCode2": "PY",
    "isoCountryCode3": "PY",
    "countryName": "Paraguay",
    "currency": "PYG",
    "rtl": false
  },
  {
    "culture": "gsw-FR",
    "displayName": "Swiss German (France)",
    "isoCode2": "gsw",
    "isoCode3": "gsw",
    "isoCountryCode2": "FR",
    "isoCountryCode3": "FR",
    "countryName": "France",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "gu-IN",
    "displayName": "Gujarati (India)",
    "isoCode2": "gu",
    "isoCode3": "guj",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": false
  },
  {
    "culture": "ha-Latn-NG",
    "displayName": "Hausa (Latin, Nigeria)",
    "isoCode2": "ha",
    "isoCode3": "hau",
    "isoCountryCode2": "NG",
    "isoCountryCode3": "NG",
    "countryName": "Nigeria",
    "currency": "NGN",
    "rtl": false
  },
  {
    "culture": "haw-US",
    "displayName": "Hawaiian (United States)",
    "isoCode2": "haw",
    "isoCode3": "haw",
    "isoCountryCode2": "US",
    "isoCountryCode3": "US",
    "countryName": "United States",
    "currency": "USD",
    "rtl": false
  },
  {
    "culture": "he-IL",
    "displayName": "Hebrew (Israel)",
    "isoCode2": "he",
    "isoCode3": "heb",
    "isoCountryCode2": "IL",
    "isoCountryCode3": "IL",
    "countryName": "Israel",
    "currency": "ILS",
    "rtl": true
  },
  {
    "culture": "hi-IN",
    "displayName": "Hindi (India)",
    "isoCode2": "hi",
    "isoCode3": "hin",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": false
  },
  {
    "culture": "hr-BA",
    "displayName": "Croatian (Bosnia & Herzegovina)",
    "isoCode2": "hr",
    "isoCode3": "hrv",
    "isoCountryCode2": "BA",
    "isoCountryCode3": "BA",
    "countryName": "Bosnia & Herzegovina",
    "currency": "BAM",
    "rtl": false
  },
  {
    "culture": "hr-HR",
    "displayName": "Croatian (Croatia)",
    "isoCode2": "hr",
    "isoCode3": "hrv",
    "isoCountryCode2": "HR",
    "isoCountryCode3": "HR",
    "countryName": "Croatia",
    "currency": "HRK",
    "rtl": false
  },
  {
    "culture": "hsb-DE",
    "displayName": "Upper Sorbian (Germany)",
    "isoCode2": "hsb",
    "isoCode3": "hsb",
    "isoCountryCode2": "DE",
    "isoCountryCode3": "DE",
    "countryName": "Germany",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "hu-HU",
    "displayName": "Hungarian (Hungary)",
    "isoCode2": "hu",
    "isoCode3": "hun",
    "isoCountryCode2": "HU",
    "isoCountryCode3": "HU",
    "countryName": "Hungary",
    "currency": "HUF",
    "rtl": false
  },
  {
    "culture": "hy-AM",
    "displayName": "Armenian (Armenia)",
    "isoCode2": "hy",
    "isoCode3": "hye",
    "isoCountryCode2": "AM",
    "isoCountryCode3": "AM",
    "countryName": "Armenia",
    "currency": "AMD",
    "rtl": false
  },
  {
    "culture": "id-ID",
    "displayName": "Indonesian (Indonesia)",
    "isoCode2": "id",
    "isoCode3": "ind",
    "isoCountryCode2": "ID",
    "isoCountryCode3": "ID",
    "countryName": "Indonesia",
    "currency": "IDR",
    "rtl": false
  },
  {
    "culture": "ig-NG",
    "displayName": "Igbo (Nigeria)",
    "isoCode2": "ig",
    "isoCode3": "ibo",
    "isoCountryCode2": "NG",
    "isoCountryCode3": "NG",
    "countryName": "Nigeria",
    "currency": "NGN",
    "rtl": false
  },
  {
    "culture": "ii-CN",
    "displayName": "Yi (China)",
    "isoCode2": "ii",
    "isoCode3": "iii",
    "isoCountryCode2": "CN",
    "isoCountryCode3": "CN",
    "countryName": "China",
    "currency": "CNY",
    "rtl": false
  },
  {
    "culture": "is-IS",
    "displayName": "Icelandic (Iceland)",
    "isoCode2": "is",
    "isoCode3": "isl",
    "isoCountryCode2": "IS",
    "isoCountryCode3": "IS",
    "countryName": "Iceland",
    "currency": "ISK",
    "rtl": false
  },
  {
    "culture": "it-CH",
    "displayName": "Italian (Switzerland)",
    "isoCode2": "it",
    "isoCode3": "ita",
    "isoCountryCode2": "CH",
    "isoCountryCode3": "CH",
    "countryName": "Switzerland",
    "currency": "CHF",
    "rtl": false
  },
  {
    "culture": "it-IT",
    "displayName": "Italian (Italy)",
    "isoCode2": "it",
    "isoCode3": "ita",
    "isoCountryCode2": "IT",
    "isoCountryCode3": "IT",
    "countryName": "Italy",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "iu-Latn-CA",
    "displayName": "Inuktitut (Latin, Canada)",
    "isoCode2": "iu",
    "isoCode3": "iku",
    "isoCountryCode2": "CA",
    "isoCountryCode3": "CA",
    "countryName": "Canada",
    "currency": "CAD",
    "rtl": false
  },
  {
    "culture": "ja-JP",
    "displayName": "Japanese (Japan)",
    "isoCode2": "ja",
    "isoCode3": "jpn",
    "isoCountryCode2": "JP",
    "isoCountryCode3": "JP",
    "countryName": "Japan",
    "currency": "JPY",
    "rtl": false
  },
  {
    "culture": "ka-GE",
    "displayName": "Georgian (Georgia)",
    "isoCode2": "ka",
    "isoCode3": "kat",
    "isoCountryCode2": "GE",
    "isoCountryCode3": "GE",
    "countryName": "Georgia",
    "currency": "GEL",
    "rtl": false
  },
  {
    "culture": "kk-KZ",
    "displayName": "Kazakh (Kazakhstan)",
    "isoCode2": "kk",
    "isoCode3": "kaz",
    "isoCountryCode2": "KZ",
    "isoCountryCode3": "KZ",
    "countryName": "Kazakhstan",
    "currency": "KZT",
    "rtl": false
  },
  {
    "culture": "kl-GL",
    "displayName": "Kalaallisut (Greenland)",
    "isoCode2": "kl",
    "isoCode3": "kal",
    "isoCountryCode2": "GL",
    "isoCountryCode3": "GL",
    "countryName": "Greenland",
    "currency": "DKK",
    "rtl": false
  },
  {
    "culture": "km-KH",
    "displayName": "Khmer (Cambodia)",
    "isoCode2": "km",
    "isoCode3": "khm",
    "isoCountryCode2": "KH",
    "isoCountryCode3": "KH",
    "countryName": "Cambodia",
    "currency": "KHR",
    "rtl": false
  },
  {
    "culture": "kn-IN",
    "displayName": "Kannada (India)",
    "isoCode2": "kn",
    "isoCode3": "kan",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": false
  },
  {
    "culture": "ko-KR",
    "displayName": "Korean (Korea)",
    "isoCode2": "ko",
    "isoCode3": "kor",
    "isoCountryCode2": "KR",
    "isoCountryCode3": "KR",
    "countryName": "Korea",
    "currency": "KRW",
    "rtl": false
  },
  {
    "culture": "kok-IN",
    "displayName": "Konkani (India)",
    "isoCode2": "kok",
    "isoCode3": "kok",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": false
  },
  {
    "culture": "ky-KG",
    "displayName": "Kyrgyz (Kyrgyzstan)",
    "isoCode2": "ky",
    "isoCode3": "kir",
    "isoCountryCode2": "KG",
    "isoCountryCode3": "KG",
    "countryName": "Kyrgyzstan",
    "currency": "KGS",
    "rtl": false
  },
  {
    "culture": "lb-LU",
    "displayName": "Luxembourgish (Luxembourg)",
    "isoCode2": "lb",
    "isoCode3": "ltz",
    "isoCountryCode2": "LU",
    "isoCountryCode3": "LU",
    "countryName": "Luxembourg",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "lo-LA",
    "displayName": "Lao (Laos)",
    "isoCode2": "lo",
    "isoCode3": "lao",
    "isoCountryCode2": "LA",
    "isoCountryCode3": "LA",
    "countryName": "Laos",
    "currency": "LAK",
    "rtl": false
  },
  {
    "culture": "lt-LT",
    "displayName": "Lithuanian (Lithuania)",
    "isoCode2": "lt",
    "isoCode3": "lit",
    "isoCountryCode2": "LT",
    "isoCountryCode3": "LT",
    "countryName": "Lithuania",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "lv-LV",
    "displayName": "Latvian (Latvia)",
    "isoCode2": "lv",
    "isoCode3": "lav",
    "isoCountryCode2": "LV",
    "isoCountryCode3": "LV",
    "countryName": "Latvia",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "mi-NZ",
    "displayName": "Maori (New Zealand)",
    "isoCode2": "mi",
    "isoCode3": "mri",
    "isoCountryCode2": "NZ",
    "isoCountryCode3": "NZ",
    "countryName": "New Zealand",
    "currency": "NZD",
    "rtl": false
  },
  {
    "culture": "mk-MK",
    "displayName": "Macedonian (North Macedonia)",
    "isoCode2": "mk",
    "isoCode3": "mkd",
    "isoCountryCode2": "MK",
    "isoCountryCode3": "MK",
    "countryName": "North Macedonia",
    "currency": "MKD",
    "rtl": false
  },
  {
    "culture": "ml-IN",
    "displayName": "Malayalam (India)",
    "isoCode2": "ml",
    "isoCode3": "mal",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": false
  },
  {
    "culture": "mn-MN",
    "displayName": "Mongolian (Mongolia)",
    "isoCode2": "mn",
    "isoCode3": "mon",
    "isoCountryCode2": "MN",
    "isoCountryCode3": "MN",
    "countryName": "Mongolia",
    "currency": "MNT",
    "rtl": false
  },
  {
    "culture": "mn-Mong-CN",
    "displayName": "Mongolian (Mongolian, China)",
    "isoCode2": "mn",
    "isoCode3": "mon",
    "isoCountryCode2": "CN",
    "isoCountryCode3": "CN",
    "countryName": "China",
    "currency": "CNY",
    "rtl": false
  },
  {
    "culture": "mn-Mong-MN",
    "displayName": "Mongolian (Mongolian, Mongolia)",
    "isoCode2": "mn",
    "isoCode3": "mon",
    "isoCountryCode2": "MN",
    "isoCountryCode3": "MN",
    "countryName": "Mongolia",
    "currency": "MNT",
    "rtl": false
  },
  {
    "culture": "moh-CA",
    "displayName": "Mohawk (Canada)",
    "isoCode2": "moh",
    "isoCode3": "moh",
    "isoCountryCode2": "CA",
    "isoCountryCode3": "CA",
    "countryName": "Canada",
    "currency": "CAD",
    "rtl": false
  },
  {
    "culture": "mr-IN",
    "displayName": "Marathi (India)",
    "isoCode2": "mr",
    "isoCode3": "mar",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": false
  },
  {
    "culture": "ms-BN",
    "displayName": "Malay (Brunei)",
    "isoCode2": "ms",
    "isoCode3": "msa",
    "isoCountryCode2": "BN",
    "isoCountryCode3": "BN",
    "countryName": "Brunei",
    "currency": "BND",
    "rtl": false
  },
  {
    "culture": "ms-MY",
    "displayName": "Malay (Malaysia)",
    "isoCode2": "ms",
    "isoCode3": "msa",
    "isoCountryCode2": "MY",
    "isoCountryCode3": "MY",
    "countryName": "Malaysia",
    "currency": "MYR",
    "rtl": false
  },
  {
    "culture": "mt-MT",
    "displayName": "Maltese (Malta)",
    "isoCode2": "mt",
    "isoCode3": "mlt",
    "isoCountryCode2": "MT",
    "isoCountryCode3": "MT",
    "countryName": "Malta",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "my-MM",
    "displayName": "Burmese (Myanmar)",
    "isoCode2": "my",
    "isoCode3": "mya",
    "isoCountryCode2": "MM",
    "isoCountryCode3": "MM",
    "countryName": "Myanmar",
    "currency": "MMK",
    "rtl": false
  },
  {
    "culture": "nb-NO",
    "displayName": "Norwegian Bokmål (Norway)",
    "isoCode2": "nb",
    "isoCode3": "nob",
    "isoCountryCode2": "NO",
    "isoCountryCode3": "NO",
    "countryName": "Norway",
    "currency": "NOK",
    "rtl": false
  },
  {
    "culture": "ne-IN",
    "displayName": "Nepali (India)",
    "isoCode2": "ne",
    "isoCode3": "nep",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": false
  },
  {
    "culture": "ne-NP",
    "displayName": "Nepali (Nepal)",
    "isoCode2": "ne",
    "isoCode3": "nep",
    "isoCountryCode2": "NP",
    "isoCountryCode3": "NP",
    "countryName": "Nepal",
    "currency": "NPR",
    "rtl": false
  },
  {
    "culture": "nl-BE",
    "displayName": "Dutch (Belgium)",
    "isoCode2": "nl",
    "isoCode3": "nld",
    "isoCountryCode2": "BE",
    "isoCountryCode3": "BE",
    "countryName": "Belgium",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "nl-NL",
    "displayName": "Dutch (Netherlands)",
    "isoCode2": "nl",
    "isoCode3": "nld",
    "isoCountryCode2": "NL",
    "isoCountryCode3": "NL",
    "countryName": "Netherlands",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "nn-NO",
    "displayName": "Norwegian Nynorsk (Norway)",
    "isoCode2": "nn",
    "isoCode3": "nno",
    "isoCountryCode2": "NO",
    "isoCountryCode3": "NO",
    "countryName": "Norway",
    "currency": "NOK",
    "rtl": false
  },
  {
    "culture": "nso-ZA",
    "displayName": "Sesotho sa Leboa (South Africa)",
    "isoCode2": "nso",
    "isoCode3": "nso",
    "isoCountryCode2": "ZA",
    "isoCountryCode3": "ZA",
    "countryName": "South Africa",
    "currency": "ZAR",
    "rtl": false
  },
  {
    "culture": "oc-FR",
    "displayName": "Occitan (France)",
    "isoCode2": "oc",
    "isoCode3": "oci",
    "isoCountryCode2": "FR",
    "isoCountryCode3": "FR",
    "countryName": "France",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "om-ET",
    "displayName": "Oromo (Ethiopia)",
    "isoCode2": "om",
    "isoCode3": "orm",
    "isoCountryCode2": "ET",
    "isoCountryCode3": "ET",
    "countryName": "Ethiopia",
    "currency": "ETB",
    "rtl": false
  },
  {
    "culture": "or-IN",
    "displayName": "Odia (India)",
    "isoCode2": "or",
    "isoCode3": "ori",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": false
  },
  {
    "culture": "pa-Arab-PK",
    "displayName": "Punjabi (Arabic, Pakistan)",
    "isoCode2": "pa",
    "isoCode3": "pan",
    "isoCountryCode2": "PK",
    "isoCountryCode3": "PK",
    "countryName": "Pakistan",
    "currency": "PKR",
    "rtl": true
  },
  {
    "culture": "pa-IN",
    "displayName": "Punjabi (India)",
    "isoCode2": "pa",
    "isoCode3": "pan",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": false
  },
  {
    "culture": "pl-PL",
    "displayName": "Polish (Poland)",
    "isoCode2": "pl",
    "isoCode3": "pol",
    "isoCountryCode2": "PL",
    "isoCountryCode3": "PL",
    "countryName": "Poland",
    "currency": "PLN",
    "rtl": false
  },
  {
    "culture": "ps-AF",
    "displayName": "Pashto (Afghanistan)",
    "isoCode2": "ps",
    "isoCode3": "pus",
    "isoCountryCode2": "AF",
    "isoCountryCode3": "AF",
    "countryName": "Afghanistan",
    "currency": "AFN",
    "rtl": true
  },
  {
    "culture": "pt-BR",
    "displayName": "Portuguese (Brazil)",
    "isoCode2": "pt",
    "isoCode3": "por",
    "isoCountryCode2": "BR",
    "isoCountryCode3": "BR",
    "countryName": "Brazil",
    "currency": "BRL",
    "rtl": false
  },
  {
    "culture": "pt-PT",
    "displayName": "Portuguese (Portugal)",
    "isoCode2": "pt",
    "isoCode3": "por",
    "isoCountryCode2": "PT",
    "isoCountryCode3": "PT",
    "countryName": "Portugal",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "quz-BO",
    "displayName": "quz (Bolivia)",
    "isoCode2": "quz",
    "isoCode3": "",
    "isoCountryCode2": "BO",
    "isoCountryCode3": "BO",
    "countryName": "Bolivia",
    "currency": "BOB",
    "rtl": false
  },
  {
    "culture": "quz-EC",
    "displayName": "quz (Ecuador)",
    "isoCode2": "quz",
    "isoCode3": "",
    "isoCountryCode2": "EC",
    "isoCountryCode3": "EC",
    "countryName": "Ecuador",
    "currency": "USD",
    "rtl": false
  },
  {
    "culture": "quz-PE",
    "displayName": "quz (Peru)",
    "isoCode2": "quz",
    "isoCode3": "",
    "isoCountryCode2": "PE",
    "isoCountryCode3": "PE",
    "countryName": "Peru",
    "currency": "PEN",
    "rtl": false
  },
  {
    "culture": "rm-CH",
    "displayName": "Romansh (Switzerland)",
    "isoCode2": "rm",
    "isoCode3": "roh",
    "isoCountryCode2": "CH",
    "isoCountryCode3": "CH",
    "countryName": "Switzerland",
    "currency": "CHF",
    "rtl": false
  },
  {
    "culture": "ro-MD",
    "displayName": "Romanian (Moldova)",
    "isoCode2": "ro",
    "isoCode3": "ron",
    "isoCountryCode2": "MD",
    "isoCountryCode3": "MD",
    "countryName": "Moldova",
    "currency": "MDL",
    "rtl": false
  },
  {
    "culture": "ro-RO",
    "displayName": "Romanian (Romania)",
    "isoCode2": "ro",
    "isoCode3": "ron",
    "isoCountryCode2": "RO",
    "isoCountryCode3": "RO",
    "countryName": "Romania",
    "currency": "RON",
    "rtl": false
  },
  {
    "culture": "ru-MD",
    "displayName": "Russian (Moldova)",
    "isoCode2": "ru",
    "isoCode3": "rus",
    "isoCountryCode2": "MD",
    "isoCountryCode3": "MD",
    "countryName": "Moldova",
    "currency": "MDL",
    "rtl": false
  },
  {
    "culture": "ru-RU",
    "displayName": "Russian (Russia)",
    "isoCode2": "ru",
    "isoCode3": "rus",
    "isoCountryCode2": "RU",
    "isoCountryCode3": "RU",
    "countryName": "Russia",
    "currency": "RUB",
    "rtl": false
  },
  {
    "culture": "rw-RW",
    "displayName": "Kinyarwanda (Rwanda)",
    "isoCode2": "rw",
    "isoCode3": "kin",
    "isoCountryCode2": "RW",
    "isoCountryCode3": "RW",
    "countryName": "Rwanda",
    "currency": "RWF",
    "rtl": false
  },
  {
    "culture": "sa-IN",
    "displayName": "Sanskrit (India)",
    "isoCode2": "sa",
    "isoCode3": "san",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": false
  },
  {
    "culture": "sah-RU",
    "displayName": "Sakha (Russia)",
    "isoCode2": "sah",
    "isoCode3": "sah",
    "isoCountryCode2": "RU",
    "isoCountryCode3": "RU",
    "countryName": "Russia",
    "currency": "RUB",
    "rtl": false
  },
  {
    "culture": "sd-Arab-PK",
    "displayName": "Sindhi (Arabic, Pakistan)",
    "isoCode2": "sd",
    "isoCode3": "snd",
    "isoCountryCode2": "PK",
    "isoCountryCode3": "PK",
    "countryName": "Pakistan",
    "currency": "PKR",
    "rtl": true
  },
  {
    "culture": "se-FI",
    "displayName": "Northern Sami (Finland)",
    "isoCode2": "se",
    "isoCode3": "sme",
    "isoCountryCode2": "FI",
    "isoCountryCode3": "FI",
    "countryName": "Finland",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "se-NO",
    "displayName": "Northern Sami (Norway)",
    "isoCode2": "se",
    "isoCode3": "sme",
    "isoCountryCode2": "NO",
    "isoCountryCode3": "NO",
    "countryName": "Norway",
    "currency": "NOK",
    "rtl": false
  },
  {
    "culture": "se-SE",
    "displayName": "Northern Sami (Sweden)",
    "isoCode2": "se",
    "isoCode3": "sme",
    "isoCountryCode2": "SE",
    "isoCountryCode3": "SE",
    "countryName": "Sweden",
    "currency": "SEK",
    "rtl": false
  },
  {
    "culture": "si-LK",
    "displayName": "Sinhala (Sri Lanka)",
    "isoCode2": "si",
    "isoCode3": "sin",
    "isoCountryCode2": "LK",
    "isoCountryCode3": "LK",
    "countryName": "Sri Lanka",
    "currency": "LKR",
    "rtl": false
  },
  {
    "culture": "sk-SK",
    "displayName": "Slovak (Slovakia)",
    "isoCode2": "sk",
    "isoCode3": "slk",
    "isoCountryCode2": "SK",
    "isoCountryCode3": "SK",
    "countryName": "Slovakia",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "sl-SI",
    "displayName": "Slovenian (Slovenia)",
    "isoCode2": "sl",
    "isoCode3": "slv",
    "isoCountryCode2": "SI",
    "isoCountryCode3": "SI",
    "countryName": "Slovenia",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "sma-NO",
    "displayName": "Southern Sami (Norway)",
    "isoCode2": "sma",
    "isoCode3": "sma",
    "isoCountryCode2": "NO",
    "isoCountryCode3": "NO",
    "countryName": "Norway",
    "currency": "NOK",
    "rtl": false
  },
  {
    "culture": "sma-SE",
    "displayName": "Southern Sami (Sweden)",
    "isoCode2": "sma",
    "isoCode3": "sma",
    "isoCountryCode2": "SE",
    "isoCountryCode3": "SE",
    "countryName": "Sweden",
    "currency": "SEK",
    "rtl": false
  },
  {
    "culture": "smj-NO",
    "displayName": "Lule Sami (Norway)",
    "isoCode2": "smj",
    "isoCode3": "smj",
    "isoCountryCode2": "NO",
    "isoCountryCode3": "NO",
    "countryName": "Norway",
    "currency": "NOK",
    "rtl": false
  },
  {
    "culture": "smj-SE",
    "displayName": "Lule Sami (Sweden)",
    "isoCode2": "smj",
    "isoCode3": "smj",
    "isoCountryCode2": "SE",
    "isoCountryCode3": "SE",
    "countryName": "Sweden",
    "currency": "SEK",
    "rtl": false
  },
  {
    "culture": "smn-FI",
    "displayName": "Inari Sami (Finland)",
    "isoCode2": "smn",
    "isoCode3": "smn",
    "isoCountryCode2": "FI",
    "isoCountryCode3": "FI",
    "countryName": "Finland",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "sms-FI",
    "displayName": "Skolt Sami (Finland)",
    "isoCode2": "sms",
    "isoCode3": "sms",
    "isoCountryCode2": "FI",
    "isoCountryCode3": "FI",
    "countryName": "Finland",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "so-SO",
    "displayName": "Somali (Somalia)",
    "isoCode2": "so",
    "isoCode3": "som",
    "isoCountryCode2": "SO",
    "isoCountryCode3": "SO",
    "countryName": "Somalia",
    "currency": "SOS",
    "rtl": false
  },
  {
    "culture": "sq-AL",
    "displayName": "Albanian (Albania)",
    "isoCode2": "sq",
    "isoCode3": "sqi",
    "isoCountryCode2": "AL",
    "isoCountryCode3": "AL",
    "countryName": "Albania",
    "currency": "ALL",
    "rtl": false
  },
  {
    "culture": "sr-Cyrl-BA",
    "displayName": "Serbian (Cyrillic, Bosnia & Herzegovina)",
    "isoCode2": "sr",
    "isoCode3": "srp",
    "isoCountryCode2": "BA",
    "isoCountryCode3": "BA",
    "countryName": "Bosnia & Herzegovina",
    "currency": "BAM",
    "rtl": false
  },
  {
    "culture": "sr-Cyrl-ME",
    "displayName": "Serbian (Cyrillic, Montenegro)",
    "isoCode2": "sr",
    "isoCode3": "srp",
    "isoCountryCode2": "ME",
    "isoCountryCode3": "ME",
    "countryName": "Montenegro",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "sr-Cyrl-RS",
    "displayName": "Serbian (Cyrillic, Serbia)",
    "isoCode2": "sr",
    "isoCode3": "srp",
    "isoCountryCode2": "RS",
    "isoCountryCode3": "RS",
    "countryName": "Serbia",
    "currency": "RSD",
    "rtl": false
  },
  {
    "culture": "sr-Latn-BA",
    "displayName": "Serbian (Latin, Bosnia & Herzegovina)",
    "isoCode2": "sr",
    "isoCode3": "srp",
    "isoCountryCode2": "BA",
    "isoCountryCode3": "BA",
    "countryName": "Bosnia & Herzegovina",
    "currency": "BAM",
    "rtl": false
  },
  {
    "culture": "sr-Latn-ME",
    "displayName": "Serbian (Latin, Montenegro)",
    "isoCode2": "sr",
    "isoCode3": "srp",
    "isoCountryCode2": "ME",
    "isoCountryCode3": "ME",
    "countryName": "Montenegro",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "sr-Latn-RS",
    "displayName": "Serbian (Latin, Serbia)",
    "isoCode2": "sr",
    "isoCode3": "srp",
    "isoCountryCode2": "RS",
    "isoCountryCode3": "RS",
    "countryName": "Serbia",
    "currency": "RSD",
    "rtl": false
  },
  {
    "culture": "st-ZA",
    "displayName": "Sesotho (South Africa)",
    "isoCode2": "st",
    "isoCode3": "sot",
    "isoCountryCode2": "ZA",
    "isoCountryCode3": "ZA",
    "countryName": "South Africa",
    "currency": "ZAR",
    "rtl": false
  },
  {
    "culture": "sv-FI",
    "displayName": "Swedish (Finland)",
    "isoCode2": "sv",
    "isoCode3": "swe",
    "isoCountryCode2": "FI",
    "isoCountryCode3": "FI",
    "countryName": "Finland",
    "currency": "EUR",
    "rtl": false
  },
  {
    "culture": "sv-SE",
    "displayName": "Swedish (Sweden)",
    "isoCode2": "sv",
    "isoCode3": "swe",
    "isoCountryCode2": "SE",
    "isoCountryCode3": "SE",
    "countryName": "Sweden",
    "currency": "SEK",
    "rtl": false
  },
  {
    "culture": "sw-KE",
    "displayName": "Kiswahili (Kenya)",
    "isoCode2": "sw",
    "isoCode3": "swa",
    "isoCountryCode2": "KE",
    "isoCountryCode3": "KE",
    "countryName": "Kenya",
    "currency": "KES",
    "rtl": false
  },
  {
    "culture": "syr-SY",
    "displayName": "Syriac (Syria)",
    "isoCode2": "syr",
    "isoCode3": "syr",
    "isoCountryCode2": "SY",
    "isoCountryCode3": "SY",
    "countryName": "Syria",
    "currency": "SYP",
    "rtl": true
  },
  {
    "culture": "ta-IN",
    "displayName": "Tamil (India)",
    "isoCode2": "ta",
    "isoCode3": "tam",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": false
  },
  {
    "culture": "ta-LK",
    "displayName": "Tamil (Sri Lanka)",
    "isoCode2": "ta",
    "isoCode3": "tam",
    "isoCountryCode2": "LK",
    "isoCountryCode3": "LK",
    "countryName": "Sri Lanka",
    "currency": "LKR",
    "rtl": false
  },
  {
    "culture": "te-IN",
    "displayName": "Telugu (India)",
    "isoCode2": "te",
    "isoCode3": "tel",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": false
  },
  {
    "culture": "tg-Cyrl-TJ",
    "displayName": "Tajik (Cyrillic, Tajikistan)",
    "isoCode2": "tg",
    "isoCode3": "tgk",
    "isoCountryCode2": "TJ",
    "isoCountryCode3": "TJ",
    "countryName": "Tajikistan",
    "currency": "TJS",
    "rtl": false
  },
  {
    "culture": "th-TH",
    "displayName": "Thai (Thailand)",
    "isoCode2": "th",
    "isoCode3": "tha",
    "isoCountryCode2": "TH",
    "isoCountryCode3": "TH",
    "countryName": "Thailand",
    "currency": "THB",
    "rtl": false
  },
  {
    "culture": "ti-ER",
    "displayName": "Tigrinya (Eritrea)",
    "isoCode2": "ti",
    "isoCode3": "tir",
    "isoCountryCode2": "ER",
    "isoCountryCode3": "ER",
    "countryName": "Eritrea",
    "currency": "ERN",
    "rtl": false
  },
  {
    "culture": "ti-ET",
    "displayName": "Tigrinya (Ethiopia)",
    "isoCode2": "ti",
    "isoCode3": "tir",
    "isoCountryCode2": "ET",
    "isoCountryCode3": "ET",
    "countryName": "Ethiopia",
    "currency": "ETB",
    "rtl": false
  },
  {
    "culture": "tk-TM",
    "displayName": "Turkmen (Turkmenistan)",
    "isoCode2": "tk",
    "isoCode3": "tuk",
    "isoCountryCode2": "TM",
    "isoCountryCode3": "TM",
    "countryName": "Turkmenistan",
    "currency": "TMT",
    "rtl": false
  },
  {
    "culture": "tn-BW",
    "displayName": "Setswana (Botswana)",
    "isoCode2": "tn",
    "isoCode3": "tsn",
    "isoCountryCode2": "BW",
    "isoCountryCode3": "BW",
    "countryName": "Botswana",
    "currency": "BWP",
    "rtl": false
  },
  {
    "culture": "tn-ZA",
    "displayName": "Setswana (South Africa)",
    "isoCode2": "tn",
    "isoCode3": "tsn",
    "isoCountryCode2": "ZA",
    "isoCountryCode3": "ZA",
    "countryName": "South Africa",
    "currency": "ZAR",
    "rtl": false
  },
  {
    "culture": "tr-TR",
    "displayName": "Turkish (Turkey)",
    "isoCode2": "tr",
    "isoCode3": "tur",
    "isoCountryCode2": "TR",
    "isoCountryCode3": "TR",
    "countryName": "Turkey",
    "currency": "TRY",
    "rtl": false
  },
  {
    "culture": "ts-ZA",
    "displayName": "Xitsonga (South Africa)",
    "isoCode2": "ts",
    "isoCode3": "tso",
    "isoCountryCode2": "ZA",
    "isoCountryCode3": "ZA",
    "countryName": "South Africa",
    "currency": "ZAR",
    "rtl": false
  },
  {
    "culture": "tt-RU",
    "displayName": "Tatar (Russia)",
    "isoCode2": "tt",
    "isoCode3": "tat",
    "isoCountryCode2": "RU",
    "isoCountryCode3": "RU",
    "countryName": "Russia",
    "currency": "RUB",
    "rtl": false
  },
  {
    "culture": "ug-CN",
    "displayName": "Uyghur (China)",
    "isoCode2": "ug",
    "isoCode3": "uig",
    "isoCountryCode2": "CN",
    "isoCountryCode3": "CN",
    "countryName": "China",
    "currency": "CNY",
    "rtl": true
  },
  {
    "culture": "uk-UA",
    "displayName": "Ukrainian (Ukraine)",
    "isoCode2": "uk",
    "isoCode3": "ukr",
    "isoCountryCode2": "UA",
    "isoCountryCode3": "UA",
    "countryName": "Ukraine",
    "currency": "UAH",
    "rtl": false
  },
  {
    "culture": "ur-IN",
    "displayName": "Urdu (India)",
    "isoCode2": "ur",
    "isoCode3": "urd",
    "isoCountryCode2": "IN",
    "isoCountryCode3": "IN",
    "countryName": "India",
    "currency": "INR",
    "rtl": true
  },
  {
    "culture": "ur-PK",
    "displayName": "Urdu (Pakistan)",
    "isoCode2": "ur",
    "isoCode3": "urd",
    "isoCountryCode2": "PK",
    "isoCountryCode3": "PK",
    "countryName": "Pakistan",
    "currency": "PKR",
    "rtl": true
  },
  {
    "culture": "uz-Cyrl-UZ",
    "displayName": "Uzbek (Cyrillic, Uzbekistan)",
    "isoCode2": "uz",
    "isoCode3": "uzb",
    "isoCountryCode2": "UZ",
    "isoCountryCode3": "UZ",
    "countryName": "Uzbekistan",
    "currency": "UZS",
    "rtl": false
  },
  {
    "culture": "uz-Latn-UZ",
    "displayName": "Uzbek (Latin, Uzbekistan)",
    "isoCode2": "uz",
    "isoCode3": "uzb",
    "isoCountryCode2": "UZ",
    "isoCountryCode3": "UZ",
    "countryName": "Uzbekistan",
    "currency": "UZS",
    "rtl": false
  },
  {
    "culture": "ve-ZA",
    "displayName": "Venda (South Africa)",
    "isoCode2": "ve",
    "isoCode3": "ven",
    "isoCountryCode2": "ZA",
    "isoCountryCode3": "ZA",
    "countryName": "South Africa",
    "currency": "ZAR",
    "rtl": false
  },
  {
    "culture": "vi-VN",
    "displayName": "Vietnamese (Vietnam)",
    "isoCode2": "vi",
    "isoCode3": "vie",
    "isoCountryCode2": "VN",
    "isoCountryCode3": "VN",
    "countryName": "Vietnam",
    "currency": "VND",
    "rtl": false
  },
  {
    "culture": "wo-SN",
    "displayName": "Wolof (Senegal)",
    "isoCode2": "wo",
    "isoCode3": "wol",
    "isoCountryCode2": "SN",
    "isoCountryCode3": "SN",
    "countryName": "Senegal",
    "currency": "XOF",
    "rtl": false
  },
  {
    "culture": "xh-ZA",
    "displayName": "isiXhosa (South Africa)",
    "isoCode2": "xh",
    "isoCode3": "xho",
    "isoCountryCode2": "ZA",
    "isoCountryCode3": "ZA",
    "countryName": "South Africa",
    "currency": "ZAR",
    "rtl": false
  },
  {
    "culture": "yi-001",
    "displayName": "Yiddish (World)",
    "isoCode2": "yi",
    "isoCode3": "yid",
    "isoCountryCode2": "001",
    "isoCountryCode3": "001",
    "countryName": "World",
    "currency": "¤¤",
    "rtl": true
  },
  {
    "culture": "yo-NG",
    "displayName": "Yoruba (Nigeria)",
    "isoCode2": "yo",
    "isoCode3": "yor",
    "isoCountryCode2": "NG",
    "isoCountryCode3": "NG",
    "countryName": "Nigeria",
    "currency": "NGN",
    "rtl": false
  },
  {
    "culture": "zh-CN",
    "displayName": "Chinese (China)",
    "isoCode2": "zh",
    "isoCode3": "zho",
    "isoCountryCode2": "CN",
    "isoCountryCode3": "CN",
    "countryName": "China",
    "currency": "CNY",
    "rtl": false
  },
  {
    "culture": "zh-SG",
    "displayName": "Chinese (Singapore)",
    "isoCode2": "zh",
    "isoCode3": "zho",
    "isoCountryCode2": "SG",
    "isoCountryCode3": "SG",
    "countryName": "Singapore",
    "currency": "SGD",
    "rtl": false
  },
  {
    "culture": "zh-HK",
    "displayName": "Chinese (Hong Kong SAR)",
    "isoCode2": "zh",
    "isoCode3": "zho",
    "isoCountryCode2": "HK",
    "isoCountryCode3": "HK",
    "countryName": "Hong Kong SAR",
    "currency": "HKD",
    "rtl": false
  },
  {
    "culture": "zh-MO",
    "displayName": "Chinese (Macao SAR)",
    "isoCode2": "zh",
    "isoCode3": "zho",
    "isoCountryCode2": "MO",
    "isoCountryCode3": "MO",
    "countryName": "Macao SAR",
    "currency": "MOP",
    "rtl": false
  },
  {
    "culture": "zh-TW",
    "displayName": "Chinese (Taiwan)",
    "isoCode2": "zh",
    "isoCode3": "zho",
    "isoCountryCode2": "TW",
    "isoCountryCode3": "TW",
    "countryName": "Taiwan",
    "currency": "TWD",
    "rtl": false
  },
  {
    "culture": "zu-ZA",
    "displayName": "isiZulu (South Africa)",
    "isoCode2": "zu",
    "isoCode3": "zul",
    "isoCountryCode2": "ZA",
    "isoCountryCode3": "ZA",
    "countryName": "South Africa",
    "currency": "ZAR",
    "rtl": false
  }
]


const cultures = {
  getCultureByName(name) {
    let ix = _.findIndex(db, c => c.culture == name)

    if (ix >= 0) {
      return db[ix]
    }

    return null
  },
  getAllCultures() {
    return db
  },
}

export default cultures
