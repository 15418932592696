import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// Global Filters
import './global-filters'

// Atlas Components
import './atlas-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Composition API
Vue.use(VueCompositionAPI)

require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
require('@/assets/scss/style-dark.scss')

Vue.config.productionTip = false

// import mixin
import EnvMixin from './mixins/EnvMixin'

Vue.mixin(EnvMixin)

if (store.getters.isAuthenticated) {
  store
    .dispatch('LoadEnvironment')
    .then(() => {
      const lastProject = localStorage.getItem('last_project')
      if (lastProject) {
        store.dispatch('LoadProject', lastProject).then(() => {
          new Vue({
            router,
            store,
            i18n,
            render: h => h(App),
          }).$mount('#app')
        })
      } else {
        new Vue({
          router,
          store,
          i18n,
          render: h => h(App),
        }).$mount('#app')
      }
    })
    .catch(err => {
      store.dispatch('LogOut')
      window.location.reload()
    })
} else {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')
}
