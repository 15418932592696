<template>
  <div>
    <b-form-group>
      <div class="inline-spacing">
        <b-form-checkbox
          v-model="internal"
          name="check-button"
          class="custom-control-success mt-0"
          switch
          inline
          :disabled="field.readOnly"
          @change="$emit('input', $event)"
        />
      </div>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: 'BooleanField',
  props: ['field', 'value', 'errors', 'index'],
  data() {
    return {
      internal: null,
    }
  },
  computed: {},
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.internal = val
      },
    },
  },
  methods: {},
}
</script>
