<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content">
        <span class="sidebar-close-icon">
          <feather-icon icon="XIcon" size="16" @click="$emit('update:mq-shall-show-left-sidebar', false)" />
        </span>
        <!-- Header -->
        <div class="media-library-fixed-search">
          <div class="d-flex align-items-center w-100">
            <div class="sidebar-profile-toggle"></div>
            <!-- Search -->
            <div class="align-baseline">
              <portal-target name="media-sidebar-header" slim />
            </div>
            <!-- <b-input-group class="input-group-merge ml-1 w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-prepend>
              <b-form-input placeholder="Search..." />
            </b-input-group> -->
          </div>
        </div>
        <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="media-library-user-list-wrapper list-group scroll-area">
          <h5 class="media-library-list-title text-dark">
            Folders
          </h5>
          <folders @folder-selected="onFolderSelected" @folder-moved="onFolderMoved" />
        </vue-perfect-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Folders from './Folders.vue'

export default {
  props: [],
  components: {
    VuePerfectScrollbar,
    Folders,
  },
  data() {
    return {
      perfectScrollbarSettings: { maxScrollbarLength: 150 },
      currentFolder: null,
    }
  },
  created() {},
  computed: {
    folderName() {
      return this.currentFolder ? this.currentFolder.text : 'All Media'
    },
  },
  methods: {
    onFolderSelected(e) {
      this.currentFolder = e
      this.$emit('folder-selected', e)
    },
    onFolderMoved(e) {},
  },
}
</script>
<style lang="scss"></style>
