<template>
  <div>
    <b-button variant="primary" @click.prevent="visible = true"><feather-icon icon="PlusIcon" /> Add Relation</b-button>
    <b-modal
      :no-close-on-backdrop="false"
      :no-close-on-esc="false"
      :hide-header="true"
      :hide-footer="false"
      v-model="visible"
      centered
      size="lg"
      ok-variant="outline-secondary"
      ok-only
      ok-title="Done"
      @ok="onOk"
      @show="onShow"
      @hidden="onHidden"
    >
      <h3>Select Contents</h3>
      <hr />
      <div class="d-flex justify-content-center flex-column">
        <div class="d-flex justify-content-between align-items-center flex-wrap mb-1">
          <div class="flex-fill">
            <b-form inline @submit.prevent>
              <b-input-group class="w-75">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input placeholder="Search" @keyup.enter="onTextSearch" v-model="searchText" />
              </b-input-group>
            </b-form>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <span class="mr-1">Locale</span>
            <b-form-select id="locale" v-model="selectedLocale" size="md" class="mr-1" @change="onLocaleChange">
              <option v-for="opt in availableLocales" :value="opt" :key="'locale_' + opt.locale">
                {{ opt.locale }}
              </option>
            </b-form-select>
          </div>
        </div>
        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :current-page="currentPage"
          :items="contents"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="false"
          :fields="columns"
        >
          <template #cell($selection)="data">
            <b-form-checkbox @change="onSelectContent($event, data.item)" :checked="isContentSelected(data.item)" />
          </template>
          <template #cell()="data">
            <span v-b-tooltip.hover :title="data.item.id" v-if="data.field.key != 'id' && data.field.key != 'locale'">{{ data.value }}</span>
            <span v-else>{{ data.value }}</span>
          </template>
        </b-table>
        <!-- pagination -->
        <div class="d-flex justify-content-center mt-1">
          <b-pagination
            v-if="totalPages > 1"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { contentTypes, contents } from '@/api/index'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ContentSelector',
  props: ['model', 'selected', 'maxSelection', 'locale'],
  components: {},
  data() {
    return {
      contents: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      searchText: '',
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      totalPages: 1,
      visible: false,
      selectedItems: [],
      selectedLocale: {},
    }
  },
  watch: {
    selected: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.selectedItems = [...newVal]
      },
    },
  },
  computed: {
    ...mapGetters(['Settings']),
    availableLocales() {
      return this.Settings.locales
    },
    defaultLocale() {
      return this.$_.find(this.Settings.locales, l => l.isDefault === true)
    },
    columns() {
      const titleFields = this.$_.filter(this.model.attributes, function(a) {
        return a.isTitle
      })

      let cols = [
        { key: '$selection', tdClass: 'selection-cell', label: '' },
        {
          key: 'id',
          label: 'ID',
          value: 'id',
          type: 'system',
          sortable: true,
        },
        {
          key: 'locale',
          label: 'Locale',
          value: 'locale',
          type: 'system',
          sortable: false,
        },
      ]

      if (titleFields && titleFields.length > 0) {
        return [
          cols[0],
          ...titleFields.map(f => ({
            key: 'attributes.' + f.key,
            label: f.label,
            value: 'attributes.' + f.key,
            type: f.type,
            sortable: false,
          })),
          cols[2],
        ]
      }
      return cols
    },
  },
  methods: {
    async fetchData() {
      const self = this
      const query = self.getFilters()

      await contents.getContents(self.projectName, self.model.key, query).then(res => {
        self.contents = res.data.data
        self.totalRows = res.data.metadata.count
        self.totalPages = res.data.metadata.totalPages
        self.currentPage = res.data.metadata.currentPage == 0 ? 1 : res.data.metadata.currentPage
      })
    },
    getFilters() {
      let filters = {
        page: this.currentPage,
        size: this.perPage,
        sort: `${this.sortBy}|${this.sortDirection}`,
        locale: this.selectedLocale.locale,
        search: this.searchText,
      }
      if (this.model.enableStageMode) {
        filters.stage = 'all'
      }

      return filters
    },
    onTextSearch(e) {
      this.currentPage = 1
      this.fetchData()
    },
    onPageChanged(e) {
      this.currentPage = e
      this.fetchData()
    },
    onLocaleChange(e) {
      this.currentPage = 1
      this.fetchData()
    },
    onSelectContent(val, item) {
      let index = this.$_.findIndex(this.selectedItems, function(c) {
        return c.id == item.id
      })

      if (val) {
        if (index < 0) {
          this.selectedItems.push(item)
        }
      } else {
        if (index >= 0) {
          this.selectedItems.splice(index, 1)
        }
      }
    },
    isContentSelected(item) {
      let index = this.$_.findIndex(this.selectedItems, function(c) {
        return c.id == item.id
      })
      return index >= 0
    },
    onOk() {
      this.$emit('selection-done', this.selectedItems)
      this.visible = false
    },
    onHidden() {
      this.currentPage = 1
      this.perPage = 10
      this.visible = false
    },
    onShow() {
      this.selectedLocale = this.$_.find(this.Settings.locales, l => l.locale === this.locale)
      this.fetchData()
    },
  },
}
</script>
