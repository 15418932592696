<template>
  <PageTitle :title="model.name" :subtitle="model.description">
    <template #after-title>
      <b-badge variant="light-dark" class="align-self-center font-weight-normal font-small-1" id="key">
        {{ model.key }}
      </b-badge>
      <b-badge variant="light-dark" class="font-weight-normal font-small-1" id="id" style="margin-left:0.3rem;">
        {{ model.id }}
      </b-badge>
    </template>
  </PageTitle>
</template>
<script>
export default {
  name: 'ContentPageTitle',
  props: ['model'],
}
</script>
