import Vue from 'vue'
import store from '../../store'

const helpers = {
  findModel(id) {
    const model = store.getters.Models.find(f => f.id == id)
    return model
  },
  getModels(id) {
    return store.getters.Models
  },
  findComponent(id) {
    const component = store.getters.Components.find(f => f.id == id)
    return component
  },
  getComponents(id) {
    return store.getters.Components
  },
}

export default function contentTypesHelper() {
  Vue.prototype.$ContentTypes = helpers
}
