<template> </template>
<script>
export default {
  name: 'JsonFieldSettings',
  props: ['value'],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
}
</script>
