<template>
  <div>
    <b-form-group>
      <validation-provider #default="{ errors }" :name="field.label" :rules="rules">
        <vue-simplemde
          v-if="field.editor == 'markdown'"
          :ref="refMdeName"
          :value="value"
          :configs="mdEditorOptions"
          :readonly="field.readOnly"
          :autoinit="true"
          @update:modelValue="onMDInput"
          @blur="onMDBlur"
          v-observe-visibility="mdeVisibilityChanged"
          :state="errors.length > 0 ? false : null"
        />
        <textarea :id="'tamde_' + field.key + field.locale" :value="editorText" v-if="field.editor == 'markdown'" style="display:none" />
        <quill-editor
          v-if="field.editor == 'html'"
          :ref="refHtmlName"
          :content="value"
          :options="htmlEditorOptions"
          :readonly="field.readOnly"
          @blur="onHtmlBlur($event)"
          @change="onHtmlChange($event)"
          :state="errors.length > 0 ? false : null"
        >
          <div id="toolbar" slot="toolbar">
            <!--  Custom Buttons <button class="w-auto btn btn-primary" @click="customButtonClick">Click here</button> -->
          </div>
        </quill-editor>
        <textarea :id="'tahtml_' + field.key + field.locale" :value="editorText" v-if="field.editor == 'html'" style="display:none" />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import VueSimplemde from 'vue-simplemde'
import { quillEditor } from 'vue-quill-editor'
import { textLengthRange } from '../Validators/FieldValidators'

export default {
  name: 'RichtextField',
  components: {
    VueSimplemde,
    quillEditor,
  },
  props: ['field', 'value', 'locale', 'errors', 'index'],
  data() {
    return {
      internal: '',
      editorText: '',
      htmlEditorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['clean'],
            ['link'], //, 'image', 'video'],
          ],
        },
      },
      mdEditorOptions: {
        status: false,
      },
    }
  },
  computed: {
    refMdeName() {
      return '_mde_' + this.field.key + this.locale
    },
    refHtmlName() {
      return '_html_' + this.field.key + this.locale
    },
    rules() {
      const ruleSet = []

      if (this.field.required) {
        ruleSet.push('required')
      }

      if (this.field.min > 0 || this.field.max > 0) {
        ruleSet.push(`text_length_range:${this.field.min},${this.field.max}`)
      }

      return ruleSet.join('|')
    },
  },
  watch: {
    value: {
      immediate: false,
      handler(val) {
        this.internal = val
      },
    },
    // internal: {
    //   handler(val) {
    //     this.$emit('input', this.internal)
    //   },
    // },
  },
  mounted() {
    if (this.field.editor == 'markdown') {
      this.editorText = this.value
    } else {
      this.editorText = this.$refs[this.refHtmlName].quill.root.innerText
    }
  },
  methods: {
    onMDInput(event) {
      this.editorText = event
    },
    onMDBlur(event) {
      this.$emit('input', event)
    },
    onHtmlChange(event) {
      this.editorText = this.$refs[this.refHtmlName].quill.root.innerText
    },
    onHtmlBlur(event) {
      this.$emit('input', this.$refs[this.refHtmlName].quill.root.innerHTML)
    },
    mdeVisibilityChanged(event) {
      if (this.value) {
        this.$refs[this.refMdeName].simplemde.value(this.value)
        this.$refs[this.refMdeName].simplemde.codemirror.refresh()
      }
    },
  },
}
</script>
<style>
@import '~simplemde/dist/simplemde.min.css';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
.ql-container {
  min-height: inherit;
}
.ql-editor {
  min-height: 250px;
}
</style>
