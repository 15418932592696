import { accounts } from '@/api/index'
import moment from 'moment'

const state = {
  token: null,
  me: null,
}
const getters = {
  isAuthenticated: state => {
    if (state.token && state.token.expires_at) {
      let exp = moment.utc(state.token.expires_at)

      if (exp.diff(moment.utc()) < 0) {
        return false
      }

      return true
    }

    return false
  },
  Token: state => state.token,
  Me: state => state.me,
}

const actions = {
  async LogIn({ commit }, credentials) {
    await accounts.login(credentials).then(res => {
      commit('setToken', res.data)
    })
  },
  async SetAuthToken({ commit }, data) {
    commit('setToken', data)
  },
  async LoadMe({ commit }, credentials) {
    await accounts.getMe().then(res => {
      commit('setMe', res.data)
    })
  },
  async LogOut({ commit }) {
    commit('logout', null)
    commit('setMe', null)
    commit('resetEnvironment', null)
  },
}

const mutations = {
  setToken(state, token) {
    state.token = token
  },
  setMe(state, me) {
    state.me = me
  },
  logout(state, token) {
    state.token = token
  },
}

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
