<template>
  <div>
    <div v-if="$acl.canOneOf(readContentsPermission)">
      <page-loader v-if="loading" type="list" :fluid="true" />
      <portal to="nav-bar-left-column">
        <ContentPageTitle :model="model" />
      </portal>
      <portal to="nav-bar-right-column" v-if="!loading">
        <router-link :to="getNewContentRoute()" class="btn btn-primary" v-if="$acl.canOneOf(createContentsPermission)"
          ><feather-icon icon="PlusIcon" class="mr-25" /> Create item</router-link
        >
      </portal>

      <b-card no-body class="mt-1" v-if="!loading">
        <b-card-body>
          <div class="d-flex justify-content-between align-items-center flex-wrap">
            <div class="flex-fill">
              <b-form inline @submit.prevent>
                <b-input-group class="w-25">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input placeholder="Search" @keyup.enter="onTextSearch" v-model="searchText" />
                </b-input-group>
                <filters-panel :model="model" class="ml-1" v-if="1 == 2" />
              </b-form>
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <span class="mr-1">Locale</span>
              <b-form-select id="locale" v-model="selectedLocale" size="md" class="mr-1" @change="onLocaleChange">
                <option v-for="opt in availableLocales" :value="opt" :key="'locale_' + opt.locale">
                  {{ opt.locale }}
                </option>
              </b-form-select>
              <!-- <feather-icon icon="SettingsIcon" size="18" class="cursor-pointer d-sm-block d-none mr-1" /> -->
              <ColumnSelector :model="model" @update="prepareColumns" />
            </div>
          </div>
        </b-card-body>
        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :current-page="currentPage"
          :items="contents"
          :fields="visibleColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="false"
          sortKey="sorter"
          no-local-sorting
          @sort-changed="onSortChanged"
        >
          <template #cell(stage)="data">
            <b-badge pill v-if="data.value == 'published'" variant="primary">
              Published
            </b-badge>
            <b-badge pill v-if="data.value == 'unpublished'" variant="warning">
              Unpublished
            </b-badge>
          </template>
          <template #cell(locales)="data">
            <small v-if="data.value && data.value.length > 0">
              <span class="font-weight-bold">{{ data.item.locale }}</span
              >, {{ data.value.map(m => m.locale).join(', ') }}
            </small>
            <small v-else>
              <span class="font-weight-bold">{{ data.item.locale }}</span>
            </small>
          </template>
          <template #head($selection)="data">
            <b-form-checkbox @change="onSelectAllContents" />
          </template>
          <template #cell(id)="data">
            <router-link :to="getEditContentPath(data.value)">{{ data.value }}</router-link>
          </template>
          <template #cell()="data">
            <div v-if="data.field.formatter" v-html="data.value"></div>
            <div v-else>{{ data.value }}</div>
          </template>
          <template #cell($selection)="data">
            <b-form-checkbox @change="onSelectContent($event, data.item.id)" :checked="isContentSelected(data.item.id)" />
          </template>
          <template #cell($actions)="data">
            <b-button @click="$router.push(getEditContentPath(data.item.id))" variant="outline-secondary" size="sm" class="btn-icon rounded-circle">
              <feather-icon icon="Edit2Icon" />
            </b-button>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="perPageSelect" class="text-nowrap mb-md-0 mr-1">
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" @change="onRowsPerPageChanged" />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-if="totalPages > 1"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @change="onPageChanged"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <div v-else>
      <forbidden />
    </div>
  </div>
</template>
<script>
import { contents } from '@/api/index'
import ColumnSelector from './ColumnSelector.vue'
import columns from '../columns'
import { mapGetters } from 'vuex'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import FiltersPanel from './FiltersPanel.vue'
import PageableComponent from '@/mixins/PageableComponent'
import ContentPageTitle from './ContentPageTitle.vue'

export default {
  name: 'ContentsList',
  mixins: [PageableComponent],
  props: ['model'],
  components: {
    ColumnSelector,
    FeatherIcon,
    FiltersPanel,
    ContentPageTitle,
  },
  data() {
    return {
      visibleColumns: [],
      contents: [],
      selectedLocale: {},
      selectedContents: [],
      totalRows: 1,
      currentPage: 1,
      totalPages: 1,
      loading: false,
    }
  },
  mounted() {
    this.selectedLocale = this.defaultLocale
  },
  watch: {
    model: {
      immediate: true,
      handler(val) {
        if (val) {
          this.prepareColumns()
        }
      },
    },
    $route(to, from) {
      this.fetchData(to.query)
    },
  },
  computed: {
    ...mapGetters(['Project', 'Settings']),
    readContentsPermission() {
      return [`contents.${this.typeId}.read`, `contents.*.read`]
    },
    createContentsPermission() {
      return [`contents.${this.typeId}.create`, `contents.*.create`]
    },
    type() {
      return this.$route.meta.type
    },
    typeId() {
      return this.$route.meta.typeId
    },
    availableLocales() {
      return this.Settings.locales
    },
    defaultLocale() {
      return this.$_.find(this.Settings.locales, l => l.isDefault === true)
    },
  },
  created() {
    let self = this
    self.fetchData(self.$route.query)
  },
  methods: {
    async fetchData(query) {
      const self = this
      self.loading = true

      query['resolve'] = 'media,mediagallery'

      if (self.model.enableStageMode) {
        query['stage'] = 'all'
      }

      await contents.getContents(self.projectName, self.type, query).then(res => {
        self.contents = res.data.data
        self.totalRows = res.data.metadata.count
        self.totalPages = res.data.metadata.totalPages
        self.currentPage = res.data.metadata.currentPage == 0 ? 1 : res.data.metadata.currentPage
        self.loading = false
      })
    },
    prepareColumns(e) {
      this.visibleColumns = [
        ,
        // { key: '$selection', tdClass: 'selection-cell', label: '' },
        ...columns.getVisibleColumns(this.model),
        { key: '$actions', tdClass: 'action-cell', label: '' },
      ]
    },
    onTextSearch(e) {
      var filters = this.getCurrentFilters()
      filters.page = 1
      this.$router.push({ query: filters, project: this.projectName })
    },
    onLocaleChange(e) {
      var filters = this.getCurrentFilters()
      filters.page = 1
      this.$router.push({ query: filters, project: this.projectName })
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy
      this.sortDesc = e.sortDesc
      this.sortDirection = e.sortDesc ? 'desc' : 'asc'

      if (e.sortBy.startsWith('attributes.')) {
        this.sortFilterBy = e.sortBy.substring('attributes.'.length)
      } else {
        if (!e.sortBy.startsWith('_')) {
          this.sortFilterBy = '_' + e.sortBy
        } else {
          this.sortFilterBy = e.sortBy
        }
      }

      var filters = this.getCurrentFilters()
      this.$router.push({ query: filters, project: this.projectName })
    },
    onPageChanged(e) {
      var filters = this.getCurrentFilters()
      filters.page = e
      this.$router.push({ query: filters, project: this.projectName })
    },
    onRowsPerPageChanged(e) {
      this.perPage = e
      let filters = this.getCurrentFilters()
      filters.page = 1
      this.$router.push({ query: filters, project: this.projectName })
    },
    onSelectAllContents(val) {
      if (val) {
        this.selectedContents = this.contents.map(c => c.id)
      } else {
        this.selectedContents = []
      }
    },
    onSelectContent(val, id) {
      let index = this.$_.findIndex(this.selectedContents, function(c) {
        return c == id
      })

      if (val) {
        if (index < 0) {
          this.selectedContents.push(id)
        }
      } else {
        if (index >= 0) {
          this.selectedContents.splice(index, 1)
        }
      }
    },
    isContentSelected(id) {
      let index = this.$_.findIndex(this.selectedContents, function(c) {
        return c == id
      })

      return index >= 0
    },
    getCurrentFilters() {
      let filters = {
        locale: this.selectedLocale.locale,
        page: this.currentPage,
        size: this.perPage,
        search: this.searchText,
      }

      filters.sort = `${this.sortFilterBy}|${this.sortDirection}`

      return filters
    },
    getNewContentRoute() {
      return {
        name: 'atlas_new_' + this.type,
        query: { locale: this.selectedLocale.locale },
        params: { project: this.projectName },
      }
    },
    getEditContentPath(id) {
      return {
        name: 'atlas_edit_' + this.type,
        params: { id: id, project: this.projectName },
      }
    },
  },
}
</script>
