import http from '@/http/index'

const mediaLibrary = {
  async getFolders(project) {
    return await http.get(`${project}/media-library/folders`)
  },

  async moveFolder(project, data) {
    return await http.post(`${project}/media-library/folders/move`, data)
  },

  async createFolder(project, data) {
    return await http.post(`${project}/media-library/folders`, data)
  },

  async deleteFolder(project, id) {
    return await http.delete(`${project}/media-library/folders`, { params: { folder: id } })
  },

  async renameFolder(project, data) {
    return await http.post(`${project}/media-library/folders/rename`, data)
  },

  async getMedia(project, id) {
    return await http.get(`${project}/media-library/media/${id}`)
  },

  async getMedias(project, query) {
    return await http.get(`${project}/media-library/media`, { params: { ...query } })
  },

  async deleteMedia(project, id) {
    return await http.delete(`${project}/media-library/media/${id}`)
  },

  async uploadImage(project, data) {
    return await http.post(`${project}/media-library/media/upload/image`, data)
  },
}

export default mediaLibrary
