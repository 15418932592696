import store from '../store'

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Index.vue'),
    meta: { layout: 'full', requiresAuth: true, requiresProject: false },
  },
  {
    path: '/app/projects',
    name: 'Projects',
    component: () => import('@/views/Account/Projects.vue'),
    meta: {
      layout: 'full',
      requiresAuth: true,
    },
  },
  {
    path: '/app/:project/dashboard',
    name: 'Dashboard',
    meta: { layout: '', requiresAuth: true, requiresProject: true, actionBar: false },
    component: () => import(/* webpackChunkName: "main" */ '@/views/Project/Dashboard/Dashboard.vue'),
  },
  {
    path: '/app/:project/settings',
    name: 'Settings',
    redirect: '/app/:project/settings/general',
    meta: {
      layout: '',
      requiresAuth: true,
      requiresProject: true,
      actionBar: true,
    },
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Project/Settings/Index.vue'),
    children: [
      {
        path: 'general',
        name: 'SettingsGeneral',
        meta: {
          requiresProject: true,
          containerFluid: false,
          actionBar: true,
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Project/Settings/General/Index.vue'),
      },
      {
        path: 'memberships',
        name: 'SettingsMemberships',
        meta: {
          requiresProject: true,
          containerFluid: false,
          actionBar: true,
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Project/Settings/Memberships/Index.vue'),
      },
      {
        path: 'roles',
        name: 'SettingsRoles',
        meta: {
          requiresProject: true,
          containerFluid: false,
          actionBar: true,
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Project/Settings/Roles/Index.vue'),
      },
      {
        path: 'tokens',
        name: 'SettingsApiTokens',
        meta: {
          requiresProject: true,
          containerFluid: false,
          actionBar: true,
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Project/Settings/ApiTokens/Index.vue'),
      },
      {
        path: 'webhooks',
        name: 'SettingsWebhooks',
        meta: {
          requiresProject: true,
          containerFluid: false,
          actionBar: true,
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Project/Settings/Webhooks/Index.vue'),
      },
      {
        path: 'billings',
        name: 'SettingsBilling',
        meta: {
          requiresProject: true,
          containerFluid: false,
          actionBar: true,
        },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Project/Settings/Billing/Index.vue'),
      },
    ],
  },
  {
    path: '/app/:project/users',
    name: 'Users',
    meta: {
      layout: '',
      requiresAuth: true,
      requiresProject: true,
      actionBar: true,
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/Project/Users/Index.vue'),
  },
  {
    path: '/app/:project/users/new',
    name: 'NewUser',
    meta: {
      layout: '',
      requiresAuth: true,
      requiresProject: true,
      actionBar: true,
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/Project/Users/New.vue'),
  },
  {
    path: '/app/:project/users/:id',
    name: 'EditUser',
    meta: {
      layout: '',
      requiresAuth: true,
      requiresProject: true,
      actionBar: true,
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/Project/Users/Edit.vue'),
  },
  {
    path: '/app/:project/roles',
    name: 'Roles',
    meta: {
      layout: '',
      requiresAuth: true,
      requiresProject: true,
      actionBar: true,
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/Project/Roles/Index.vue'),
  },
  {
    path: '/app/:project/model-builder',
    name: 'ModelBuilder',
    meta: {
      layout: '',
      requiresAuth: true,
      requiresProject: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'model-builder-application',
      actionBar: false,
    },
    component: () => import(/* webpackChunkName: "main" */ '@/views/Project/ModelBuilder/Index.vue'),
  },
  {
    path: '/app/:project/model-builder/system',
    name: 'SystemModelBuilder',
    meta: { layout: '', requiresAuth: true, requiresProject: true, contentRenderer: 'sidebar-left', contentClass: 'model-builder-application' },
    component: () => import(/* webpackChunkName: "main" */ '@/views/Project/ModelBuilder/System.vue'),
  },
  {
    path: '/app/:project/media-library',
    name: 'MediaLibrary',
    meta: {
      layout: '',
      requiresAuth: true,
      requiresProject: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'media-library-application',
      actionBar: false,
    },
    component: () => import(/* webpackChunkName: "media" */ '@/views/Project/MediaLibrary/Index.vue'),
  },
  {
    path: '/app/:project/graphql/playground',
    name: 'GraphQlPlayground',
    meta: { layout: '', requiresAuth: true, requiresProject: true, contentClass: 'graphql-application', actionBar: false },
    component: () => import(/* webpackChunkName: "graphql" */ '@/views/GraphQL/index.vue'),
  },
  {
    path: '/app/profile',
    name: 'ProfileSettings',
    meta: { layout: 'full', requiresAuth: true, requiresProject: false },
    component: () => import(/* webpackChunkName: "main" */ '@/views/Account/Profile.vue'),
  },

  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'full', requiresProject: false },
    component: () => import(/* webpackChunkName: "public" */ '@/views/Login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: { layout: 'full', requiresProject: false },
    component: () => import(/* webpackChunkName: "public" */ '@/views/Registration/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: { layout: 'full', requiresProject: false },
    component: () => import(/* webpackChunkName: "public" */ '@/views/Registration/ResetPassword.vue'),
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    meta: { layout: 'full', requiresProject: false },
    component: () => import(/* webpackChunkName: "public" */ '@/views/Registration/SignUp.vue'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Errors/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next()
      } else {
        next('/login')
      }
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
