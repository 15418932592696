import http from '@/http/index'

const webhooks = {
  async createWebhook(project, data) {
    return await http.post(`${project}/webhooks`, data)
  },
  async getWebhooks(project) {
    return await http.get(`${project}/webhooks`)
  },

  async updateWebhook(project, id, data) {
    return await http.put(`${project}/webhooks/${id}`, data)
  },

  async deleteWebhook(project, id) {
    return await http.delete(`${project}/webhooks/${id}`)
  },
}

export default webhooks
