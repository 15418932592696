<template>
  <div class="d-flex align-items-center">
    <span v-if="formErrors" class="mr-1 d-flex align-items-center text-danger"
      ><feather-icon icon="AlertCircleIcon" class="m-0 p-0 ml-1 mr-25" />There are errors on the page that prevent to save changes</span
    >
    <b-link size="xs" class="font-weight-normal text-secondary mr-1 font-small-2" :to="{ name: `atlas_list_${model.key}` }" v-if="!model.isSingle">
      <FeatherIcon icon="CornerDownLeftIcon" class="mr-25" size="12" />
      Back to list</b-link
    >
    <b-button :variant="publishButtonVariant" @click="stagesModal = true" v-if="isPublishStagesVisibile" class="mr-1" :disabled="publishButtonDisabled">
      <feather-icon icon="UploadIcon" size="14" />
      <span class="ml-50">Publish</span>
    </b-button>
    <b-button
      :variant="publishButtonVariant"
      @click="$emit('change-status', { id: contents[0].id, stage: 'published' })"
      v-if="isPublishContentVisibile"
      class="mr-1"
      :disabled="publishButtonDisabled"
    >
      <feather-icon icon="UploadIcon" size="14" />
      <span class="ml-50">Publish</span>
    </b-button>
    <b-button
      :variant="unpublishButtonVariant"
      @click="$emit('change-status', { id: contents[0].id, stage: 'unpublished' })"
      v-if="isUnpublishContentVisibile"
      class="mr-1"
      :disabled="publishButtonDisabled"
    >
      <feather-icon icon="DownloadIcon" size="14" />
      <span class="ml-50">Unpublish</span>
    </b-button>
    <b-button :variant="saveButtonVariant" :disabled="saveButtonDisabled" @click="$emit('save')" size="xs">Save</b-button>
    <b-dropdown variant="link" no-caret toggle-class="p-0" right class="ml-1">
      <template #button-content>
        <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body" />
      </template>
      <b-dropdown-item @click="$emit('duplicate')" v-if="$acl.canOneOf([`contents.${model.id}.duplicate`, `contents.*.duplicate`])">
        <feather-icon icon="CopyIcon" class="" />
        <span class="align-middle ml-50">Duplicate</span>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item @click="onConfirmDeleteAll" v-if="$acl.canOneOf([`contents.${model.id}.delete`, `contents.*.delete`])">
        <feather-icon icon="TrashIcon" class="text-danger" />
        <span class="align-middle ml-50 text-danger">Delete</span>
      </b-dropdown-item>
      <b-dropdown-item @click="onConfirmDeleteSingle" v-if="$acl.canOneOf([`contents.${model.id}.delete`, `contents.*.delete`])">
        <feather-icon icon="TrashIcon" class="text-danger" />
        <span class="align-middle ml-50 text-danger">Delete this language</span>
      </b-dropdown-item>
    </b-dropdown>

    <b-modal id="deleteConfirmModal" v-model="confirmDeleteModal" hide-footer :title="model.name" size="md" centered hide-header @hidden="resetDeleteModal">
      <div class="d-block text-left mt-2">
        <h4>Delete Content</h4>
        <p class="h6 font-weight-normal" v-if="deleteType == 'all'">Do you want to delete the content?</p>
        <p class="h6 font-weight-normal" v-if="deleteType == 'single'">
          Do you want to delete the content language <strong>{{ content ? cultures.getCultureByName(content.locale).isoCode3.toUpperCase() : '' }}</strong
          >?
        </p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="$bvModal.hide('deleteConfirmModal')">Cancel</b-button>
        <b-button class="" variant="danger" @click="$emit('delete', { deleteType: deleteType })">Delete</b-button>
      </div>
    </b-modal>
    <b-modal id="publishModal" v-model="stagesModal" hide-footer :title="model.name" size="lg" centered hide-header @hidden="resetStagesModal">
      <div class="d-block text-left mt-2">
        <h4>Publish {{ model.name }}</h4>
        <hr />
        <p>
          Please select content to be published or deselect those to unpublish
        </p>
        <div class="mt-2">
          <b-row>
            <b-col cols="12" md="6" v-for="(content, index) in contentStages" :key="`publish_content_${content.id}_${index}`" class="mb-1">
              <b-form-checkbox class="custom-control-success mt-0" switch inline v-model="content.stage" value="published" unchecked-value="unpublished">
                <FlagIcon :country-code="cultures.getCultureByName(content.locale).isoCountryCode2" />
                {{ cultures.getCultureByName(content.locale).displayName }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="$bvModal.hide('publishModal')">Cancel</b-button>
        <b-button class="" variant="primary" @click="onSaveStages">Confirm</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import FlagIcon from '@/components/FlagIcon/Index.vue'

export default {
  name: 'ContentEditActions',
  props: ['model', 'isEmptyModel', 'cultures', 'contents', 'content', 'contentStages', 'isDirty', 'formErrors'],
  components: {
    FlagIcon,
  },
  data() {
    return {
      deleteType: 'single',
      confirmDeleteModal: false,
      stagesModal: false,
    }
  },
  computed: {
    isPublishStagesVisibile() {
      return this.model.enableStageMode && this.contents.length > 1
    },
    publishButtonDisabled() {
      return this.isDirty || !this.$acl.canOneOf([`contents.${this.model.id}.publish`, `contents.*.publish`])
    },
    isPublishContentVisibile() {
      return this.model.enableStageMode && (this.contents.length == 1 || !this.model.localizable) && this.contents[0].stage == 'unpublished'
    },
    isUnpublishContentVisibile() {
      return this.model.enableStageMode && (this.contents.length == 1 || !this.model.localizable) && this.contents[0].stage == 'published'
    },
    publishButtonVariant() {
      if (this.saveButtonDisabled) {
        return 'outline-secondary'
      }

      return 'outline-success'
    },
    unpublishButtonVariant() {
      if (this.saveButtonDisabled) {
        return 'outline-secondary'
      }

      return 'outline-warning'
    },
    saveButtonVariant() {
      if (this.saveButtonDisabled) {
        return 'outline-secondary'
      }

      return 'primary'
    },
    saveButtonDisabled() {
      return !(this.contents.length > 0 && this.isDirty && !this.isEmptyModel) || !this.$acl.canOneOf([`contents.${this.model.id}.update`, `contents.*.update`])
    },
  },
  methods: {
    onConfirmDeleteAll() {
      this.deleteType = 'all'
      this.confirmDeleteModal = true
    },
    onConfirmDeleteSingle() {
      this.deleteType = 'single'
      this.confirmDeleteModal = true
    },
    async onSaveStages() {
      const self = this
      for (let index = 0; index < self.contentStages.length; index++) {
        await self.$emit('change-status', { id: self.contentStages[index].id, stage: self.contentStages[index].stage })
      }
      self.stagesModal = false
    },
    resetDeleteModal() {
      this.deleteType = 'single'
      this.confirmDeleteModal = false
    },
    resetStagesModal() {
      //this.prepareContentStages()
    },
  },
}
</script>
