<template>
  <b-alert v-height-fade.appear :variant="variant" class="mb-1" :show="(errors && errors.length > 0) || genericError">
    <div :class="css">
      <div v-if="showTitle"><feather-icon icon="InfoIcon" class="mr-50" /> {{ title }}</div>
      <div>
        <ul class="mt-1 ml-0" v-if="!genericError">
          <li v-for="(error, index) in errors" :key="index" class="font-weight-normal">{{ getDisplayMessage(error) }}</li>
        </ul>
        <ul class="mt-1 ml-0" v-else>
          <li class="font-weight-normal">
            Oops! Something went wrong!<br />Help us improve your experience by sending an email to <a href="mailto:support@atlascms.io">support</a>.
          </li>
        </ul>
      </div>
    </div>
  </b-alert>
</template>
<script>
import { heightFade } from '@core/directives/animations'
export default {
  name: 'FormAlert',
  props: {
    css: {
      type: String,
      default: 'alert-body',
    },
    variant: {
      type: String,
      default: 'danger',
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Error!',
    },
    errors: {
      type: Array,
      default: null,
    },
    genericError: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    'height-fade': heightFade,
  },
  methods: {
    getDisplayMessage(error) {
      let parts = []

      if (error.propertyName && error.propertyName !== '') {
        parts.push(`Field ${error.propertyName}: `)
      }

      parts.push(error.message)

      return parts.join('')
    },
  },
}
</script>
