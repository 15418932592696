<template>
  <div>
    <b-form-group class="w-25">
      <validation-provider #default="{ errors }" :name="field.label" :rules="rules">
        <div class="d-flex align-items-center">
          <v-swatches
            :value="value"
            show-fallback
            shapes="circles"
            fallback-input-type="color"
            popover-x="right"
            :disabled="field.readOnly"
            @input="$emit('input', $event)"
          />
          <b-form-input
            :id="field.label"
            class="ml-1"
            size="lg"
            :value="value"
            :state="errors.length > 0 ? false : null"
            :readonly="field.readOnly"
            @input="$emit('input', $event)"
          />
        </div>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'

export default {
  name: 'ColorPickerField',
  components: {
    VSwatches,
  },
  props: ['field', 'value', 'errors', 'index'],
  data() {
    return {
      internal: '',
    }
  },
  computed: {
    rules() {
      const ruleSet = []

      if (this.field.required) {
        ruleSet.push('required')
      }
      return ruleSet.join('|')
    },
  },
  mounted() {
    // extend('length', minChars, { paramNames })
  },
  //   watch: {
  //     value: {
  //       immediate: true,
  //       handler(val) {
  //         this.internal = val
  //       },
  //     },
  //     internal: {
  //       handler(val) {
  //         this.$emit('input', this.internal)
  //       },
  //     },
  //   },

  methods: {},
}
</script>
