import http from '@/http/index'

const apitokens = {
  async getApiTokens(project, query) {
    return await http.get(`${project}/api-tokens`, { params: query })
  },
  async getApiToken(project, id) {
    return await http.get(`${project}/api-tokens/${id}`)
  },
  async createApiToken(project, data) {
    return await http.post(`${project}/api-tokens`, data)
  },
  async getPermissionSet(project) {
    return await http.get(`${project}/api-tokens/permissions`)
  },
  async updateApiToken(project, id, data) {
    return await http.put(`${project}/api-tokens/${id}`, data)
  },
}

export default apitokens
