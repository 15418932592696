<template> </template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: ['value'],
  components: { ToastificationContent },
  data() {
    return {}
  },
  watch: {
    value(val) {
      if (val) {
        this.showNotification(this.$_.cloneDeep(val))
      }
    },
  },
  methods: {
    showNotification(notification) {
      this.$emit('input', null)

      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: notification.title,
            icon: notification.icon,
            text: notification.text,
            details: notification.details,
            variant: notification.variant ? notification.variant : 'primary',
          },
        },
        {
          timeout: notification.timeout ? notification.timeout : 2500,
          position: notification.position ? notification.position : 'top-center',
        }
      )
    },
  },
}
</script>
