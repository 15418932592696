<template>
  <div>
    <LeafletMapField :value="value" :field="field" @input="$emit('input', $event)" />
    <div>
      <span class="font-small-3" v-if="value"
        ><em><strong>Lat:</strong> {{ value.lat }}, <strong>Lng:</strong> {{ value.lng }}</em></span
      >
    </div>
  </div>
</template>
<script>
import LeafletMapField from './LeafletMapField'
export default {
  name: 'MapField',
  props: ['field', 'component', 'value', 'parentKey', 'locale', 'errors', 'index'],
  components: {
    LeafletMapField,
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {},
}
</script>
