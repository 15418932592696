<template>
  <span :class="flagClass" style="width:40;height:30" v-if="CountryCode" />
</template>
<script>
require('flag-icon-css/sass/flag-icons.scss')
export default {
  name: 'FlagIcon',
  props: ['CountryCode', 'BadgeBariant'],
  computed: {
    flagClass() {
      return `flag-icon flag-icon-${this.CountryCode.toLowerCase()}`
    },
  },
}
</script>
