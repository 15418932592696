<template>
  <div>
    <section-divider text="Tags Settings" />
    <b-form-group label="Min/Max selection">
      <b-row>
        <b-col md="2">
          <b-form-input id="min" placeholder="Min" size="md" v-model="min" />
        </b-col>
        <b-col md="2">
          <b-form-input id="max" placeholder="Max" size="md" v-model="item.max" />
        </b-col>
      </b-row>
      <b-form-text>Min and Max number of tags required. 0 means no limits</b-form-text>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: 'TagFieldSettings',
  props: ['value'],
  data() {
    return {}
  },
  watch: {},
  computed: {
    min: {
      get() {
        return this.item.min
      },
      set(newValue) {
        if (newValue > 0) {
          this.item.required = true
          this.update('required', this.item.required)
        }
        this.item.min = newValue
        this.update('min', this.item.min)
      },
    },
    item() {
      return this.value ? this.value : { name: '' }
    },
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.item, [key]: value })
    },
  },
}
</script>
