<template>
  <div>
    <b-button variant="outline-primary" type="submit" class="mr-1" @click.prevent="visible = true">
      <feather-icon icon="PlusIcon" class="mr-25" /> Add Field
    </b-button>
    <b-modal
      :no-close-on-backdrop="false"
      :no-close-on-esc="false"
      v-model="visible"
      hide-header
      centered
      size="lg"
      cancel-variant="outline-secondary"
      ok-title="Ok"
      cancel-title="Close"
      @ok="validateForm"
      @hidden="reset"
    >
      <div v-if="selected === null" class="w-100">
        <b-card-body>
          <b-row>
            <b-col md="3" v-for="field in fields" :key="field.settings.type" class="cursor-pointer" @click="select(field)">
              <div class="text-center mb-2">
                <b-avatar size="80" alt="" class="mb-1 field-avatar">
                  <img :src="imageUrl(field)" width="30%" height="40%" />
                </b-avatar>
                <h5 class="mb-0">{{ field.name }}</h5>
                <small class="text-muted">{{ field.hint }}</small>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
      <div v-if="selected !== null">
        <field-settings-editor v-model="field" :model="model" ref="editor" :auto-generate-key="true" />
      </div>
      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex justify-content-between w-100">
          <div class="justify-content-start ml-1">
            <b-button variant="outline-primary" type="submit" class="" @click="selected = null" v-if="selected !== null">
              Back
            </b-button>
          </div>
          <div class="mr-2">
            <b-button variant="outline-secondary" type="submit" class="mr-50" @click="cancel()">
              Cancel
            </b-button>
            <b-button variant="primary" type="submit" class="mr-50" @click="ok()" v-if="selected !== null">
              Save
            </b-button>
            <b-button variant="primary" type="submit" class="mr-0" @click="okOneMore($event)" v-if="selected !== null">
              Save & Add Another Field
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import FieldSettingsEditor from './FieldSettingsEditor.vue'
import { fields } from './manifest'
export default {
  name: 'NewField',
  props: ['model'],
  components: {
    FieldSettingsEditor,
  },
  data() {
    return {
      fields,
      selected: null,
      visible: false,
      default: {
        key: '',
        help: '',
        label: '',
        order: 0,
        fieldSet: '__default',
        type: '',
        hidden: false,
        required: false,
      },
      field: {},
    }
  },
  computed: {},
  methods: {
    imageUrl(field) {
      return require(`@/assets/images/fields/${field.image}`)
    },
    select(field) {
      this.field = { ...this.default, ...field.settings, localizable: this.model.localizable }
      this.selected = field
    },
    reset() {
      this.field = {}
      this.selected = null
    },
    async validateForm(e) {
      e.preventDefault()
      if (await this.$refs.editor.validateForm(e)) {
        this.visible = false
        this.$emit('field-created', { ...this.field })
      }
    },
    async okOneMore(e) {
      e.preventDefault()
      if (await this.$refs.editor.validateForm(e)) {
        this.$emit('field-created', { ...this.field })
        this.field = {}
        this.selected = null
      }
    },
  },
}
</script>
