import http from '@/http/index'

const projects = {
  async getProjects() {
    return await http.get(`projects`)
  },
  async createProject(data) {
    return await http.post(`projects`, data)
  },
  async getProject(project) {
    return await http.get(`${project}`)
  },
  async getSettings(project) {
    return await http.get(`${project}/settings`)
  },

  async updateProject(project, data) {
    return await http.put(`${project}`, data)
  },

  async updateSettings(project, data) {
    return await http.put(`${project}/settings`, data)
  },

  async getMetrics(project) {
    return await http.get(`${project}/metrics`)
  },
}

export default projects
