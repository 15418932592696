<template>
  <div>
    <div class="mb-1">
      <draggable
        v-bind="dragOptions"
        v-model="internal"
        tag="div"
        handle=".drag-handle"
        @start="onDragFieldStart"
        @end="onDragFieldEnd"
        @change="onDragChanged"
        v-if="internal && internal.length > 0"
      >
        <b-row v-for="(item, index) in internal" :key="`v-${index}`" class="pb-1">
          <b-col cols="6" class="pr-0 d-flex align-items-center">
            <feather-icon icon="AlignJustifyIcon" size="16" class="cursor-move d-sm-block d-none mr-1 drag-handle" />
            <text-input :value="item" @input="onInput($event, index)" class="flex-grow-1" placeholder="Value" css="m-0 p-0" />
          </b-col>
          <b-col cols="1" class="pl-0 d-flex align-items-center">
            <feather-icon icon="TrashIcon" size="16" class="cursor-pointer ml-50 text-danger" @click="onRemoveItem(index)" />
          </b-col>
        </b-row>
      </draggable>
    </div>
    <b-button variant="primary" @click="onAddItem"><feather-icon icon="PlusIcon" class="mr-25" /> Add</b-button>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  name: 'ValueArrayField',
  props: ['field', 'component', 'value', 'parentKey', 'locale', 'errors', 'index'],
  components: { draggable },
  data() {
    return {
      internal: [],
      drag: false,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.internal = [...val]
        } else {
          this.internal = []
        }
      },
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: this.field.key,
        disabled: false,
        scrollSensitivity: 200,
        forceFallback: true,
        ghostClass: 'collapsed-card-ghost',
      }
    },
  },
  mounted() {},
  methods: {
    onInput(event, index) {
      this.internal[index] = event
      this.$emit('input', [...this.internal])
    },
    onAddItem() {
      this.internal.push('')
      this.$emit('input', [...this.internal])
    },
    onRemoveItem(index) {
      this.internal.splice(index, 1)
      this.$emit('input', [...this.internal])
    },
    onDragChanged(e) {
      this.$emit('input', [...this.internal])
    },
    onDragFieldEnd(e) {
      this.drag = false
    },
    onDragFieldStart(e) {
      this.drag = true
    },
  },
}
</script>
