<template>
  <b-modal
    :no-close-on-backdrop="false"
    :no-close-on-esc="false"
    v-model="visible"
    hide-header
    centered
    size="md"
    cancel-variant="outline-secondary"
    ok-title="Ok"
    cancel-title="Close"
  >
    <b-card-body>
      <b-row>
        PLAN EXCEEDED
      </b-row>
    </b-card-body>
  </b-modal>
</template>
<script>
export default {
  props: ['show'],
  data() {
    return {
      visible: false,
    }
  },
  watch: {
    show(newVal, oldVal) {
      this.visible = newVal
    },
  },
}
</script>
