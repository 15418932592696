import { projects, contentTypes } from '@/api/index'
import _ from 'lodash'
import router from '@/router/index'
import { createContentMenu, createContentRoutes } from '@/router/content-routes'

const state = {
  project: null,
  settings: null,
  models: null,
  components: null,
  menu: null,
}
const getters = {
  Project: state => state.project,
  Settings: state => state.settings,
  Menu: state => state.menu,
  Components: state => state.components,
  Models: state => state.models,
  ProjectContextLoaded: (state, getters) => {
    return state.project != null && state.models != null && state.components != null && state.menu != null && state.settings != null
  },
}

const actions = {
  async LoadEnvironment({ dispatch }) {
    await dispatch('LoadMe', null, { root: true })
  },

  async LoadProject({ commit, getters, dispatch }, project) {
    projects
      .getProject(project)
      .then(res => {
        commit('setProject', res.data)
      })
      .catch(err => {
        dispatch('SetLastProject', '')
        window.location.href = '/'
      })

    const projectSettingsResponse = await projects.getSettings(project)
    commit('setSettings', projectSettingsResponse.data)

    await dispatch('LoadModels', project, { root: true })
  },

  async LoadModels({ commit, state }, project) {
    // console.log(project)
    const models = await contentTypes.getModels(project)
    const sysmodels = await contentTypes.getModels(project, { system: true })
    const components = await contentTypes.getComponents(project)

    commit('setContentTypes', { models: [...models.data.data, ...sysmodels.data.data], components: components.data.data })

    let routes = createContentRoutes(state.models, state.org, state.project)
    let menu = createContentMenu(state.models, state.org, state.project)

    routes.forEach(r => {
      router.addRoute(r)
    })

    commit('setMenu', menu)
  },
  SetLastProject({ commit, state }, project) {
    localStorage.setItem('last_project', project)
  },
}

const mutations = {
  setProject(state, project) {
    // console.log('-- 1. set project')
    // console.log(project)
    // console.log('-- 2. set project')
    this.dispatch('SetLastProject', project.name)
    state.project = { ...state.project, ...project }
  },
  setSettings(state, settings) {
    state.settings = { ...state.settings, ...settings }
  },
  setMenu(state, menu) {
    state.menu = menu
  },
  setContentTypes(state, types) {
    state.components = types.components
    state.models = types.models
  },
  resetEnvironment(state) {
    state.project = null
    state.settings = null
    state.models = null
    state.components = null
    state.menu = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
