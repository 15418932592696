<template>
  <div>
    <b-container class="ml-auto mt-1" :fluid="fluid">
      <b-card v-if="type == 'content'">
        <b-row>
          <b-col cols="12">
            <div class="d-flex">
              <div>
                <b-skeleton type="avatar" width="100px" height="100px" class="mr-1" />
              </div>
              <div class="flex-grow-1">
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
              </div>
            </div>
          </b-col>
          <b-col cols="12" v-if="size != 'small'">
            <b-row class="mt-1">
              <b-col>
                <b-skeleton-img no-aspect height="80px" width="90%"></b-skeleton-img>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" v-if="size != 'small'">
            <b-row class="mt-1">
              <b-col>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="55%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="55%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
    <b-container class="ml-auto" v-if="type == 'list'" :fluid="fluid">
      <b-card>
        <b-skeleton-table :rows="5" :columns="4" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
      </b-card>
    </b-container>
  </div>
</template>
<script>
export default {
  name: 'PageLoader',
  props: {
    type: {
      type: String,
      default: 'content',
    },
    size: {
      type: String,
      default: 'normal',
    },
    fluid: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
