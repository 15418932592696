import { mapActions, mapGetters } from 'vuex'

import SorteableComponent from './SorteableComponent'
export default {
  mixins: [SorteableComponent],
  data() {
    return {
      searchText: '',
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      totalPages: 1,
    }
  },
  computed: {},
  methods: {},
}
