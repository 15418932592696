<template>
  <div>
    <b-form-group>
      <validation-provider #default="{ errors }" :name="field.label" :rules="rules">
        <div v-if="!readOnly && !field.readOnly">
          <b-form-checkbox-group
            v-if="field.editor == 'checkbox'"
            v-model="internal"
            :options="field.enum"
            :name="field.key + locale"
            class="inline-spacing custom-control-success"
            @change="$emit('input', internal)"
          />
          <v-select
            v-if="field.editor == 'multiselect'"
            v-model="internal"
            :options="field.enum"
            size="lg"
            multiple
            class="select-size-lg custom-control-success"
            @input="$emit('input', $event)"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </div>
        <div v-else>
          <b-badge v-for="(item, index) in internal" :key="index" variant="primary" class="mr-1">
            {{ item }}
          </b-badge>
        </div>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import vSelect from 'vue-select'

export default {
  name: 'EnumerationField',
  components: { vSelect },
  props: {
    field: {},
    value: {},
    locale: {},
    readOnly: null,
    errors: [],
  },
  data() {
    return {
      internal: [],
    }
  },
  computed: {
    rules() {
      const ruleSet = []

      if (this.field.required) {
        ruleSet.push('required')
      }

      return ruleSet.join('|')
    },
    checkboxesOptions() {
      return this.field.enum.map(m => ({ text: m, value: m }))
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.internal = [...val]
        } else {
          this.internal = []
        }
      },
    },
    // NOTE: Avoid to emit from here, emit only from specific event
    //       see: https://forum.vuejs.org/t/vue-warn-avoid-mutating-a-prop-directly-since-the-value-will-be-overwritten-whenever-the-parent-component-re-renders-instead-use-a-data-or-computed-property-based-on-the-props-value-prop-being-mutated-content/101589
    // internal: {
    //   handler(val) {
    //     this.$emit('input', this.internal)
    //   },
    // },
  },
  methods: {},
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.radio-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  & > * {
    margin-right: 1.5rem;
    margin-top: 1.5rem;
  }
}
</style>
