import store from '@/store'
import _ from 'lodash'

const projectAdminRole = 'project-admin'
const adminRole = 'admin'
const ownerRole = 'owner'

const acl = {
  user() {
    return store.getters.Me
  },
  getMemberhip(project) {
    const me = this.user()

    return me.projects.find(elem => elem.id == project)
  },
  projectId() {
    return store.getters.Project.id
  },
  can(ability) {
    const membership = this.getMemberhip(this.projectId())

    if (!membership) {
      return false
    }

    if (membership.owner) return true

    if (membership.role == projectAdminRole) return true

    if (membership.permissions.includes(ability)) {
      return true
    }

    return false
  },
  canOneOf(abilities) {
    if (abilities && abilities.length == 0) {
      return true
    }

    let result = false

    const membership = this.getMemberhip(this.projectId())

    if (!membership) {
      return false
    }

    if (!abilities) return true

    if (Array.isArray(abilities)) {
      if (membership.owner) return true

      if (membership.role == projectAdminRole) return true

      for (let i = 0, len = abilities.length; i < len; i++) {
        const ability = abilities[i]
        if (ability.endsWith('*')) {
          if (
            _.findIndex(membership.permissions, function(o) {
              return o.startsWith(ability.substring(0, ability.length - 1))
            }) >= 0
          ) {
            result = true
          }
        } else {
          if (membership.permissions.includes(ability)) {
            result = true
          }
        }

        if (result) break
      }
    }
    return result
  },
  canAllOf(abilities) {
    return false
  },
}

export default acl
