<template>
  <section class="media-library-app-window">
    <div class="active-media-library">
      <!-- HEADER -->
      <div class="media-library-navbar">
        <header class="media-library-header">
          <div class="d-flex align-items-center">
            <div class="sidebar-toggle d-block d-lg-none mr-1">
              <feather-icon icon="MenuIcon" class="cursor-pointer" size="21" @click="mqShallShowLeftSidebar = true" />
            </div>
            <div class="pl-1 d-flex align-items-center left-header">
              <b-input-group class="input-group-merge ml-0 w-100 round">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" class="text-muted" />
                </b-input-group-prepend>
                <b-form-input placeholder="Search..." style="min-width:400px" @keyup.enter="onTextSearch" v-model="searchText" />
              </b-input-group>
              <b-button
                variant="link"
                v-b-tooltip.hover.v-secondary.top
                title="Reset Filters"
                @click="onCancelFilters"
                class="font-weight-normal pl-25 text-secondary d-block"
                v-if="filtersActive"
              >
                <span v-html="FilterOff" style="width:18px;" class="d-block" />
              </b-button>
            </div>
          </div>
          <div class="d-flex align-items-center pr-1 right-header">
            <upload @upload-completed="onUploadCompleted" :current-folder="currentFolder" />
          </div>
        </header>
      </div>

      <!-- CONTENT -->
      <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="media-library-edit-area scroll-area" ref="ps" infinite-wrapper>
        <b-container fluid class="mb-1 ml-0" v-if="currentFolder">
          <div class="media-wrapper media-box">
            <media-item
              v-for="item in items"
              :key="item.id"
              :item="item"
              :selected="isItemSelected(item)"
              tag="div"
              :view="view"
              :mode="libraryMode"
              :enable-selection="!maxSelectionReached"
              @item-changed="onItemChanged"
              @item-clicked="onItemClicked"
              @image-edit="openEdit(item)"
              @show-info="openInfo(item)"
              @delete="onDeleteMedia"
            />
          </div>
          <!-- <div class="d-flex mt-2 align-items-center justify-content-center" v-if="loading">
            <b-spinner label="Loading..." class="align-middle" />
          </div> -->
          <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId" spinner="spiral" class="mt-3">
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
        </b-container>
      </vue-perfect-scrollbar>

      <!-- FOOTER -->
      <b-form class="media-library-app-form d-flex justify-content-between">
        <div class="pl-1 d-flex align-items-center footer-action justify-content-left">
          <h6 class="m-0 p-0 pr-1" v-if="selectionActive">{{ selectedItems.length }} items selected</h6>
          <b-link class="text-primary pr-1" @click="onDeselectAll" v-if="selectionActive">Deselect all</b-link>
          <b-link
            class="text-danger pr-1"
            @click="onDeleteSelectedMedia"
            v-if="selectionActive && libraryMode == 'manage' && $acl.can('medialibrary.files.delete')"
            >Delete selected</b-link
          >
          <!-- <b-link class="text-primary" v-if="selectionActive && libraryMode == 'manage'">Assign tags</b-link> -->
        </div>
        <div class="pl-1 d-flex align-items-center justify-content-center">
          <portal-target name="media-selector-footer-actions" slim />
        </div>
      </b-form>
      <media-info ref="mediaInfo" @image-edit="openEdit" />
      <image-editor ref="imageEditor" @upload-completed="onUploadCompleted" />
    </div>
  </section>
</template>
<script>
import { mediaLibrary } from '@/api/index'
import MediaItem from './MediaItem.vue'
import MediaInfo from './MediaInfo.vue'
import Upload from './Upload.vue'
import ImageEditor from '@/components/ImageEditor/ImageEditor.vue'
import InfiniteLoading from 'vue-infinite-loading'
import FilterOff from '!!raw-loader!@/assets/images/icons/filter-off.svg'
import PageableComponent from '@/mixins/PageableComponent'

export default {
  mixins: [PageableComponent],
  props: ['currentFolder', 'mode', 'maxSelection'],
  components: {
    MediaItem,
    MediaInfo,
    Upload,
    ImageEditor,
    InfiniteLoading,
  },
  data() {
    return {
      perfectScrollbarSettings: { maxScrollbarLength: 150 },
      view: 'card',
      selectedItems: [],
      items: [],
      infiniteId: +new Date(),
      FilterOff,
    }
  },
  watch: {
    currentFolder(newVal, oldVal) {
      if (newVal != null && oldVal == null) this.reload(newVal)
      else if (newVal.path != oldVal.path) this.reload(newVal)
    },
  },
  created() {
    //this.fetchMedias(false)
  },
  computed: {
    maxSelectionAllowed() {
      return this.maxSelection && this.maxSelection > 0 ? this.maxSelection : null
    },
    maxSelectionReached() {
      return this.maxSelectionAllowed != null && this.maxSelectionAllowed == this.selectedItems.length
    },
    libraryMode() {
      return this.mode ? this.mode : 'manage'
    },
    selectionActive() {
      return this.selectedItems && this.selectedItems.length > 0
    },
    filtersActive() {
      let filters = this.getCurrentFilters()

      return filters.search && filters.search != ''
    },
  },
  methods: {
    reload(folder) {
      this.$refs.ps.$el.scrollTop = 0
      this.currentPage = 1
      this.infiniteId += 1
      this.items = []
    },
    getCurrentFilters() {
      let filters = {
        size: this.perPage,
        page: this.currentPage,
        sort: 'createdAt|desc',
        search: this.searchText,
      }

      if (this.currentFolder) {
        filters.folder = this.currentFolder.path
      }

      return filters
    },
    isItemSelected(item) {
      return (
        this.$_.findIndex(this.selectedItems, s => {
          return s.id == item.id
        }) >= 0
      )
    },
    handleSelection(item) {},
    removeSelected(id) {
      this.selectedItems = this.selectedItems.filter(function(value, index, arr) {
        return value.id != id
      })
    },
    openInfo(item) {
      this.$refs.mediaInfo.open(item.id)
    },
    openEdit(item) {
      this.$refs.imageEditor.open(item)
    },
    onDeselectAll() {
      this.selectedItems = []
    },
    onUploadCompleted() {
      this.reload(this.currentFolder)
    },
    onItemChanged(selected, item) {
      const self = this
      if (selected) {
        if (!this.isItemSelected(item)) {
          if (this.maxSelectionAllowed == null || this.maxSelectionAllowed > this.selectedItems.length) {
            this.selectedItems.push(item)
          }
        }
      } else {
        self.removeSelected(item.id)
      }

      this.$emit('selected-items-change', this.selectedItems)
    },
    onItemClicked(item) {
      if (this.selectionActive) {
        if (this.isItemSelected(item)) {
          this.onItemChanged(false, item)
        } else {
          this.onItemChanged(true, item)
        }
      } else {
        this.openInfo(item)
      }
    },
    async onDeleteSelectedMedia() {
      const self = this

      new Promise((resolve, reject) => {
        self.$_.each(self.selectedItems, i => {
          mediaLibrary.deleteMedia(self.projectName, i.id)
          self.removeSelected(i.id)
        })
        resolve()
      }).then(resolve => {
        self.reload(self.currentFolder)
      })
    },
    async onDeleteMedia(e) {
      const self = this

      new Promise((resolve, reject) => {
        mediaLibrary.deleteMedia(self.projectName, e.id)
        self.removeSelected(e.id)

        resolve()
      }).then(resolve => {
        self.reload(self.currentFolder)
      })
    },
    infiniteHandler(state) {
      let self = this
      mediaLibrary.getMedias(self.projectName, self.getCurrentFilters()).then(res => {
        if (res.data.data.length > 0) {
          self.currentPage += 1
          self.items.push(...res.data.data)
          state.loaded()
          self.$nextTick(() => {
            self.$refs.ps.update()
          })
        } else {
          state.complete()
        }
      })
    },
    onTextSearch(e) {
      let filters = this.getCurrentFilters()
      filters.page = 1
      // this.$router.push({ query: filters })
      this.reload(this.currentFolder)
    },
    onCancelFilters(e) {
      this.searchText = ''

      let filters = this.getCurrentFilters()
      filters.page = 1

      this.reload(this.currentFolder)
    },
  },
}
</script>
<style lang="scss" scoped>
.footer-action {
  a:hover {
    text-decoration: underline !important;
  }
}
</style>
