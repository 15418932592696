<template>
  <div>
    <section-divider text="Single Choise Settings" />
    <b-form-group label="Editor *">
      <validation-provider #default="{ errors }" name="Editor" rules="required">
        <b-form-select id="editor" v-model="item.editor" :options="editorOptions" size="md" />
        <small class="text-danger">{{ errors[0] }}</small>
        <b-form-text>The type of editor you will use when editing contents</b-form-text>
      </validation-provider>
    </b-form-group>
    <b-form-group label="Values *">
      <validation-provider #default="{ errors }" name="enums" rules="required">
        <b-form-textarea id="enums" v-model="enums" placeholder="Ex: Red\nYellow\nGreen\Blue" />
        <small class="text-danger">{{ errors[0] }}</small>
        <b-form-text>Separate each value with a new line</b-form-text>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: 'EnumerationFieldSettings',
  props: ['value'],
  data() {
    return {
      editorOptions: [
        { value: null, text: 'Please select an editor style' },
        { value: 'select', text: 'Select' },
        { value: 'radio', text: 'Radio Buttons' },
      ],
    }
  },
  computed: {
    enums: {
      get() {
        if (this.item.enum) {
          return this.item.enum.join('\n')
        } else {
          return ''
        }
      },
      set(newValue) {
        this.item.enum = newValue.split('\n')
        this.update('enum', this.item.enum)
      },
    },
    min: {
      get() {
        return this.item.min
      },
      set(newValue) {
        if (newValue > 0) {
          this.item.required = true
          this.update('required', this.item.required)
        }
        this.item.min = newValue
        this.update('min', this.item.min)
      },
    },
    item() {
      return this.value ? this.value : { name: '' }
    },
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.item, [key]: value })
    },
  },
}
</script>
