<template>
  <div>
    <field-settings-card
      :field="read"
      :image="image"
      @edit="visible = true"
      @field-delete="$emit('field-delete', id)"
      @field-duplicate="$emit('field-duplicate', id)"
    />
    <b-modal
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      v-model="visible"
      hide-header
      centered
      size="lg"
      @ok="validateForm"
      @cancel="reset"
      cancel-variant="outline-secondary"
      ok-title="Ok"
      cancel-title="Close"
    >
      <field-settings-editor v-model="edit" :model="model" ref="editor" :auto-generate-key="false" />
    </b-modal>
  </div>
</template>
<script>
import FieldSettingsCard from './FieldSettingsCard.vue'
import FieldSettingsEditor from './FieldSettingsEditor.vue'

export default {
  name: 'FieldSettings',
  props: ['model', 'field', 'id'],
  components: {
    FieldSettingsCard,
    FieldSettingsEditor,
  },
  data() {
    return {
      visible: false,
      read: {},
      edit: {},
    }
  },
  created() {},
  watch: {
    field: {
      immediate: true,
      handler(newValue, oldValue) {
        this.read = this.$_.cloneDeep(newValue)
        this.edit = this.$_.cloneDeep(newValue)
      },
    },
  },
  computed: {
    currentFieldType() {
      if (this.field !== null && this.field.type != null && this.field.type != '') {
        return `${this.field.type}-field-settings`
      }
      return ''
    },
    image() {
      return `https://fakeimg.pl/50/ccc,255/000,255/?font=helvetica&text=${this.field.type}`
    },
  },
  methods: {
    save() {
      this.read = this.edit
      this.$emit('field-update', { id: this.id, field: this.read })
      this.visible = false
    },
    async validateForm(e) {
      e.preventDefault()
      if (await this.$refs.editor.validateForm(e)) {
        this.save()
      }
    },
    async reset(e) {
      this.edit = this.read ? { ...this.read } : { label: '' }
    },
  },
}
</script>
