import Vue from 'vue'

// PROJECT
import PageTitle from '@/components/PageTitle/Index'
Vue.component('PageTitle', PageTitle)

// SETTINGS
import SettingsNav from '@/views/Project/Settings/SettingsNav.vue'
Vue.component('settings-nav', SettingsNav)

// FIELDS
import NewField from '@/components/Fields/NewField.vue'
import FieldEditor from '@/components/Fields/FieldEditor.vue'
import FieldSettings from '@/components/Fields/FieldSettings.vue'
import SectionDivider from '@/components/Fields/SectionDivider.vue'
Vue.component('new-field', NewField)
Vue.component('field-editor', FieldEditor)
Vue.component('field-settings', FieldSettings)
Vue.component('section-divider', SectionDivider)

// TEXT
import TextFieldSettings from '@/components/Fields/Text/TextFieldSettings.vue'
import TextField from '@/components/Fields/Text/TextField.vue'
Vue.component('text-field-settings', TextFieldSettings)
Vue.component('text-field', TextField)

// NUMBERS
import NumberFieldSettings from '@/components/Fields/Number/NumberFieldSettings.vue'
import NumberField from '@/components/Fields/Number/NumberField.vue'
Vue.component('number-field-settings', NumberFieldSettings)
Vue.component('number-field', NumberField)

// RICH TEXT
import RichtextFieldSettings from '@/components/Fields/RichText/RichTextFieldSettings.vue'
import RichtextField from '@/components/Fields/RichText/RichTextField.vue'
Vue.component('richtext-field-settings', RichtextFieldSettings)
Vue.component('richtext-field', RichtextField)

// BOOLEAN
import BooleanFieldSettings from '@/components/Fields/Boolean/BooleanFieldSettings.vue'
import BooleanField from '@/components/Fields/Boolean/BooleanField.vue'
Vue.component('boolean-field-settings', BooleanFieldSettings)
Vue.component('boolean-field', BooleanField)

// SINGLE CHOISE
import SingleChoiseFieldSettings from '@/components/Fields/SingleChoise/SingleChoiseFieldSettings.vue'
import SingleChoiseField from '@/components/Fields/SingleChoise/SingleChoiseField.vue'
Vue.component('singlechoise-field-settings', SingleChoiseFieldSettings)
Vue.component('singlechoise-field', SingleChoiseField)

// ENUMERATION
import EnumerationFieldSettings from '@/components/Fields/Enumeration/EnumerationFieldSettings.vue'
import EnumerationField from '@/components/Fields/Enumeration/EnumerationField.vue'
Vue.component('enumeration-field-settings', EnumerationFieldSettings)
Vue.component('enumeration-field', EnumerationField)

// TAGS
import TagFieldSettings from '@/components/Fields/Tag/TagFieldSettings.vue'
import TagField from '@/components/Fields/Tag/TagField.vue'
Vue.component('tag-field-settings', TagFieldSettings)
Vue.component('tag-field', TagField)

// JSON
import JsonFieldSettings from '@/components/Fields/Json/JsonFieldSettings.vue'
import JsonField from '@/components/Fields/Json/JsonField.vue'
Vue.component('json-field-settings', JsonFieldSettings)
Vue.component('json-field', JsonField)

// MEDIA
import MediaFieldSettings from '@/components/Fields/Media/MediaFieldSettings.vue'
import MediaField from '@/components/Fields/Media/MediaField.vue'
Vue.component('media-field-settings', MediaFieldSettings)
Vue.component('media-field', MediaField)

// MEDIA GALLERY
import MediaGalleryFieldSettings from '@/components/Fields/Media/MediaGalleryFieldSettings.vue'
import MediaGalleryField from '@/components/Fields/Media/MediaGalleryField.vue'
Vue.component('mediagallery-field-settings', MediaGalleryFieldSettings)
Vue.component('mediagallery-field', MediaGalleryField)

// RELATION
import RelationFieldSettings from '@/components/Fields/Relation/RelationFieldSettings.vue'
import RelationField from '@/components/Fields/Relation/RelationField.vue'
Vue.component('relation-field-settings', RelationFieldSettings)
Vue.component('relation-field', RelationField)

// MAP
import MapFieldSettings from '@/components/Fields/Map/MapFieldSettings.vue'
import MapField from '@/components/Fields/Map/MapField.vue'
Vue.component('map-field-settings', MapFieldSettings)
Vue.component('map-field', MapField)

// DATETIME
import DateTimeFieldSettings from '@/components/Fields/DateTime/DateTimeFieldSettings.vue'
import DateTimeField from '@/components/Fields/DateTime/DateTimeField.vue'
Vue.component('datetime-field-settings', DateTimeFieldSettings)
Vue.component('datetime-field', DateTimeField)

// TIME
import TimeFieldSettings from '@/components/Fields/Time/TimeFieldSettings.vue'
import TimeField from '@/components/Fields/Time/TimeField.vue'
Vue.component('time-field-settings', TimeFieldSettings)
Vue.component('time-field', TimeField)

// COMPONENT
import ComponentFieldSettings from '@/components/Fields/Component/ComponentFieldSettings.vue'
import ComponentField from '@/components/Fields/Component/ComponentField.vue'
Vue.component('singlecomponent-field-settings', ComponentFieldSettings)
Vue.component('singlecomponent-field', ComponentField)

// MULTICOMPONENT
import MultipleComponentFieldSettings from '@/components/Fields/MultipleComponent/MultipleComponentFieldSettings.vue'
import MultipleComponentField from '@/components/Fields/MultipleComponent/MultipleComponentField.vue'
Vue.component('multiplecomponent-field-settings', MultipleComponentFieldSettings)
Vue.component('multiplecomponent-field', MultipleComponentField)

// COLOR PICKER
import ColorPickerFieldSettings from '@/components/Fields/ColorPicker/ColorPickerFieldSettings.vue'
import ColorPickerField from '@/components/Fields/ColorPicker/ColorPickerField.vue'
Vue.component('colorpicker-field-settings', ColorPickerFieldSettings)
Vue.component('colorpicker-field', ColorPickerField)

// KEY/VALUE
import KeyValueFieldSettings from '@/components/Fields/KeyValue/KeyValueFieldSettings.vue'
import KeyValueField from '@/components/Fields/KeyValue/KeyValueField.vue'
Vue.component('keyvalue-field-settings', KeyValueFieldSettings)
Vue.component('keyvalue-field', KeyValueField)

// KEY/VALUE
import ValueArrayFieldSettings from '@/components/Fields/ValueArray/ValueArrayFieldSettings.vue'
import ValueArrayField from '@/components/Fields/ValueArray/ValueArrayField.vue'
Vue.component('valuearray-field-settings', ValueArrayFieldSettings)
Vue.component('valuearray-field', ValueArrayField)

// PAGE LOADER
import PageLoader from '@/components/PageLoader/PageLoader.vue'
Vue.component('page-loader', PageLoader)

// FORM ERROR
import FormAlert from '@/components/FormAlert/FormAlert.vue'
Vue.component('form-alert', FormAlert)

// FORM INPUTS
import TextInput from '@/components/Form/TextInput.vue'
Vue.component('text-input', TextInput)

// FORM INPUTS
import TextArea from '@/components/Form/TextArea.vue'
Vue.component('text-area', TextArea)

// AUDITS
import Audits from '@/components/Audit/Index.vue'
Vue.component('audits', Audits)

// AUDITS
import Forbidden from '@/components/Forbidden/Index.vue'
Vue.component('forbidden', Forbidden)
