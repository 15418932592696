import _ from 'lodash'

const state = {
  contentColumns: null,
  isVerticalMenuCollapsed: true,
  isContentEditSidebarCollapsed: false,
  isActionBarActive: false,
}
const getters = {
  ContentColumns: state => (state.contentColumns ? state.contentColumns : []),
  ActionBar: state => state.isActionBarActive,
  IsContentEditSidebarCollapsed: state => state.isContentEditSidebarCollapsed,
}
const actions = {
  SetActionBar({ commit, state }, data) {
    commit('SetActionBar', data)
  },
  SetContentColumns({ commit, state }, data) {
    let cols = getters.ContentColumns(state)

    // var projectIdex = _.findIndex(cols, c => {
    //   return c.project == data.projectId
    // })

    // if (projectIdex <= 0)
    // {
    //   cols
    // }

    var modelIndex = _.findIndex(cols, c => {
      return c.model == data.model
    })

    if (modelIndex < 0) {
      cols.push(data)
    } else {
      cols[modelIndex] = data
    }

    commit('SetContentColumns', cols)
  },
}
const mutations = {
  SetActionBar(state, data) {
    state.isActionBarActive = data
  },
  SetContentColumns(state, data) {
    state.contentColumns = data
  },
  UpdateVerticalMenuCollapsed(state, val) {
    state.isVerticalMenuCollapsed = val
  },
  UpdateContentEditSidebarCollapsed(state, val) {
    state.isContentEditSidebarCollapsed = val
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
