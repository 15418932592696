import Vue from 'vue'
import Acl from './functions.js'

export default function aclPlugin() {
  Vue.prototype.$acl = Acl
}

// import { abilitiesPlugin } from '@casl/vue'
// import ability from './ability'

// Vue.use(abilitiesPlugin, ability)

