const state = {
  planLimitExceededModalVisible: false,
}
const getters = {
  PlanLimitExceededModalVisible: state => state.planLimitExceededModalVisible,
}

const actions = {
  async ShowPlanLimitExceededModal({ commit }, data) {
    commit('SET_SHOW_PLAN_LIMIT_EXCEEDED_MODAL', data, { root: true })
  },
}

const mutations = {
  SET_SHOW_PLAN_LIMIT_EXCEEDED_MODAL(state, data) {
    state.planLimitExceededModalVisible = data
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
