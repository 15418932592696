<template>
  <div>
    <b-form-group>
      <validation-provider #default="{ errors }" :name="field.label" :rules="rules">
        <div v-if="!readOnly && !field.readOnly">
          <b-form-radio-group
            v-if="field.editor == 'radio'"
            v-model="internal"
            :options="field.enum"
            :name="field.key + locale"
            class="inline-spacing custom-control-success"
            @change="$emit('input', internal)"
          >
          </b-form-radio-group>

          <b-form-select v-if="field.editor == 'select'" v-model="internal" :options="field.enum" size="lg" @change="$emit('input', internal)" />

          <small class="text-danger">{{ errors[0] }}</small>
        </div>
        <div v-else>
          {{ internal }}
        </div>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: 'SingleChoiseField',
  components: {},
  props: {
    field: {},
    value: {},
    locale: {},
    readOnly: null,
    errors: [],
  },
  data() {
    return {
      internal: [],
    }
  },
  computed: {
    rules() {
      const ruleSet = []

      if (this.field.required) {
        ruleSet.push('required')
      }

      return ruleSet.join('|')
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.internal = val
      },
    },
  },
  methods: {},
}
</script>
<style lang="scss">
.radio-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  & > * {
    margin-right: 1.5rem;
    margin-top: 1.5rem;
  }
}
</style>
