<template>
  <div>
    <!-- {{ local }} -->
    <div class="px-2 pt-1">
      <b-media>
        <template #aside>
          <b-avatar size="50" alt="" class="mb-1 field-avatar">
            <img :src="imageUrl()" width="30%" height="40%" />
          </b-avatar>
        </template>
        <h4 class="media-heading">
          Edit Field
        </h4>
        <b-card-text>
          You can configure all the basic settings and specific settings if the type of the field use them
        </b-card-text>
      </b-media>
      <hr class="mt-0" />

      <validation-observer ref="settingsForm">
        <b-form>
          <b-form-group label="Name *">
            <validation-provider #default="{ errors }" name="Name" rules="required">
              <b-form-input
                id="name"
                placeholder="title, price, description...."
                size="md"
                v-model="local.label"
                :state="errors.length > 0 ? false : null"
                @keyup="createKey"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <b-form-text>The Name of the field</b-form-text>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Hint">
            <b-form-input id="description" placeholder="" size="md" v-model="local.help" />
            <b-form-text>This help text will appear below the field</b-form-text>
          </b-form-group>
          <b-form-group label="Key *">
            <validation-provider #default="{ errors }" name="Key" rules="required">
              <b-form-input id="key" placeholder="" size="md" v-model="local.key" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
              <b-form-text>The name that will appear in the API responses</b-form-text>
            </validation-provider>
          </b-form-group>
          <section-divider text="Basic Settings" />
          <b-form-group>
            <div class="inline-spacing">
              <b-form-checkbox id="localizable" v-model="local.localizable" class="custom-control-primary" v-if="model.type == 'model' && model.localizable">
                Enable localization for this field
              </b-form-checkbox>
              <b-form-checkbox id="required" v-model="local.required" class="custom-control-primary">
                This field is required
              </b-form-checkbox>
              <b-form-checkbox id="hidden" v-model="local.hidden" class="custom-control-primary">
                Hidden field
              </b-form-checkbox>
              <b-form-checkbox id="readonly" v-model="local.readOnly" class="custom-control-primary">
                Read Only
              </b-form-checkbox>
            </div>
          </b-form-group>
          <component v-bind:is="currentFieldType" v-model="local"></component>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { required } from '@validations'
import * as changeCase from 'change-case'
import { fields } from './manifest'

export default {
  name: 'FieldSettings',
  props: ['value', 'model', 'autoGenerateKey'],
  data() {
    return {
      fields,
      required,
      local: {},
    }
  },
  computed: {
    currentFieldType() {
      if (this.value !== null && this.value.type != null && this.value.type != '') {
        return `${this.value.type}-field-settings`
      }
      return ''
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        this.local = newValue ? this.$_.cloneDeep(newValue) : { label: '' }
      },
    },
  },
  methods: {
    imageUrl() {
      const field = this.$_.find(this.fields, f => f.settings.type === this.value.type)
      return require(`@/assets/images/fields/${field.image}`)
    },
    update(key, value) {
      this.$emit('input', { ...this.local, [key]: value })
    },
    async validateForm(e) {
      e.preventDefault()
      var self = this
      return await self.$refs.settingsForm.validate().then(success => {
        if (success) {
          self.$emit('input', { ...self.local })
          return true
        } else {
          return false
        }
      })
    },
    createKey(e) {
      if (this.autoGenerateKey) {
        this.local.key = changeCase.camelCase(this.local.label)
      }
    },
  },
}
</script>
