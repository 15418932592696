<template>
  <div>
    <section-divider text="RichText Settings" />
    <b-form-group label="Default Value">
      <b-form-textarea id="defaultValue" placeholder="" size="md" v-model="item.defaultValue" />
      <b-form-text>Will be the default value when creating new contents</b-form-text>
    </b-form-group>
    <b-form-group label="Editor *">
      <validation-provider #default="{ errors }" name="Editor" rules="required">
        <b-form-select id="editor" v-model="item.editor" :options="editorOptions" size="md" />
        <small class="text-danger">{{ errors[0] }}</small>
        <b-form-text>The type of editor you will use when editing contents</b-form-text>
      </validation-provider>
    </b-form-group>
    <b-form-group label="Size">
      <b-row>
        <b-col md="2">
          <b-form-input id="min" placeholder="Min chars" size="md" v-model="item.min" />
        </b-col>
        <b-col md="2">
          <b-form-input id="max" placeholder="Max chars" size="md" v-model="item.max" />
        </b-col>
      </b-row>
      <b-form-text>Min and Max length. 0 means no limits</b-form-text>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: 'RichTextFieldSettings',
  props: ['value'],
  data() {
    return {
      editorOptions: [
        { value: null, text: 'Please select an editor type' },
        { value: 'markdown', text: 'Markdown' },
        { value: 'html', text: 'Html' },
      ],
    }
  },
  computed: {
    item() {
      return this.value ? this.value : { name: '' }
    },
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.item, [key]: value })
    },
  },
}
</script>
