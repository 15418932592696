import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      projectName: '',
      lastProject: '',
      userLocale: 'it-IT',
    }
  },
  created() {
    this.projectName = this.$route ? this.$route.params.project : 'na'
  },
  computed: {
    ...mapGetters(['Project', 'Me']),
  },
  methods: {
    ...mapActions(['LoadProject']),
    SwitchToProject(project) {
      localStorage.setItem('last_project', project)
      window.location.href = `/app/${project}/dashboard`
    },
    InvalidateProjectContext(project) {
      this.LoadProject(project)
    },
    isProjectOwner(project) {
      const self = this
      if (project) {
        let myProject = self.Me.projects.find(p => p.id == project.id)
        if (myProject && myProject.owner) {
          return true
        }
      }

      return false
    },
  },
}
