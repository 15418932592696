import http from '@/http/index'
const memberships = {
  async sendInvitation(project, data) {
    return await http.post(`${project}/memberships/invitation`, data)
  },

  async getMemberships(project, query) {
    return await http.get(`${project}/memberships`, { params: query })
  },

  async updateMembership(project, id, data) {
    return await http.put(`${project}/memberships/${id}`, data)
  },

  async disconnectMembership(project, id) {
    return await http.delete(`${project}/memberships/${id}`)
  },
}

export default memberships
