<template>
  <div>
    <section-divider text="Boolean Settings" />
    <b-form-group label="Default Value">
      <b-form-input id="defaultValue" placeholder="" size="md" v-model="item.defaultValue" />
      <b-form-text>Will be the default value when creating new contents</b-form-text>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: 'BooleanFieldSettings',
  props: ['value'],
  data() {
    return {
      defaultValue: this.value.defaultValue.toString(),
    }
  },
  computed: {
    item() {
      return this.value ? this.value : {}
    },
  },
  methods: {},
}
</script>
