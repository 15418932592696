import http from '@/http/index'

const users = {
  async getUsers(project, query) {
    return await http.get(`${project}/users`, { params: query })
  },
  async getUser(project, id) {
    return await http.get(`${project}/users/${id}`)
  },
  async createUser(project, data) {
    return await http.post(`${project}/users/register`, data)
  },
  async updateUser(project, id, data) {
    return await http.put(`${project}/users/${id}`, data)
  },
  async deleteUser(project, id) {
    return await http.delete(`${project}/users/${id}`)
  },
  async changePassword(project, id, data) {
    return await http.post(`${project}/users/${id}/change-password`, data)
  },
  async getRole(project, id) {
    return await http.get(`${project}/roles/${id}`)
  },
  async getRoles(project) {
    return await http.get(`${project}/users/roles`)
  },
  async createRole(project, data) {
    return await http.post(`${project}/users/roles`, data)
  },
  async updateRole(project, id, data) {
    return await http.put(`${project}/users/roles/${id}`, data)
  },
  async getPermissionSet(project) {
    return await http.get(`${project}/users/roles/permissions`)
  },
  async deleteRole(project, id) {
    return await http.delete(`${project}/users/roles/${id}`)
  },
}

export default users
