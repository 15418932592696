import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    }
  },
  computed: {},
  methods: {},
}
