<template>
  <div>
    <section-divider text="Number Settings" />
    <b-form-group label="Default Value">
      <b-form-input id="defaultValue" placeholder="" size="md" v-model="item.defaultValue" />
      <b-form-text>Will be the default value when creating new contents</b-form-text>
    </b-form-group>
    <b-form-group label="Type *">
      <validation-provider #default="{ errors }" name="Type" rules="required">
        <b-form-select id="type" v-model="item.editor" :options="editorOptions" size="md" />
        <small class="text-danger">{{ errors[0] }}</small>
        <b-form-text>The type of number</b-form-text>
      </validation-provider>
    </b-form-group>
    <b-form-group label="Range">
      <b-row>
        <b-col md="2">
          <b-form-input id="min" placeholder="Min" size="md" v-model="item.min" />
        </b-col>
        <b-col md="2">
          <b-form-input id="max" placeholder="Max" size="md" v-model="item.max" />
        </b-col>
      </b-row>
      <b-form-text>If set represents the min and max value of the number. 0 means no min/max.</b-form-text>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: 'NumberFieldSettings',
  props: ['value'],
  data() {
    return {
      editorOptions: [
        { value: null, text: 'Please select the number type' },
        { value: 'integer', text: 'Integer' },
        { value: 'decimal', text: 'Decimal' },
      ],
    }
  },
  computed: {
    item() {
      return this.value ? this.value : { name: '' }
    },
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.item, [key]: value })
    },
  },
}
</script>
